import React, { Component } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Heading from "../components/Heading";
import { CompanyListWrapper } from "../styles/CompanyList.style";
import TableContainer from "./TableContainer";

var Base64 = require("js-base64").Base64;

export default class CompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data ? this.props.data : [],
      splitted_data: [],
    };
  }

  componentDidMount() {
    this.splitToChunks();
  }

  splitToChunks = () => {
    let company_list = this.state.data.data.company_list;
    let splitted_data = [];

    for (let i = 0; i < company_list.length; i = Math.ceil(i + company_list.length/2)) {
      let chunk = company_list.slice(i, Math.ceil(i + company_list.length/2));
      splitted_data.push(chunk);
    }
    this.setState({ splitted_data: splitted_data });
  };

  render() {
    const { slideNo } = this.props;
    const { section_id } = this.props.data.meta;
    const pageId = section_id && Base64.encode(section_id);

    return (
      <CompanyListWrapper style={{ height:`${ this.props.fromInterface ? "100%" : "720px"}` }}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.data.type} />
        ) : null}
        <div  style={{height: "87%",display:"flex", justifyContent:"flex-start", flexDirection:"column", padding:"10px 40px"}} >
          {this.state.data.data.company_list.length > 0 ? (
            <Heading heading={this.state.data.title} />
          ) : (
            <h1> No Data</h1>
          )}

          {this.state.data.meta.split_table ? (
            <div className="splitted_table">
              {this.state.splitted_data.map((chunk) => (
                <div className="table_chunks">
                  <TableContainer
                    data={this.state.data}
                    column_title={Object.keys(chunk[0])}
                    data_source={chunk}
                  />
                </div>
              ))}
            </div>
          ) : (
            <TableContainer
              data={this.state.data}
              column_title={Object.keys(this.props.data.data.company_list[0])}
              data_source={this.props.data.data.company_list}
            />
          )}
        </div>
        {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slideNo={slideNo}
              fromInterface={this.props.fromInterface}
            />
          ) : null}
      </CompanyListWrapper>
    );
  }
}
