const INITIAL_STATE = {
  TOC_DATA: {},
  USER_DATA: {},
  showGraphVal: false,
  IS_AUTH: false,
  SEARCH_PARAMS: false,
  MARKET_ACTIVITY_PARAMS: false,
  HLP_PARAMS: false,
  MASTER_ME_PARAMS: false,
  DOWNLOAD_REPORTS_PARAMS: false,
  switch_state: false,
  ACTIVE_TAB: "drafts",
  REPORT_HEADER_DATA: [],
  ANALYSIS_LINK_COUNTER: 0,
  PLACEMENT: "right",
  PUBLISHED_DRAWER_VISIBLE: false,
  DRAFT_DRAWER_VISIBLE: false,
  draft_versions: {},
  published_versions: {},
  draft_data_heading: "",
  draft_data_analysis: [],
  draft_data_sources: [],
  analysis_editor_state: [],
  sources_editor_state: [],
  analysis_heading_editor_state: "",
  analysis_heading_editor_state_for_published: null,
  analysis_heading_editor_state_for_draft: null,
  draft_stage: "INITIAL",
  draft_stage_from_get_specific_data: "",
  no_drafts_found: false,
  user_edit_access: true,
  draft_versions_loading: false,
  initial_draft_versions_loading: false,
  published_versions_loading: false,
  specific_draft_loading: false,
  select_stage_change_loader: false,
  switch_disabled: false,
  selected_indicator_heading: "",
  indicator_object_to_show: {},
  parent_id: "",
  cache_id: "",
  template_type: "",
  draft_id: null,
  indicator_dropdown_options: [],
  company_profile_data: [],
  update_company_profile_data: false,
  company_profile_dimensions: {},
  companyType: "globalCompany",
  fetch_full_data: false,
  character_count_error: false,
  exec_summary_data: [],
  save_draft_loader: false,
  direct_publish_loader: false,
  analysis_char_count: 0,
  dimensions: null,
  edit_graph: false,
  edit_graph_column: [],
  edit_graph_data: [],
  editGraphConfig: false,
};

export const APP_REDUCER = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GETTOCDATA":
      return {
        ...state,
        TOC_DATA: action.data,
      };
    case "SETSHOWGRAPHVAL":
      return {
        ...state,
        showGraphVal: action.data,
      };
    case "SETAUTH":
      return {
        ...state,
        IS_AUTH: action.data,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        USER_DATA: action.data,
      };
    case "SET_SEARCH_PARAM":
      return {
        ...state,
        SEARCH_PARAMS: action.data,
      };
    case "SET_MARKET_ACTIVITY_PARAM":
      return {
        ...state,
        MARKET_ACTIVITY_PARAMS: action.data,
      };
    case "SET_HLP_PARAM":
      return {
        ...state,
        HLP_PARAMS: action.data,
      };

    case "SET_MASTER_MES_PARAM":
      return {
        ...state,
        MASTER_ME_PARAMS: action.data,
      };
    case "SET_DOWNLOAD_REPORTS_PARAM":
      return {
        ...state,
        DOWNLOAD_REPORTS_PARAMS: action.data,
      };

    case "ACTIVE_TAB":
      return {
        ...state,
        ACTIVE_TAB: action.data,
      };
    case "SWITCH_STATE":
      return {
        ...state,
        switch_state: action.data,
      };
    case "REPORT_HEADER_DATA":
      return {
        ...state,
        REPORT_HEADER_DATA: action.data,
      };
    case "ANALYSIS_LINK_COUNTER":
      return {
        ...state,
        ANALYSIS_LINK_COUNTER: action.data,
      };
    case "SHOW_PUBLISHED_DRAWER":
      return {
        ...state,
        PUBLISHED_DRAWER_VISIBLE: action.data,
      };
    case "SHOW_DRAFT_DRAWER":
      return {
        ...state,
        DRAFT_DRAWER_VISIBLE: action.data,
      };
    case "SET_DRAFT_VERSIONS":
      return {
        ...state,
        draft_versions: action.data,
      };
    case "SET_PUBLISHED_VERSIONS":
      return {
        ...state,
        published_versions: action.data,
      };

    case "SET_DRAFT_DATA_HEADING":
      return {
        ...state,
        draft_data_heading: action.data,
      };

    case "SET_DRAFT_DATA_ANALYSIS":
      return {
        ...state,
        draft_data_analysis: action.data,
      };
    case "SET_DRAFT_DATA_SOURCES":
      return {
        ...state,
        draft_data_sources: action.data,
      };
    case "SET_ANALYSIS_EDITOR_STATE":
      return {
        ...state,
        analysis_editor_state: action.data,
      };

    case "SET_SOURCES_EDITOR_STATE":
      return {
        ...state,
        sources_editor_state: action.data,
      };
    case "SET_ANALYSIS_HEADING_EDITOR_STATE":
      return {
        ...state,
        analysis_heading_editor_state: action.data,
      };
    case "SET_ANALYSIS_HEADING_EDITOR_STATE_FOR_PUBLISHED":
      return {
        ...state,
        analysis_heading_editor_state_for_published: action.data,
      };
    case "SET_ANALYSIS_HEADING_EDITOR_STATE_FOR_DRAFT":
      return {
        ...state,
        analysis_heading_editor_state_for_draft: action.data,
      };

    case "SET_SAVE_DRAFT_LOADER":
      return {
        ...state,
        save_draft_loader: action.data,
      };

    case "SET_DIRECT_PUBLISH_LOADER":
      return {
        ...state,
        direct_publish_loader: action.data,
      };

    case "SET_DRAFT_STAGE": //SELECTED DRAFT STAGE BY USER
      return {
        ...state,
        draft_stage: action.data,
      };

    case "SET_DRAFT_STAGE_FROM_GET_SPECIFIC_DATA": // DEFAULT STAGE FROM getSpecificData and subsequently from DRAFTS
      return {
        ...state,
        draft_stage_from_get_specific_data: action.data,
      };

    case "SET_USER_EDIT_ACCESS":
      return {
        ...state,
        user_edit_access: action.data,
      };
    case "SET_NO_DRAFTS_FOUND":
      return {
        ...state,
        no_drafts_found: action.data,
      };
    case "SET_DRAFT_VERSIONS_LOADING":
      return {
        ...state,
        draft_versions_loading: action.data,
      };
    case "SET_INITIAL_DRAFT_VERSIONS_LOADING":
      return {
        ...state,
        initial_draft_versions_loading: action.data,
      };
    case "SET_PUBLISHED_VERSIONS_LOADING":
      return {
        ...state,
        published_versions_loading: action.data,
      };
    case "SET_SPECIFIC_DRAFT_LOADING":
      return {
        ...state,
        specific_draft_loading: action.data,
      };
    case "SET_SELECT_STAGE_CHANGE_LOADER":
      return {
        ...state,
        select_stage_change_loader: action.data,
      };

    case "SET_DISABLE_SWITCH":
      return {
        ...state,
        switch_disabled: action.data,
      };

    case "SET_SELECTED_INDICATOR_HEADING":
      return {
        ...state,
        selected_indicator_heading: action.data,
      };

    case "SET_INDICATOR_OBJECT_TO_SHOW":
      return {
        ...state,
        indicator_object_to_show: action.data,
      };

    case "SET_PARENT_ID":
      return {
        ...state,
        parent_id: action.data,
      };

    case "SET_CACHE_ID":
      return {
        ...state,
        cache_id: action.data,
      };

    case "SET_TEMPLATE_TYPE":
      return {
        ...state,
        template_type: action.data,
      };

    case "SET_DRAFT_ID":
      return {
        ...state,
        draft_id: action.data,
      };

    case "SET_INDICATOR_DROPDOWN_OPTIONS":
      return {
        ...state,
        indicator_dropdown_options: action.data,
      };

    case "SET_COMPANY_PROFILE_DATA":
      return {
        ...state,
        company_profile_data: action.data,
      };

    case "SET_UPDATE_COMPANY_PROFILE_DATA":
      return {
        ...state,
        update_company_profile_data: action.data,
      };
    case "SET_COMPANY_PROFILE_DIMENSIONS":
      return {
        ...state,
        company_profile_dimensions: action.data,
      };
    case "SET_COMPANY_TYPE":
      return {
        ...state,
        companyType: action.data,
      };

    case "SET_FETCH_FULL_DATA":
      return {
        ...state,
        fetch_full_data: action.data,
      };
    case "SET_CHARACTER_COUNT_ERROR":
      return {
        ...state,
        character_count_error: action.data,
      };

    case "SET_ANALYSIS_CHAR_COUNT":
      return {
        ...state,
        analysis_char_count: action.data,
      };

    case "SET_EXEC_SUMMARY_DATA":
      return {
        ...state,
        exec_summary_data: action.data,
      };

    case "PARTIAL_STATE_RESET": //except user login data and toc data
      return {
        ...state,
        switch_state: false,
        PUBLISHED_DRAWER_VISIBLE: false,
        DRAFT_DRAWER_VISIBLE: false,
        draft_versions: {},
        published_versions: {},
        draft_data_heading: "",
        draft_data_analysis: [],
        draft_data_sources: [],
        analysis_editor_state: [],
        sources_editor_state: [],
        analysis_heading_editor_state: "",
        analysis_heading_editor_state_for_published: null,
        analysis_heading_editor_state_for_draft: null,
        draft_stage: "INITIAL",
        no_drafts_found: false,
        user_edit_access: true,
        draft_versions_loading: false,
        initial_draft_versions_loading: false,
        published_versions_loading: false,
        specific_draft_loading: false,
        select_stage_change_loader: false,
        selected_indicator_heading: "",
        indicator_object_to_show: {},
        parent_id: "",
        cache_id: "",
        template_type: "",
        draft_id: null,
        indicator_dropdown_options: [],
        company_profile_data: [],
        update_company_profile_data: false,
        company_profile_dimensions: {},
        companyType: "globalCompany",
        fetch_full_data: false,
        character_count_error: false,
        exec_summary_data: [],
        save_draft_loader: false,
        direct_publish_loader: false,
        analysis_char_count: 0,
      };
    case "SET_DIMENSIONS":
      return {
        ...state,
        dimensions: action.data,
      };
    case "SET_EDIT_GRAPH":
      return {
        ...state,
        edit_graph: action.data,
      };
    case "SET_GRAPH_COLUMN":
      return {
        ...state,
        edit_graph_column: action.data,
      };
    case "SET_GRAPH_DATA":
      return {
        ...state,
        edit_graph_data: action.data,
      };
    case "SET_EDIT_GRAPH_CONFIG":
      return {
        ...state,
        editGraphConfig: action.data,
      };
    case "RESET_STATE":
      return INITIAL_STATE;

    default:
      return state;
  }
};
