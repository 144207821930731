import React,{useState} from 'react';
import axios from 'axios';
import config from '../../config/Api';
import { PublishRDContainer, CloseModal, Submit } from './styles';
import {notification } from 'antd';
const base_url = config.api.base_url;
const website_url = config.api.website_url;

export default function PublishRD(props) {
  let [blocker, setBlocker] = useState(false);

    let closeModal = (e)=>{
        e.preventDefault();
        props.set([]);
    }

let onSubmit =(e)=>{       
      e.preventDefault();
      setBlocker(true);
      let data ={
          "id":props.selectedRD.id,
          "slug":props.selectedRD.slug,
          };

      let config = {
          method: 'post',
          url: `${base_url}/api/interfaceQueries/Publish_Rd`,
          data : data
          };

      axios(config)
      .then((response)=>{
        let args = {
          message:response.data.message, 
          description: "Successfully Published RD On Website", 
          duration: 2.5,
          placement: "topRight",
        };
        notification.success(args);
        setBlocker(false);
            let a = document.createElement("a");
            a.target = "_blank";
            a.href = `${website_url}industry-reports/${response.data.slugs[0]}`;
            a.click();
            window.location.reload()
            setTimeout(()=>{props.set([])}, 4000);
       })
      .catch((error)=>{
        let args = {
          message:"Failed To Publish RD On Website", 
          description:"Sorry u don't have access to Publish RD's",
          duration: 2,
          placement: "topRight",
        };
        notification.error(args);
        setBlocker(false);
        props.set([]);
        console.log(error);
       });

    }
  return (
    <div>
       <PublishRDContainer>
        <CloseModal onClick={closeModal}>
            X
        </CloseModal>
        <div>
        <p>Are You Sure To Publish The Following Report On Website ? </p>
        <h4>
         {props.selectedRD.title}
        </h4>
        </div>
        <Submit disabled={blocker} onClick={(e)=>onSubmit(e)}>
            Publish
        </Submit>
       </PublishRDContainer>
    </div>

  )
}
