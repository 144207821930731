import styled from "styled-components";

export const ConstructionChemicalsVCWrapper = styled.div`
  .section_data1 {
    top: 65%;
    width: 40%;
    max-width: 260px;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    top: 69%;
    width: 21%;
    max-width: 180px;
    position: absolute;
    word-break: break-word;
  }


  .section1_list1 {
    left: 6%;
  }


  .section2_list {
    left: ${(props) => props.templatePart === 2 ? "40%" : "34%"};
  }

  .section3_list1 {
    left: ${(props) => props.templatePart === 2 ? "69%" : "62%"};
    top:${(props) => props.templatePart === 2 ? "65%" : "69%"};
  }
  .section4_list1 {
    left: 80%;
    top:${(props) => props.templatePart === 2 ? "65%" : "69%"};
  }
 
`;
