class Switchtextuppercase {

  static get isInline() {
    return true;
  }

  constructor() {
    this.button = null;
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.style.display='flex';
    this.button.style.alignItems='center';
    this.button.style.justifyContent='center';
    this.button.style.width='30px';
    this.button.style.height='30px';
    this.button.innerHTML=`<svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 512 512" xml:space="preserve">
 <g>
   <g>
     <path d="M490.667,0H21.333C9.552,0,0,9.551,0,21.333v469.333C0,502.449,9.552,512,21.333,512h469.333
       c11.782,0,21.333-9.551,21.333-21.333V21.333C512,9.551,502.45,0,490.667,0z M469.334,469.333L469.334,469.333H42.667V42.667
       h426.667V469.333z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M446.906,377.254l-35.38-106.141c-0.042-0.138-0.092-0.273-0.137-0.41l-49.816-149.449
       c-6.483-19.449-33.994-19.449-40.477,0l-49.904,149.711c-0.004,0.012-0.008,0.023-0.012,0.035l-35.418,106.254
       c-3.725,11.177,2.315,23.259,13.493,26.985c11.177,3.726,23.259-2.315,26.985-13.492l30.693-92.08h68.803l30.693,92.08
       c3.726,11.177,15.807,17.218,26.985,13.492C444.591,400.513,450.632,388.432,446.906,377.254z M321.154,256l20.179-60.538
       L361.513,256H321.154z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M212.696,378.826l-42.666-170.667c-5.077-20.309-33.321-21.918-40.671-2.317l-64,170.667
       c-4.138,11.032,1.452,23.329,12.484,27.466c11.032,4.137,23.329-1.452,27.466-12.484l10.809-28.824h48.559l6.627,26.507
       c2.858,11.43,14.44,18.38,25.87,15.522C208.604,401.838,215.555,390.256,212.696,378.826z M132.117,320l13.136-35.028L154.01,320
       H132.117z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M207.085,112.915l-42.667-42.667c-0.497-0.497-1.02-0.967-1.564-1.413c-0.245-0.202-0.504-0.377-0.757-0.566
       c-0.305-0.228-0.603-0.464-0.92-0.676c-0.302-0.202-0.616-0.378-0.927-0.563c-0.287-0.172-0.567-0.352-0.863-0.51
       c-0.317-0.169-0.643-0.313-0.966-0.465c-0.308-0.145-0.611-0.299-0.926-0.43c-0.314-0.13-0.635-0.235-0.953-0.349
       c-0.338-0.122-0.672-0.251-1.018-0.356c-0.318-0.096-0.642-0.167-0.964-0.248c-0.353-0.089-0.701-0.188-1.061-0.259
       c-0.372-0.074-0.748-0.118-1.122-0.171c-0.314-0.045-0.622-0.104-0.94-0.136c-0.7-0.069-1.402-0.106-2.105-0.106h-0.001
       c-0.702,0-1.404,0.037-2.104,0.106c-0.318,0.031-0.627,0.091-0.941,0.136c-0.375,0.054-0.75,0.097-1.122,0.171
       c-0.36,0.071-0.708,0.17-1.061,0.259c-0.322,0.081-0.645,0.151-0.963,0.248c-0.346,0.105-0.68,0.235-1.019,0.356
       c-0.318,0.114-0.638,0.219-0.951,0.348c-0.316,0.131-0.619,0.285-0.927,0.43c-0.323,0.152-0.649,0.296-0.965,0.465
       c-0.296,0.158-0.577,0.339-0.864,0.51c-0.31,0.185-0.623,0.36-0.926,0.562c-0.318,0.213-0.617,0.449-0.922,0.678
       c-0.252,0.189-0.51,0.363-0.755,0.565c-0.544,0.446-1.067,0.916-1.564,1.413L91.58,112.914c-8.331,8.331-8.331,21.839,0,30.17
       s21.841,8.332,30.173,0.001l6.248-6.248v12.497c0,11.782,9.551,21.333,21.333,21.333s21.333-9.551,21.333-21.333v-12.497
       l6.248,6.248c8.331,8.331,21.839,8.331,30.17,0S215.416,121.246,207.085,112.915z"/>
   </g>
 </g>
 </svg>`
    return this.button;
  }
 
  surround(range) {
    const selectedText = range.extractContents();
    const text = selectedText.textContent;

    let transformedText = text.toUpperCase();

    const span = document.createElement('span');
    span.textContent = transformedText;
    span.setAttribute('data-case', this.state);
    range.insertNode(span);
  }

  checkState(selection) {
    const text = selection.anchorNode;

    if (!text) {
      return;
    }
    this.button.classList.toggle('active', this.state !== 'none');
  }
}




export default Switchtextuppercase;

