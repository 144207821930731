import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import config from '../../config/Api';
import { UpdateMetaContainer, CloseModal, Submit, Separator, Label, Header, Fieldset, Legend } from './styles';
import { Input, Spin, notification } from 'antd';
const { TextArea } = Input;
const base_url = config.api.base_url;

export default function UpdateMeta(props) {
    let [loading, setLoading] = useState(false)
    let [blocker, setBlocker] = useState(false);
    let [HLPMeta, setHLPMeta] = useState({});
    let [relatedTags, setRelatedTags] = useState(["sticky_form"]);

    let tagsRef = useRef();
    let keywordsRef = useRef();
    let descriptionRef = useRef();

    useEffect(async () => {
        setLoading(true);
        await axios
            .get(`${base_url}/api/hub_landing_page/draft?hub_id=${props.selectedHLP.hub_id}`)
            .then((response) => {
                if (response.status === 200) {
                    setHLPMeta({ ...response.data.data.meta })
                    setLoading(false)
                    let args = {
                        message: "Meta Data Found",
                        duration: 2.5,
                        placement: "topRight",
                    };
                    notification.success(args);
                }
            })
            .catch((error) => {
                let args = {
                    message: "Can Not Find RD Data",
                    duration: 2,
                    placement: "topRight",
                };
                setLoading(false)
                notification.error(args);
                console.log(error);
            });

    }, [])
    let handleInputs = async (event, id = null, index = 0) => {
        let { value, name } = event.target;
        switch (true) {
            case name.includes("sticky_form"):
            case name.includes("testimonial_bottom"): {
                if (!relatedTags.includes(value)) {
                    let tempArr = [...relatedTags];
                    tempArr.push(value);
                    setRelatedTags(tempArr);
                } else if (relatedTags.includes(value)) {
                    let tempArr = [...relatedTags];
                    tempArr = tempArr.filter(item => {
                        return (item !== value);
                    })
                    setRelatedTags(tempArr);
                }
                break;
            }
        }
    }
    let closeModal = (e) => {
        e.preventDefault();
        props.set([])
    }

    let onSubmit = (e) => {
        e.preventDefault();
        setBlocker(true);
        let data = {
            "hub_id": props.selectedHLP.hub_id,
            "meta": {
                title: tagsRef.current.resizableTextArea.props.value,
                keywords: keywordsRef.current.resizableTextArea.props.value,
                desc: descriptionRef.current.resizableTextArea.props.value,
            },
            "sticky_form":relatedTags.includes("sticky_form")
        };

        let config = {
            method: 'post',
            url: `${base_url}/api/hub_landing_page/updateMeta`,
            data: data
        };

        axios(config)
            .then((response) => {
                let args = {
                    message: "Successfully Updated Meta Information",
                    description: response.data.message,
                    duration: 2.5,
                    placement: "topRight",
                };
                notification.success(args);
                setBlocker(false);
                setTimeout(() => { props.set([]) }, 1000)
            })
            .catch((error) => {
                let args = {
                    message: "Failed To Update Meta Information",
                    description: "Sorry u don't have access to update RD's meta",
                    duration: 2,
                    placement: "topRight",
                };
                notification.error(args);
                setBlocker(false);
                props.set([]);
                console.log(error);
            });

    }

    return (
        <UpdateMetaContainer>
            <CloseModal onClick={closeModal}>
                X
            </CloseModal>

            <Header>Hubs Landing Page Meta Update</Header>
            <div>
      
                {loading ?
                    <div style={{ textAlign:"center", margin:"20px auto", marginTop:"40px" }}>
                        <Spin />
                    </div>

                    :
                    <>
                    <Fieldset>
                    <Legend>Meta Information</Legend>
                        <div style={{display:"flex", justifyContent:"flex-start"}}>
                            <Label style={{flex: '0 1 10%'}} htmlFor="title">Title: </Label>
                            <TextArea
                                ref={tagsRef}
                                defaultValue={Object.keys(HLPMeta).length && HLPMeta.title}
                                id='title'
                                style={{ flex: '0 1 90%',marginBottom:"10px" }}
                                rows={1}
                            />
                        </div>

                        <div style={{display:"flex", justifyContent:"flex-start"}}>
                            <Label style={{flex: '0 1 10%'}} htmlFor="keywords">Keywords :</Label>
                            <TextArea
                                ref={keywordsRef}
                                defaultValue={Object.keys(HLPMeta).length && HLPMeta.keywords}
                                id='keywords'
                                style={{ flex: '0 1 90%',marginBottom:"10px",marginBottom:"10px" }}
                                rows={2}
                            />
                        </div>

                        <div style={{display:"flex", justifyContent:"flex-start"}}>
                            <Label style={{flex: '0 1 10%'}} htmlFor="description">Description :</Label>
                            <TextArea
                                ref={descriptionRef}
                                defaultValue={Object.keys(HLPMeta).length && HLPMeta.desc}
                                id='description'
                                style={{ flex: '0 1 90%',marginBottom:"10px" }}
                                rows={3}
                            />
                        </div>
                        
                  </Fieldset>
                  <Fieldset style={{paddingTop:"0px", paddingBottom:"10px"}}>
                  <Legend>Realted Tags</Legend>
                       
                   <Separator style={{ display:"flex", justifyContent:"flex-start", padding:"0" }}>
                        <Input style={{width:"fit-content", marginRight:"10px", marginTop: "0px"}} type="checkbox" id="sticky_form" name="sticky_form" value="sticky_form" checked={relatedTags.includes("sticky_form")} onChange={handleInputs} />
                        <Label htmlFor="sticky_form">Sticky Form</Label>
                     </Separator>
                  </Fieldset>

            <Fieldset>
            <Submit style={{position:"relative", top:"0"}} disabled={blocker} onClick={(e) => onSubmit(e)}>
                Submit
            </Submit>
            </Fieldset>
                    </>
                }
            </div>
       

        </UpdateMetaContainer>
    )
}
