const config = {
  LOCAL_STORAGE_TOKEN: {
    Name: "token",
    meta: "lastSignInTime",
    TokenTimeout: 5 * 50,
  },

  AUTH_TEST_ACCOUNTS: [
    "test@mordorintelligence.com",
    "lorien_beta1@mordorintelligence.com",
    "lorien_beta2@mordorintelligence.com",
    "lorien_beta3@mordorintelligence.com",
    "lorien_beta4@mordorintelligence.com",
    "lorien_beta5@mordorintelligence.com",
    "subscription_platform@mordorintelligence.com",
    "lorientempaccess_1@mordorintelligence.com",
  ],
  COLORS:
    "#4A90E2, #3FD0D9, #9889F2, #65789B, #FFAC01 , #F38141 ,#2E5F84, #802392 ,#002F75, #7D7E75, #CC7BAE, #5AD054, #EF6663, #CD9265, #B2B2B2",
  CONTINENT_CONFIG: {
    asia: [
      "southern asia",
      "western asia",
      "south-eastern asia",
      "central asia",
      "eastern asia",
    ],
    "asia-pacific": [
      "southern asia",
      "western asia",
      "south-eastern asia",
      "central asia",
      "eastern asia",
    ],
    "middle east": ["middle east"],
    europe: [
      "southern europe",
      "western europe",
      "northern europe",
      "eastern europe",
    ],
    africa: [
      "middle africa",
      "eastern africa",
      "western africa",
      "southern africa",
      "northern africa",
    ],
    "north america": ["caribbean", "central america", "northern america"],
    oceania: ["melanesia", "australia and new zealand"],
  },

  SUBCONTINENT_CONFIG: {
    "southern asia": ["AFG", "BGD", "BTN", "IND", "IRN", "LKA", "NPL", "PAK"],
    "middle africa": ["AGO", "CAF", "CMR", "COG", "COD", "GAB", "GNQ", "TCD"],
    "southern europe": [
      "ALB",
      "BIH",
      "ESP",
      "GRC",
      "HRV",
      "ITA",
      "OSA",
      "MKD",
      "MNE",
      "PRT",
      "SRB",
      "SVN",
    ],
    "western asia": [
      "ARE",
      "ARM",
      "AZE",
      "CYP",
      "GEO",
      "IRQ",
      "ISR",
      "JOR",
      "KWT",
      "LBN",
      "OMN",
      "QAT",
      "SAU",
      "SYR",
      "TUR",
      "PSE",
      "YEM",
    ],
    "south america": [
      "ARG",
      "BRA",
      "BOL",
      "CHL",
      "COL",
      "ECU",
      "FLK",
      "GUY",
      "PER",
      "PRY",
      "SUR",
      "URY",
      "VEN",
    ],
    antarctica: ["ATA"],
    "eastern africa": [
      "ATF",
      "BDI",
      "DJI",
      "ERI",
      "ETH",
      "KEN",
      "MDG",
      "MOZ",
      "MWI",
      "RWA",
      "SDS",
      "ABV",
      "SOM",
      "TZA",
      "UGA",
      "ZMB",
      "ZWE",
    ],
    "western europe": ["AUT", "BEL", "CHE", "DEU", "FRA", "LUX", "NLD"],
    "western africa": [
      "BEN",
      "BFA",
      "CIV",
      "GHA",
      "GIN",
      "GMB",
      "GNB",
      "LBR",
      "MLI",
      "MRT",
      "NER",
      "NGA",
      "SEN",
      "SLE",
      "TGO",
    ],
    "eastern europe": [
      "BGR",
      "BLR",
      "CZE",
      "HUN",
      "MDA",
      "POL",
      "ROU",
      "RUS",
      "SVK",
      "UKR",
    ],
    caribbean: ["BHS", "CUB", "DOM", "HTI", "JAM", "PRI", "TTO"],
    "central america": ["BLZ", "CRI", "GTM", "HND", "MEX", "NIC", "PAN", "SLV"],
    "south-eastern asia": [
      "BRN",
      "IDN",
      "KHM",
      "LAO",
      "MMR",
      "MYS",
      "PHL",
      "THA",
      "TLS",
      "TWN",
      "VNM",
    ],
    "southern africa": ["BWA", "LSO", "NAM", "SWZ", "ZAF"],
    "northern america": ["CAN", "USA"],
    "eastern asia": ["CHN", "JPN", "MNG", "KOR"],
    "northern europe": [
      "DNK",
      "EST",
      "FIN",
      "GBR",
      "IRL",
      "ISL",
      "LTU",
      "LVA",
      "NOR",
      "SWE",
    ],
    "northern africa": ["DZA", "EGY", "LBY", "MAR", "ESH", "SDN", "TUN"],
    melanesia: ["FJI", "NCL", "PNG", "SLB", "VUT"],
    "central asia": ["KAZ", "KGZ", "TJK", "TKM", "UZB"],
    "australia and new zealand": ["AUS", "NZL"],
    "middle east": [
      "EGY",
      "TUR",
      "IRN",
      "IRQ",
      "SAU",
      "YEM",
      "SYR",
      "JOR",
      "ARE",
      "ISR",
      "LBY",
      "LBN",
      "OMN",
      "KWT",
      "QAT",
    ],
  },
  COUNTRY_CONFIG: {
    afghanistan: "AFG",
    angola: "AGO",
    albania: "ALB",
    "united arab emirates": "ARE",
    argentina: "ARG",
    armenia: "ARM",
    antarctica: "ATA",
    "french southern and antarctic lands": "ATF",
    australia: "AUS",
    austria: "AUT",
    azerbaijan: "AZE",
    burundi: "BDI",
    belgium: "BEL",
    benin: "BEN",
    "burkina faso": "BFA",
    bangladesh: "BGD",
    bulgaria: "BGR",
    "the bahamas": "BHS",
    "bosnia and herzegovina": "BIH",
    belarus: "BLR",
    belize: "BLZ",
    bolivia: "BOL",
    brazil: "BRA",
    brunei: "BRN",
    bhutan: "BTN",
    botswana: "BWA",
    "central african republic": "CAF",
    canada: "CAN",
    switzerland: "CHE",
    chile: "CHL",
    china: "CHN",
    "ivory coast": "CIV",
    cameroon: "CMR",
    "democratic republic of the congo": "COD",
    "republic of the congo": "COG",
    colombia: "COL",
    "costa rica": "CRI",
    cuba: "CUB",
    "northern cyprus": "-99",
    cyprus: "CYP",
    "czech republic": "CZE",
    germany: "DEU",
    djibouti: "DJI",
    denmark: "DNK",
    "dominican republic": "DOM",
    algeria: "DZA",
    ecuador: "ECU",
    egypt: "EGY",
    eritrea: "ERI",
    spain: "ESP",
    estonia: "EST",
    ethiopia: "ETH",
    finland: "FIN",
    fiji: "FJI",
    "falkland islands": "FLK",
    france: "FRA",
    gabon: "GAB",
    uk: "GBR",
    georgia: "GEO",
    ghana: "GHA",
    guinea: "GIN",
    gambia: "GMB",
    "guinea bissau": "GNB",
    "equatorial guinea": "GNQ",
    greece: "GRC",
    greenland: "GRL",
    guatemala: "GTM",
    guyana: "GUY",
    honduras: "HND",
    croatia: "HRV",
    haiti: "HTI",
    hungary: "HUN",
    indonesia: "IDN",
    india: "IND",
    ireland: "IRL",
    iran: "IRN",
    iraq: "IRQ",
    iceland: "ISL",
    israel: "ISR",
    italy: "ITA",
    jamaica: "JAM",
    jordan: "JOR",
    japan: "JPN",
    kazakhstan: "KAZ",
    kenya: "KEN",
    kyrgyzstan: "KGZ",
    cambodia: "KHM",
    kosovo: "OSA",
    kuwait: "KWT",
    laos: "LAO",
    lebanon: "LBN",
    liberia: "LBR",
    libya: "LBY",
    "sri lanka": "LKA",
    lesotho: "LSO",
    lithuania: "LTU",
    luxembourg: "LUX",
    latvia: "LVA",
    morocco: "MAR",
    moldova: "MDA",
    madagascar: "MDG",
    mexico: "MEX",
    macedonia: "MKD",
    mali: "MLI",
    myanmar: "MMR",
    montenegro: "MNE",
    mongolia: "MNG",
    mozambique: "MOZ",
    mauritania: "MRT",
    malawi: "MWI",
    malaysia: "MYS",
    namibia: "NAM",
    "new caledonia": "NCL",
    niger: "NER",
    nigeria: "NGA",
    nicaragua: "NIC",
    netherlands: "NLD",
    norway: "NOR",
    nepal: "NPL",
    "new zealand": "NZL",
    oman: "OMN",
    pakistan: "PAK",
    panama: "PAN",
    peru: "PER",
    philippines: "PHL",
    "papua new guinea": "PNG",
    poland: "POL",
    "puerto rico": "PRI",
    portugal: "PRT",
    paraguay: "PRY",
    qatar: "QAT",
    romania: "ROU",
    russia: "RUS",
    rwanda: "RWA",
    "western sahara": "ESH",
    "saudi arabia": "SAU",
    sudan: "SDN",
    "south sudan": "SDS",
    senegal: "SEN",
    "solomon islands": "SLB",
    "sierra leone": "SLE",
    "el salvador": "SLV",
    somaliland: "ABV",
    somalia: "SOM",
    serbia: "SRB",
    suriname: "SUR",
    slovakia: "SVK",
    slovenia: "SVN",
    sweden: "SWE",
    swaziland: "SWZ",
    syria: "SYR",
    chad: "TCD",
    togo: "TGO",
    thailand: "THA",
    tajikistan: "TJK",
    turkmenistan: "TKM",
    "east timor": "TLS",
    "trinidad and tobago": "TTO",
    tunisia: "TUN",
    turkey: "TUR",
    taiwan: "TWN",
    tanzania: "TZA",
    uganda: "UGA",
    ukraine: "UKR",
    uruguay: "URY",
    usa: "USA",
    "uk": "UK",
    "united kingdom": "UK",
    uzbekistan: "UZB",
    venezuela: "VEN",
    vietnam: "VNM",
    vanuatu: "VUT",
    "west bank": "PSE",
    yemen: "YEM",
    "south africa": "ZAF",
    zambia: "ZMB",
    zimbabwe: "ZWE",
    korea: "KOR",
  },
  STATE_CONFIG: {
    'andaman and nicobar islands': 'AN',
    'andhra pradesh': 'AP',
    'arunachal pradesh': 'AR',
    assam: 'AS',
    bihar: 'BI',
    chandigarh: 'CH',
    chhattisgarh: 'CA',
    'dadra and nagar haveli': 'DN',
    'daman and diu': 'DD',
    delhi: 'DE',
    goa: 'GO',
    gujarat: 'GU',
    haryana: 'HA',
    'himachal pradesh': 'HP',
    'jammu and kashmir': 'JK',
    jharkhand: 'JH',
    karnataka: 'KA',
    kerala: 'KE',
    ladakh: 'LA',
    lakshadweep: 'LK',
    'madhya pradesh': 'MP',
    maharashtra: 'MA',
    manipur: 'MN',
    meghalaya: 'ME',
    mizoram: 'MI',
    nagaland: 'NA',
    odisha: 'OR',
    puducherry: 'PO',
    punjab: 'PU',
    rajasthan: 'RA',
    sikkim: 'SI',
    'tamil nadu': 'TN',
    telangana: 'TL',
    tripura: 'TR',
    'uttar pradesh': 'UP',
    uttarakhand: 'UT',
    'west bengal': 'WB',
    anhui: 'AH',
    fujian: 'FJ',
    gansu: 'GS',
    guangdong: 'GD',
    guangxi: 'GX',
    guizhou: 'GZ',
    hainan: 'HA',
    hebei: 'HB',
    heilongjiang: 'HL',
    henan: 'HE',
    hubei: 'HU',
    hunan: 'HN',
    jiangsu: 'JS',
    jiangxi: 'JX',
    jilin: 'JL',
    liaoning: 'LN',
    'nei mongol': 'NM',
    'ningxia hui': 'NX',
    qinghai: 'QH',
    shaanxi: 'SA',
    shandong: 'SD',
    shanxi: 'SX',
    sichuan: 'SC',
    tibet: 'TB',
    xinjiang: 'XJ',
    yunnan: 'YN',
    zhejiang: 'ZJ',
    'new south wales': 'SW',
    'northern territory': 'NT',
    queensland: 'QU',
    'south australia': 'SA',
    tasmania: 'TA',
    victoria: 'VI',
    'western australia': 'WA',
    acre: 'AC',
    alagoas: 'AL',
    amapa: 'AP',
    amazonas: 'AM',
    bahia: 'BA',
    ceara: 'CE',
    'distrito federal': 'DF',
    'espirito santo': 'ES',
    goias: 'GO',
    maranhao: 'MA',
    'mato grosso': 'MT',
    'mato grosso do sul': 'MS',
    'minas gerais': 'MG',
    para: 'PA',
    paraiba: 'PB',
    parana: 'PR',
    pernambuco: 'PE',
    piaui: 'PI',
    'rio de janeiro': 'RJ',
    'rio grande do norte': 'RN',
    'rio grande do sul': 'RS',
    rondonia: 'RO',
    roraima: 'RR',
    'santa catarina': 'SC',
    'sao paulo': 'SP',
    sergipe: 'SE',
    tocantins: 'TO',
    'buenos aires': 'BA',
    catamarca: 'CT',
    chaco: 'CC',
    chubut: 'CH',
    corrientes: 'CN',
    'córdoba': 'CB',
    'ciudad autónoma de buenos aires': 'DF',
    'entre rios': 'ER',
    formosa: 'FM',
    jujuy: 'JY',
    'la pampa': 'LP',
    'la rioja': 'LR',
    mendoza: 'MZ',
    misiones: 'MN',
    'neuquén': 'NQ',
    'río negro': 'RN',
    salta: 'SA',
    'san juan': 'SJ',
    'san luis': 'SL',
    'santa cruz': 'SC',
    'santa fe': 'SF',
    'santiago del estero': 'SE',
    'tierra del fuego': 'TF',
    'tucumán': 'TM',
    'special region of aceh': 'AC',
    bali: 'BA',
    'bangka–belitung islands': 'BB',
    banten: 'BT',
    bengkulu: 'BE',
    'central java': 'JT',
    'central kalimantan': 'KT',
    'central sulawesi': 'ST',
    'east java': 'JI',
    'east kalimantan': 'KI',
    'east nusa tenggara': 'NT',
    gorontalo: 'GO',
    'jakarta special capital region': 'JK',
    jambi: 'JA',
    lampung: 'LA',
    maluku: 'MA',
    'north maluku': 'MU',
    'north sulawesi': 'SA',
    'north sumatra': 'SU',
    'special region of papua': 'PA',
    riau: 'RI',
    'riau islands': 'KR',
    'southeast sulawesi': 'SG',
    'south kalimantan': 'KS',
    'south sulawesi': 'SN',
    'south sumatra': 'SL',
    'special region of west papua': 'IB',
    'west java': 'JR',
    'west kalimantan': 'KB',
    'west nusa tenggara': 'NB',
    'west sulawesi': 'SR',
    'west sumatra': 'SB',
    'special region of yogyakarta': 'YO',
    'north kalimantan': 'KU',
    'baden-wuerttemberg': 'BA',
    bayern: 'BY',
    berlin: 'BE',
    brandenburg: 'BR',
    bremen: 'BM',
    hamburg: 'HA',
    hessen: 'HE',
    'mecklenburg-vorpommern': 'ME',
    niedersachsen: 'NI',
    'nordrhein-westfalen': 'NO',
    'rheinland-pfalz': 'RH',
    saarland: 'SA',
    sachsen: 'SC',
    'sachsen-anhalt': 'SH',
    'schleswig-holstein': 'SS',
    thueringen: 'TH'
  },
  COLOR_CONFIG: {
    high: 0.5,
    medium: 1.5,
    low: 2.5,
    none: -1,
  },
  CODE_COUNTRY_CONFIG: {
    BHS: "02",

    BLZ: "04",
    CAN: "05",
    CRI: "06",
    CUB: "07",

    DOM: "09",
    SLV: "10",

    GTM: "12",
    HTI: "13",
    HND: "14",
    JAM: "15",
    MEX: "16",
    NIC: "17",
    PAN: "18",

    TTO: "22",
    USA: "23",
    GRL: "24",
    ARG: "25",
    BOL: "26",
    BRA: "27",
    CHL: "28",
    COL: "29",
    ECU: "30",
    FLK: "31",
    ATF: "32",
    GUY: "33",
    PRY: "34",
    PER: "35",
    SUR: "36",
    URY: "37",
    VEN: "38",
    DZA: "39",
    AGO: "40",
    BEN: "41",
    BWA: "42",
    BFA: "43",
    BDI: "44",
    CMR: "45",

    CAF: "47",
    TCD: "48",

    CIV: "50",
    COD: "51",
    DJI: "52",
    EGY: "53",
    GNQ: "54",
    ERI: "55",
    ETH: "56",
    GAB: "57",
    GHA: "58",
    GIN: "59",
    GNB: "60",
    KEN: "61",
    LSO: "62",
    LBR: "63",
    LBY: "64",
    MDG: "65",
    MWI: "66",
    MLI: "67",
    MRT: "68",
    MAR: "69",
    MOZ: "70",
    NAM: "71",
    NER: "72",
    NGA: "73",
    RWA: "74",

    SEN: "76",

    SLE: "78",
    SOM: "79",
    ZAF: "80",
    SDN: "81",
    SWZ: "82",
    TZA: "83",
    TGO: "84",
    TUN: "85",
    UGA: "86",
    ESH: "87",
    ZMB: "88",
    ZWE: "89",
    GMB: "90",
    COG: "91",

    AFG: "93",
    ARM: "94",
    AZE: "95",
    BGD: "96",
    BTN: "97",
    BRN: "98",
    MMR: "99",
    KHM: "100",
    CHN: "101",

    GEO: "103",
    IND: "104",
    IDN: "105",
    IRN: "106",
    JPN: "107",
    KAZ: "108",

    KOR: "110",
    KGZ: "111",
    LAO: "112",
    MYS: "113",
    MNG: "114",
    NPL: "115",
    PAK: "116",
    PHL: "117",
    RUS: "118",

    LKA: "120",
    TJK: "121",
    THA: "122",
    TKM: "123",
    UZB: "124",
    VNM: "125",
    ALB: "129",

    AUT: "131",
    BLR: "132",
    BEL: "133",
    BIH: "134",
    BGR: "135",
    HRV: "136",
    CZE: "137",
    DNK: "138",
    EST: "139",
    FIN: "140",
    FRA: "141",
    DEU: "142",
    GRC: "143",
    HUN: "144",
    ISL: "145",
    IRL: "146",
    ITA: "147",
    LVA: "148",

    LTU: "150",
    LUX: "151",
    MKD: "152",

    MDA: "154",

    MNE: "156",
    NLD: "157",
    NOR: "158",
    POL: "159",
    PRT: "160",
    ROU: "161",

    SRB: "163",
    SVK: "164",
    SVN: "165",
    ESP: "166",
    SWE: "167",
    CHE: "168",
    UKR: "169",
    GBR: "170",

    CYP: "172",
    TUR: "173",
    AUS: "175",
    FJI: "176",

    NZL: "181",

    PNG: "183",

    SLB: "185",

    VUT: "188",
    NCL: "189",

    IRQ: "191",
    ISR: "192",
    JOR: "193",
    KWT: "194",
    LBN: "195",
    OMN: "196",
    QAT: "197",
    SAU: "198",
    SYR: "199",
    ARE: "200",
    YEM: "201",
    PRI: "202",

    SDS: "204",
    OSA: "205",
  },
  ordinalNumberConfig: {
    1: "First",
    2: "Second",
    3: "Third",
    4: "Fourth",
    5: "Fifth",
  },
  COUNTRY_MAP: {
    "North America": ["United States", "Canada", "Mexico"],

    "South America": ["Brazil", "Argentina", "Colombia"],

    Europe: [
      "Russia",

      "Germany",

      "France",

      "United Kingdom",

      "Poland",

      "Italy",

      "Spain",

      "Netherlands",

      "Ukraine",

      "Ireland",

      "Denmark",

      "Bulgaria",

      "Norway",
    ],

    "Asia-Pacific": [
      "China",

      "India",

      "Japan",

      "Australia",

      "South Korea",

      "Vietnam",

      "Thailand",

      "Indonesia",

      "New Zealand",

      "Philippines",

      "Malaysia",

      "Singapore",

      "Pakistan",

      "Hong Kong",
    ],

    "Middle East": ["Turkey", "Saudi Arabia", "Iran", "Israel", "Qatar", "UAE"],

    Africa: ["South Africa", "Kenya", "Egypt", "Morocco", "Nigeria"],
  },
};

export default config;
