import React, { Component } from "react";
import Footer from "../../components/Footer";
import { Form, Input, Button, Spin } from "antd";
import StaticExecutiveSummaryBox from "../../components/ExecutiveSummary/StaticExecutiveSummaryBox";
import ExecutiveSummaryCard from "../../components/ExecutiveSummary/ExecutiveSummaryCard";
import NonEditablComponent from "../../components/NonEditableComponent";

import {
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId,
  saveDraftApi,
  directPublishApi,
} from "../../reducersAndActions/Actions";
import Heading from "../../components/Heading";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../../Common/withWindowSizeHOC";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

var Base64 = require("js-base64").Base64;

class StaticExecutiveSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data || {},
      reseller: false,
    };

    this.formRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  componentDidMount() {
    if (this.props.edit_mode) {
      // this.setValues();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.edit_mode !== prevProps.edit_mode ||
      JSON.stringify(this.props.exec_summary_data) !==
        JSON.stringify(prevProps.exec_summary_data)
    ) {
      if (this.props.edit_mode) {
        // this.setValues();
      }
    }

    if (this.props.draft_drawer_visible !== prevProps.draft_drawer_visible) {
      if (this.props.draft_drawer_visible) {
        this.props.getDrafts(this.props.parent_id, this.props.template_type);
      }
    }

    if (
      this.props.published_drawer_visible !== prevProps.published_drawer_visible
    ) {
      if (this.props.published_drawer_visible) {
        this.props.getPublishedVersions(this.props.parent_id);
      }
    }
  }

  setValues = () => {
    // construct an object with keys(metric+dimension) and values(analysis) and setFieldsValue
    let newObj =
      this.props.exec_summary_data &&
      this.props.exec_summary_data.length &&
      this.props.exec_summary_data.reduce(function (acc, cur) {
        acc[`${cur.metric}@${cur.specificDimension}`] = cur.analysis;
        return acc;
      }, {});

    for (let key in newObj) {
      this.formRef.current.setFieldsValue({
        [key]: newObj[key],
      });
    }

  };

  handleSaveDraft = () => {
    let obj = this.formRef.current.getFieldsValue();
    let newArr = [];
    for (let key in obj) {
      newArr.push({
        metric:
          key.split("@")[0].charAt(0).toUpperCase() +
          key.split("@")[0].slice(1),
        specificDimension: key.split("@")[1].replace(/ /g, "_"),
        analysis: obj[key],
      });
    }

    const data = {
      parent_id: this.props.parent_id,
      type: "executive_summary_static",
      blocks: newArr,
      stage: this.props.draft_versions.stage
        ? this.props.draft_versions.stage
        : "INITIAL",
    };

    this.props.saveDraftApi(data);
  };

  // handleDirectPublish = () => {
  //   let obj = this.formRef.current.getFieldsValue();
  //   let newArr = [];
  //   for (let key in obj) {
  //     newArr.push({
  //       metric:
  //         key.split("@")[0].charAt(0).toUpperCase() +
  //         key.split("@")[0].slice(1),
  //       specificDimension: key.split("@")[1].replace(/ /g, "_"),
  //       analysis: obj[key],
  //     });
  //   }

  //   const data = {
  //     parent_id: this.props.parent_id,
  //     type: this.props.template_type,
  //     blocks: newArr,
  //     directPublished: true,
  //     stage: "PUBLISHED",
  //     cacheId: this.props.cache_id,
  //     hub_id: this.props.TOC_DATA.hub_id,
  //     dimensions: this.props.TOC_DATA.dimensions,
  //   };
  //   this.props.directPublishApi(data);
  // };

  // handleEditoverlayClick = () => {
  //   this.props.setNoDraftsFound(false);
  //   this.props.setDraftStage("INITIAL");
  // };

  render() {
  
    let { section_id, title, subtitle, slug, trends, graphs } = this.state.data
      ? this.state.data
      : {};
    let companycount = 0;

    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    let bgcolor = ["#D7F6EB", "#E6E3FC", "#FFEDC7", "#E0F9FF", "#EEF1F2"];
    let segmentBgcolor = ["#46CDCF", "#ECB449", "#B285FF", "#4A8CBB"];
    let logocolor = ["#BDE3DF", "#C8CBF1", "#EFD699", "#C4E6F3"];
    let hrbgcolor = ["#C1E1DE", "#D0D2EF", "#E4D9BF", "#C5E1EE", "#D5DDE5"];

    if(this.props.edit_mode){
      return <NonEditablComponent/>
    }else{
      return (
        <>
        <div>
        </div>
          {this.props.data.data &&
          this.props.data.data.blocks &&
          this.props.data.data.blocks.length ? (
            <div className="page" id={pageId}>
              <div className="excuSumry">
                <Heading heading="EXECUTIVE SUMMARY & KEY FINDINGS" />
                <div className="excuSumryBoxes">
                  <div className="segment-block-container">
                    {this.props.data.data.boxes &&
                      this.props.data.data.boxes.map(
                        ({ label, value }, k) => (
                          <ExecutiveSummaryCard
                            segmentBgcolor={segmentBgcolor[k]}
                            segmentBlockValue={value}
                            segmentBlockLabel={label}
                          />
                        )
                      )}
                  </div>
                   {this.props.data.data.blocks &&
                    this.props.data.data.blocks.map(
                      (
                        {
                          analysis,
                          block,
                          dimension,
                          icon,
                          metric,
                          name,
                          segment,
                          value,      
                        },
                        index
                      ) => {
                        if (dimension === "company") {
                          companycount++;
                        }

                        let logoname =
                          name && dimension !== "company"
                            ? name.toLowerCase().replace(/ /g, "_")
                            : "";     
                            
                        return (
                          <StaticExecutiveSummaryBox
                            icon={icon ? icon : null}
                            segment={segment}
                            value={value}
                            dimension={dimension}
                            metric={metric}
                            block={block}
                            analysis={analysis}
                            logoname={logoname}
                            companycount={companycount}
                            hrbgcolor={hrbgcolor[index]}
                            bgColor={bgcolor[index]}
                            logocolor={logocolor[index]}
                          />
                        );
                      }
                    )}
                </div>
              </div>

              {!this.props.fromInterface ||
              this.props.fromInterface === undefined ? (
                <Footer
                  slug={slug}
                  slideNo={slideNo}
                  slideNoElementId={slideNoElementId}
                  reseller={this.state.reseller}
                />
              ) : null}
            </div>
          ) : !this.props.fromInterface ||
            this.props.fromInterface === undefined ? null : (
            <h3>No Data</h3>
          )}
        
        </>
      
      );
      }
  }
}

function mapStateToPros(state) {
  return {
    exec_summary_data: state.exec_summary_data,
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_versions: state.draft_versions,
    TOC_DATA: state.TOC_DATA,
    no_drafts_found: state.no_drafts_found,
    parent_id: state.parent_id,
    template_type: state.template_type,
    specific_draft_loading: state.specific_draft_loading,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    cache_id: state.cache_id,
    save_draft_loader: state.save_draft_loader,
    direct_publish_loader: state.direct_publish_loader,
  };
}

const mapDispatchToProps = {
  setDraftId,
  setNoDraftsFound,
  setDraftStage,
  getDrafts,
  getPublishedVersions,
  saveDraftApi,
  directPublishApi,
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(StaticExecutiveSummary))
);
