import React from 'react';
import { NonEditableContainer } from './styles';

export default function NonEditablComponent() {
  return (
     <NonEditableContainer>
        This Section Is Not Editable.
     </NonEditableContainer>
  )
}
