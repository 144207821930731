import styled from "styled-components";

export const NonEditableContainer = styled.div`
    border: 4px solid gray;
    display:flex;
    justify-content:center;
    align-items:center;
    box-sizing: border-box;
    margin-top:.5rem;
    font-size: 20px;
    color:gray;
    font-weight:bold;
    height:670px;
`