import styled from "styled-components";

export const CompanyListWrapper = styled.div`
  .splitted_table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .table_chunks {
    margin : 0px 50px 50px 0;
  }
`;
