import React from "react";
import { Alert } from "antd";
import "antd/dist/antd.css";
import Error from "../../src/images/error.jpg";

class ErrorBoundary extends React.Component {
  state = {
    error: null,
    errorMessage: null,
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    // this.setState({
    //   error: error,
    //   errorMessage: info
    // })

    this.logErrorToServices(error.toString(), info.componentStack);
  }

  logErrorToServices = console.log;

  render() {
    // console.clear();
    console.error(this.state.errorMessage, " =>> ErrorMessage From ErrorBoundary");
    // console.error(this.state.error.toString(), " =>> Error From ErrorBoundary");

    if (this.state.errorMessage) {
      return (
        <div>
          <Alert
            style={{
              width: "50%",
              margin: "auto",
              textAlign: "justify",
            }}
            message={<h2>We're Sorry! </h2>}
            description={<h3>The page is down for maintenance </h3>}
            type="error"
          />

          <img
            src={Error}
            alt="Error"
            width={400}
            height={320}
            style={{ display: "flex", margin: "auto", paddingTop: "10px" }}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
