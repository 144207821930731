import React , {useState, useEffect, }from 'react';
// import xlsx from "json-as-xlsx";
import axios from 'axios';
import config from '../../config/Api';
import {Select, Spin, notification } from "antd";
import { connect } from "react-redux";
import { MasterMEsExportContainer , MasterMEsExportForm, CloseModal} from './styles';
import {
  setSearchParams,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setDraftStage,
} from "../../reducersAndActions/Actions";

const base_url = config.api.base_url;
const {Option} = Select;

function MasterMEsExport(props) {

   let [hubList, setHubList] = useState([]);
   let [currentHub, setCurrentHub] = useState(null);
   let [clicked, setClicked] = useState(false);

   let closeModal = ()=>{
    props.setSearchParams(false);
    props.setMarketActivityParams(false);
    props.setDownloadReportsParams(false);
    props.setMasterMEParams(false);
    props.setDraftStage("INITIAL");
   }

useEffect(()=>{
  let full_access=false;
    axios
    .get(`${base_url}/api/hubs/list?full_access=${full_access}`)
    .then((response) => {
        if (response.status === 200) {
            let hubs = [...response.data.obj].filter(hub=> {
               return hub.name.toLowerCase() !== 'zero';
            });
            let args = {
                message: 'Success',
                description: "Hub List Found.",
                duration: 2,
                placement: "topRight",
              };
              notification.success(args)
           setHubList(hubs);
        }})
    .catch((error) => {
        let args = {
            message: error,
            description: "Hub List Not Found.",
            duration: 1,
            placement: "topRight",
          };
          notification.error(args)
          console.log(error);
        });
}, []);

let hubChange =(value)=>{
    setCurrentHub(value);
}

let handleSubmit= (e)=>{
  e.preventDefault();
   setClicked(true);
 
    let args = {
      message: "MasterME Sheet Is Being Downloaded",
      description:"It may take few Minutes.", 
      duration: 5,
      placement: "topRight",
    };
    notification.warn(args);

    axios
    .get(`${base_url}/api/interfaceQueries/masterSheet?hub_id=${currentHub}&type=ME`)
    .then((response) => {
        if (response.status === 200) {

// If Data From Backend Is Coming In JSON Format We Are Using "json-as-xlsx" Package To Convert In xlsx File And Download.

// let columnsData = response.data[0];
// let columns = [];
// let content = [];

// for(let key in columnsData){
//    columns.push({label: key, value: key})
// }

// response.data.map((item)=>{
//   let obj={};
//   for(let key in item){
//     obj[key] = item[key]
//   }
// content.push(obj);
// })

// let data = [
//   {
//     sheet: "Master ME Sheet",
//     columns: columns,
//     content: content
//   }
// ];

// let now = new Date();
// let nowDate = now.toDateString();
// let nowTime = now.toLocaleTimeString();
// let name = `MasterME Sheet ${nowDate + " " + nowTime}`;
//     name = name.replaceAll(" ", "_")

// let settings = {
//             fileName: name, // Name of the resulting spreadsheet
//             extraLength: 3, // A bigger number means that columns will be wider
//             writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
//             writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
//             RTL: true, // Display the columns from right-to-left (the default value is false)
// };

// let callback = function (sheet) {
// }

// xlsx(data, settings, callback);

let link = document.createElement('a');
    link.href= response.data?.url;
    link.download = true;
    link.click();

let args = {
  message: 'Success',
  description: response.data.message,
  duration: 2,
  placement: "topRight",
};
notification.success(args);
setClicked(false);
}})
    .catch((error) => {
        let args = {
            message: error,
            description: "Can Not Download MasterME Sheet",
            duration: 2,
            placement: "topRight",
          };
          notification.error(args);
          console.log(error);
          setClicked(false);
        });
}
  return (
    <MasterMEsExportContainer>
    <MasterMEsExportForm>

    <CloseModal onClick={closeModal}>
            X
        </CloseModal>
    <legend>Download Master MEs</legend>
<div>
 {
  hubList.length ?
  <>
    <Select
     value={currentHub}
     style={{width: 300, marginRight: '10px'}}
     onSelect={hubChange}
     placeholder={'Select Hub To Download Master MEs'}>
    {
        hubList.length && hubList.map(hub=>{
            return(<><Option value={`${hub.id}`}>{hub.name.toUpperCase()}</Option></>)
        })
    }
    </Select>

   {
    (currentHub !== null)
    ?
    (
      !clicked
      ?
    <button  onClick={(e)=>handleSubmit(e)}>
      Download
    </button>
    : <button><Spin/></button>)
    : null
  }
  </>
: <Spin/>
}
</div>
    </MasterMEsExportForm>
    </MasterMEsExportContainer>
  )
}

function mapStateToPros(state) {
  return {
    IS_AUTH: state.IS_AUTH,
    SEARCH_PARAMS: state.SEARCH_PARAMS,
    MARKET_ACTIVITY_PARAMS: state.MARKET_ACTIVITY_PARAMS,
    DOWNLOAD_REPORTS_PARAMS: state.DOWNLOAD_REPORTS_PARAMS,
    MASTER_ME_PARAMS: state.MASTER_ME_PARAMS,
    TOC_DATA: state.TOC_DATA,
    USER_DATA: state.USER_DATA,
  };
}
const mapDispatchToProps = {
  setSearchParams,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setDraftStage,
};

export default connect(mapStateToPros, mapDispatchToProps)(MasterMEsExport);

