import React, { Component } from "react";
import { Table, Typography } from "antd";
import Footer from "../components/Footer";
import { maintainTableText } from "../Common/maintainTableText";
import { Base64 } from "js-base64";
import DataHidden from "../components/DataHidden";
// const { Title } = Typography;

let styleObj = {
  fontSize: "17px",
  width: "fit-content",
  fontWeight: "400",
  color: "#FFFFFF",
  backgroundColor: "#4472C4",
  lineHeight: "20px",
  letterSpacing: "0px",
  wordSpacing: "4px",
};

export class CompetitiveLandscape extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }

  componentDidMount() {
    this.setState({ list: this.props.data.data.global_data });
  }
  formatColumns = (columns) => {
    let newCols = [];
    columns.forEach((col) => {
      newCols.push({
        title: maintainTableText(
          col.label,
          col.textsize ? col.textsize : 150,
          col.styleObj ? col.styleObj : styleObj
        ),
        dataIndex: col.key,
        key: col.key,
        align: col.align ? col.align : "center",
        width: col.width ? col.width : "20%",
      });
    });
    return newCols;
  };

  render() {
    const { slideNo } = this.props;
    const { section_id } = this.props.data.meta;
    const allColumns = this.props.data.data.headers_order;
    const pageId = section_id && Base64.encode(section_id);
    let columnsModifiedData = this.formatColumns(allColumns);

    return (
      <>
        <div id={pageId} className="page">
          <div className="Landscape container">
            <div>
              <h2 className="heading">
                {!this.props.fromInterface ||
                this.props.fromInterface === undefined
                  ? this.props.template_title ?? this.props.data.title
                  : this.props.data.title}
              </h2>
              <Table
                className="LandscapeTable"
                bordered={false}
                pagination={false}
                columns={columnsModifiedData}
                dataSource={this.state.list}
                scroll={{ x: 400 }}
              />
              {this.props.data?.meta?.sample ? <DataHidden /> : ""}
            </div>
          </div>
          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer slideNo={slideNo} reseller={this.state.reseller} />
          ) : null}
        </div>
      </>
    );
  }
}

export default CompetitiveLandscape;
