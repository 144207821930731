import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
// import BoldTextTool from "../Common/RteCustomFunctions/BoldTextTool";

import { EDITOR_JS_TOOLS } from "../constants";
import { message, Button } from "antd";
import DataHidden from "./DataHidden";
import {
  setLinkCounter,
  setCharacterCountError,
  setAnalysisEditorState,
  setAnalysisCharCount,
  setDraftDataHeading,
} from "../reducersAndActions/Actions";
import {Input} from "antd";
import { connect } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import parse from "html-react-parser";

const EDITTOR_HOLDER_ID = "analysis-editor";

const Analysis = (props) => {
  const ejInstance = useRef();
  const [skip, setSkip] = useState(false);

  useEffect(() => {
    let sum = props.draft_data_analysis.reduce(
      (sum, ele) => sum + ele?.length,
      0
    );
    props.setAnalysisCharCount(sum);

    props.setAnalysisEditorState(
      props.draft_data_analysis && props.draft_data_analysis.length
        ? props.draft_data_analysis.map((item) => item)
        : []
    );

    if (!ejInstance.current) {
      ejInstance.current = new EditorJS({
     
        data: {
          time: new Date().getTime(),
          blocks: [
            {
              type: "list",
              data: {
                style: "unordered",
                items:
                  props.draft_data_analysis && props.draft_data_analysis.length
                    ? props.draft_data_analysis.map((item) => item)
                    : [],
              },
            },
          ],
        },
        holder: EDITTOR_HOLDER_ID,
        autofocus: true,
        tools: EDITOR_JS_TOOLS,
        onChange: handleSave,
      });
    } else {
      if (ejInstance && ejInstance.current && ejInstance.current.isReady) {
        ejInstance.current.isReady
          .then(() => {
            ejInstance.current.clear();
            ejInstance.current.blocks.render({
              blocks: [
                {
                  type: "list",
                  data: {
                    style: "unordered",
                    items:
                      props.draft_data_analysis &&
                      props.draft_data_analysis.length
                        ? props.draft_data_analysis.map((item) => item)
                        : [],
                  },
                },
              ],
              holder: EDITTOR_HOLDER_ID,
              inlineToolbar: ['link', 'marker', 'bold', 'italic'],
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [JSON.stringify(props.draft_data_analysis)]);


  async function handleSave() {
    let countBrTags = 0;
    let countNBSPTags = 0;

    ejInstance.current
      .save()
      .then((outputData) => {
        let mergedArray = outputData.blocks
          .map((item) => item.data.items)
          .flat(Infinity);

        for (let i = 0; i < mergedArray.length; i++) {
          countNBSPTags += (mergedArray[i].match(/&nbsp;/g) || []).length;
          countBrTags += (mergedArray[i].match(/<br>/g) || []).length;
        }

        countNBSPTags = countNBSPTags * 5;
        countBrTags = countBrTags * 4;

        let result = mergedArray
          .filter(Boolean)
          .reduce((sum, ele) => sum + ele.length, 0);

        result = result > 0 ? result - countNBSPTags : result;
        result = result > 0 ? result - countBrTags : result;

        props.setAnalysisCharCount(result);
        // Show char count error to everyone except QC designation
        if (
          props.USER_DATA.obj.designation !== "QC" &&
          result > props.character_count
        ) {

          props.setCharacterCountError(true);
          if (!skip) {
            message.destroy();
            message.warning({
              message: "Info",
              content: (
                <div>
                  <InfoCircleOutlined />
                  Character count is more than {props.character_count}
                </div>
              ),
              icon: (
                <Button onClick={handleSkip} type="primary">
                  Don't show again
                </Button>
              ),
              duration: 5,
            });
          }
        } else {
          props.setCharacterCountError(false);
        }

  //  if(mergedArray && mergedArray.length === 0){
  //   if(props?.draft_data_analysis && props.draft_data_analysis?.length ){
  //     mergedArray = [...props.draft_data_analysis]
  //    }
  //  }

        props.setAnalysisEditorState(mergedArray);
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  }

  const handleSkip = () => {
    setSkip(true);
    message.destroy();
  };

  //DOES Analysis array contain hidden items?
  let hiddenItemExists =
    !props.edit_mode &&
    props.trends.analysis.some((item) => item.text === "hidden");

  return (
    <div
      style={{
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >

      {props.fromRegulatoryFrameWork && hiddenItemExists ? (
        <DataHidden />
      ) : props.edit_mode ? (
        <React.Fragment>
          <div id={EDITTOR_HOLDER_ID}> </div>
        </React.Fragment>
      ) : (
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            marginBottom: "0",
          }}
        >
          {props.trends.analysis && props.trends.analysis.length
            ? props.trends.analysis.map(
                ({ text, superScript, hyperLink, counter }, index) => {
                  return text && text.toLowerCase() !== "hidden" ? (
                   <>
                
                    <li key={index}>
                      <span>
                        {parse(`${text}`)}{" "}
                        {hyperLink.length ? (
                          <sup>
                            [<a href={"#" + hyperLink}>{counter}</a>]
                          </sup>
                        ) : null}
                      </span>
                    </li>
                   </>
                  ) : text && text.toLowerCase() === "hidden" ? (
                    <DataHidden />
                  ) : null;
                }
              )
            : ""}
        </ul>
      )}
    </div>
  );
};

function mapStateToPros(state) {
  return {
    ANALYSIS_LINK_COUNTER: state.ANALYSIS_LINK_COUNTER,
    draft_data_analysis: state.draft_data_analysis,
    draft_data_heading: state.draft_data_heading,
    analysis_editor_state: state.analysis_editor_state,
    USER_DATA: state.USER_DATA,
  };
}

const mapDispatchToProps = {
  setLinkCounter,
  setAnalysisEditorState,
  setCharacterCountError,
  setAnalysisCharCount,
  setDraftDataHeading,
};

export default connect(mapStateToPros, mapDispatchToProps)(Analysis);
