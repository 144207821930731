import React, { useState, useEffect } from "react";
import { PurgeReportsComponentWrapper } from "./PurgeReports.style";
import InterfaceHeader from "../InterfaceHeader";
import { Button, Checkbox, Select, Spin, notification } from "antd";
import axios from "axios";
import configApi from "../../config/Api";
import config from "../../config/Config";
import { InfoCircleOutlined } from "@ant-design/icons";

const openNotification = (msg) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Purge successful",
      duration: 0,
      placement: "bottomLeft",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to purge titles",
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.warning(args);
  }
};
const formData = new FormData();
let cancelTokenOfTitles = axios.CancelToken.source();

function PurgeReportsComponent() {
  const [hubData, setHubData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onGoingApiCall, setOnGoingApiCall] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [titlesList, setTitlesList] = useState(null);
  const [defaultTitlesList, setDefaultTitlesList] = useState([]);
  const [modalTitlesLoading, setModalTitlesLoading] = useState(false);
  const [defaultRegions, setDefaultRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [titles, setTitles] = useState(false);
  const [purgeItems, setPurgeItems] = useState({
    report_url: true,
    sample_url: true,
    reseller_sample_url: true,
    ppt_url: true,
    sample_ppt_url: true,
  });

  const [errors, setErrors] = useState(null);
  const [branches, setBranches] = useState(null);
  const [infoTabVis, setInfoTabVis] = useState(false);

  const toTitleCase = (str) => {
    return (str + "").toUpperCase();
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const getHubList = async () => {
    setLoading(true);
    if (!hubData) {
      let full_access = false;
      await axios
        .get(
          `${configApi.api.base_url}/api/hubs/list?full_access=${full_access}`
        )
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list");
          setLoading(false);
        });
    }
  };

  const getListTitles = () => {
    if (!selectedHub) {
      openNotification("Please select a Hub");
    }

    let payload = {
      hub_id: selectedHub,
      dimensions_array: {
        region: selectedRegions,
        country: selectedCountry,
      },
    };
    setTitlesList([]);
    setDefaultTitlesList([]);
    setModalTitlesLoading(true);

    axios
      .post(`${configApi.api.base_url}/api/getPdfTitles`, payload, {
        cancelToken: cancelTokenOfTitles.token,
      })
      .then((res) => {
        if (res.data.data.length) {
          setTitlesList(res?.data?.data);
          setDefaultTitlesList(res?.data?.data);
          setModalTitlesLoading(false);
          setTitles(true);
        } else {
          setTitlesList([]);
          setDefaultTitlesList([]);
          setModalTitlesLoading(false);
          setTitles(true);
        }
      })
      .catch((error) => {
        console.log(error);
        cancelTokenOfTitles = axios.CancelToken.source();
        setTitlesList([]);
        setDefaultTitlesList([]);
        setModalTitlesLoading(false);
        setTitles(false);
      });
  };

  const selectTitles = (e, item) => {
    let filteredList = [],
      newfilteredList = [];
    if (!e.target.checked) {
      filteredList = defaultTitlesList.filter((CheckedItem) => {
        return item._id !== CheckedItem._id;
      });
    } else {
      newfilteredList.push(item);
      filteredList = [...newfilteredList];
    }
    if (filteredList.length) {
      setDefaultTitlesList(filteredList);
    } else {
      setDefaultTitlesList([]);
    }
  };

  const onChangeRegion = (value) => {
    if (value.length) {
      setSelectedRegions(value);
    } else {
      setSelectedRegions([]);
    }

    if (value.length === 1 && value[0] === "All") {
      // this.setState({
      //   selected_countries: [],
      //   Select_country_disabled: true,
      // });
      setSelectedRegions([]);
    }
  };

  const onChangeCountry = (value) => {
    if (value.length) {
      setSelectedCountry(value);
      // this.setState({
      //   selected_countries: value,
      // });
    } else {
      setSelectedCountry([]);

      // this.setState({
      //   selected_countries: [],
      // });
    }
  };

  const handlePurgeItems = (event) => {
    setPurgeItems((prev) => ({
      ...prev,
      [event.target.value]: event.target.checked,
    }));
  };

  function getToken() {
    return window.localStorage.getItem("token");
  }

  const handlePurge = () => {
    let purgeItemsclean = [];
    Object.keys(purgeItems).forEach((item) => {
      if (purgeItems[item] == true) {
        if (item === "report_url") {
          purgeItemsclean.push("recent_report_url");
        }
        purgeItemsclean.push(item);
      }
    });

    try {
      axios
        .post(
          `${configApi.api.base_url}/api/additional_routes/purgeTitles`,
          {
            data: {
              hub_id: selectedHub,
              countries,
              regions: selectedRegions,
              reports: defaultTitlesList,
              purgeItems: purgeItemsclean,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          openNotification(true);
          setOnGoingApiCall(false);
          setErrors(null);
        })
        .catch((error) => {
          console.error(error);
          setOnGoingApiCall(false);
          openNotification(error?.message || false);
        });
    } catch (error) {
      console.error(error);
      setOnGoingApiCall(false);
      openNotification(error?.message || false);
    }

    try {
    } catch (error) {}
  };

  const purgeCompleteHub = () => {
    try {
      axios
        .post(
          `${configApi.api.base_url}/api/additional_routes/purgeTitles`,
          {
            data: {
              hub_id: selectedHub,
              countries,
              regions: selectedRegions,
              purgeItems: Object.keys(purgeItems).filter(
                (item) => purgeItems[item]
              ),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          openNotification(true);
          setOnGoingApiCall(false);
          setErrors(null);
        })
        .catch((error) => {
          console.error(error);
          setOnGoingApiCall(false);
          openNotification(error?.message || false);
        });
    } catch (error) {
      console.error(error);
      setOnGoingApiCall(false);
      openNotification(error?.message || false);
    }
  };

  useEffect(() => {
    getHubList();
    let regions;
    regions = Object.keys(config.COUNTRY_MAP);
    regions.unshift("All");
    setDefaultRegions(regions);
  }, []);

  useEffect(() => {
    let arr = [];
    let regions = selectedRegions.filter((regn, k) => {
      return regn !== "All";
    });
    regions.map((item, i) => {
      arr.push(...config.COUNTRY_MAP[item]);
    });
    setCountries(arr);
  }, [selectedRegions]);

  return (
    <>
      <InterfaceHeader />
      <PurgeReportsComponentWrapper>
        <div
          style={{
            marginTop: "8rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="card">
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  margin: "20px 0 30px",
                }}
              >
                Purge Reports Titles
              </h2>
              <div className="selectDropdownContainer">
                <p>
                  Select Hub{"    "}
                  <span className="mandatory">*</span> :{" "}
                </p>
                <Select
                  disabled={onGoingApiCall}
                  id="SelectHub"
                  placeholder="Select Hub"
                  defaultValue="Select Hub"
                  value={selectedHub}
                  loading={loading}
                  maxLength=""
                  onSelect={(e) => {
                    setSelectedHub(e);
                    setTitles(false);
                    setTitlesList([]);
                    setDefaultTitlesList([]);
                  }}
                  options={
                    hubData
                      ?.filter((item, index) => item.name !== "ZEROHUB")
                      ?.map((option) => ({
                        value: option.id,
                        label: toTitleCase(option.name),
                      })) || []
                  }
                />
              </div>
              <div
                style={{
                  margin: "5px 0",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {selectedHub && (
                  <Button
                    style={{
                      background: "red",
                      borderColor: "red",
                    }}
                    onClick={purgeCompleteHub}
                    type="primary"
                  >
                    Purge Complete Hub
                  </Button>
                )}
              </div>
              <div className="selectDropdownContainer">
                <p className="exportLabels">Select Region : </p>
                <Select
                  placeholder="Select Region"
                  allowClear
                  value={selectedRegions}
                  onChange={onChangeRegion}
                  mode="multiple"
                  options={
                    defaultRegions?.map((item) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                />
              </div>
              <div className="selectDropdownContainer">
                <p className="exportLabels">Select Country : </p>
                <Select
                  placeholder="Select Country"
                  allowClear
                  disabled={!selectedRegions.length}
                  value={selectedCountry}
                  onChange={onChangeCountry}
                  mode="multiple"
                  options={
                    countries?.map((item) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                />
              </div>
              {selectedHub && (
                <div>
                  <h4>Select what do you want to purge : </h4>
                  <Checkbox
                    checked={purgeItems.report_url}
                    value="report_url"
                    onChange={handlePurgeItems}
                  >
                    Report URL
                  </Checkbox>
                  <Checkbox
                    checked={purgeItems.sample_url}
                    value="sample_url"
                    onChange={handlePurgeItems}
                  >
                    Sample Sample
                  </Checkbox>
                  <Checkbox
                    checked={purgeItems.reseller_sample_url}
                    value="reseller_sample_url"
                    onChange={handlePurgeItems}
                  >
                    Reseller Sample URL
                  </Checkbox>
                  <Checkbox
                    checked={purgeItems.ppt_url}
                    value="ppt_url"
                    onChange={handlePurgeItems}
                  >
                    PPT URL
                  </Checkbox>
                  <Checkbox
                    checked={purgeItems.sample_ppt_url}
                    value="sample_ppt_url"
                    onChange={handlePurgeItems}
                  >
                    Sample PPT URL
                  </Checkbox>
                </div>
              )}
              <div
                style={{
                  margin: "15px 0",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {" "}
                {selectedHub && (
                  <Button onClick={getListTitles} type="primary">
                    Get Titles List
                  </Button>
                )}
              </div>

              <Spin tip="Loading..." spinning={modalTitlesLoading}></Spin>
              <div className="titlesListContainer">
                <div className="export-antd-container uploadsection">
                  <div className="titlesContainer">
                    {titles && titlesList.length > 0 ? (
                      <>
                        <div className="titlesWrapper">
                          {titlesList?.map((item) => (
                            <Checkbox
                              onChange={(e) => selectTitles(e, item)}
                              defaultChecked={true}
                            >
                              {item.title}
                            </Checkbox>
                          ))}
                        </div>
                      </>
                    ) : (
                      // : modalTitlesLoading ? (
                      //   ""
                      // )
                      titles &&
                      titlesList?.length === 0 && (
                        <h4>* No titles are present</h4>
                      )
                    )}
                  </div>
                </div>
              </div>
              {titles && titlesList.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                  onClick={handlePurge}
                >
                  <Button type="primary">Purge selected Titles</Button>
                </div>
              )}
            </div>

            <div className={infoTabVis ? "infoContainer" : "info"}>
              {/* <InfoCircleOutlined title="Company profile only .png and Executive summary only .svg" /> */}
              <Button
                type="default"
                danger={infoTabVis}
                onClick={() => setInfoTabVis(!infoTabVis)}
              >
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div className="infoContent">
                  <b>
                    Purge Reports will clear the selected URLS from the Data
                    base. post purge the Reports need to regenerate.
                  </b>
                  <br />

                  <ul>
                    <li>select a hub</li>
                    <li>Purge Hub completely or select region and country</li>
                    <li>select what do you want to purge</li>
                    <li>Get Titles List</li>
                    <li>Select Titles</li>
                    <li>Purge selected Titles</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </PurgeReportsComponentWrapper>
    </>
  );
}

export default PurgeReportsComponent;
