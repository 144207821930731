import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import {COLORS} from "../graphs.configs";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

export default  class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1 ljkshdlkjsbd slkkkkdnlkmsf klshd.kjsdbms",
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      let datas = this.props.data.data;
      let catagory = this.props.data.catagory;
      this.setState({
        datas: datas,
        loadingData: false,
        data: this.props.data.data,
        catagory: catagory,
      });
    }
  }

  render() {
    let data = "",
        catagory = "",
        width = 740,
        height = 220,
        legendItemFontSize=14,
        headers = "";
      ;

    data = this.state.data;
    catagory = this.state.catagory;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    
    if(data.length>=8) legendItemFontSize = 11;
  
  let chartConfigs = {
      type: "msbar2d",
      dataFormat: "json",
      width: width,
      height: height,
      id: this.props.id ? this.props.id : "stacked",
      dataSource: {
      chart: {
          theme: "fusion",
          animation: false,
          showanchors: 1,

          formatNumberScale: 0,
          numberScaleValue: "1000,1000,1000",
          numberScaleUnit: "k,M,B",

          paletteColors: COLORS,
          plotSpacePercent: 40,
          minPlotHeightForValue: 0,

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#ffffff",
          divLineAlpha: "80",
          divLineDashed: "0",

          showLabels: true,
          labelFont: "Avenir Medium",
          labelFontColor:"#7f7f7f",
          labelFontSize: 14,
          labelDisplay: "WRAP",

          legendItemFont: "Avenir Medium",
          legendItemFontColor:"#7f7f7f",
          legendItemFontSize: legendItemFontSize,
          legendPosition: "absolute",
          legendNumColumns: 1,
          legendXPosition: 360,
          legendYPosition: 48+this.props.legendsYPosition,
          
          showValues: this.props.labels === true ? true : false,
          valueFont: "Avenir Heavy",
          valueFontSize: 12,
          valueFontBold: true,
          valueFontColor: "#ffffff",
          placeValuesInside: "0",

          showYAxisLine: false,
          showYAxisValues: false,
          yAxisNamePadding: 0,

          showXAxisLine: false,
          centerXAxisName: false,

          chartTopMargin: 80+this.props.chartHeadingGap,
          chartBottomMargin: 0,
          chartRightMargin: 200,

          baseFont: "Avenir Medium",
          baseFontSize:14,
          baseFontColor:"#7f7f7f"
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers+"").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${570+this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                }
              ]
            }
          ],
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };

    return (
        <div>
          <ReactFC {...chartConfigs} />
        </div>
    );
  }
}



