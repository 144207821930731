import React, { Component } from "react";
import { Input, Spin } from "antd";
import axios from "axios";
import config from "../config/Api";

const { Search } = Input;

class SearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      openSearchResult: false,
      loading: false,
      searchData: [],
    };
    this.timeout = 0;
  }

  onclickOfSearch = () => { };

  onInputSearch = (event) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState({
      loading: true,
      searchValue: event.target.value,
      searchData: [],
    });

    if (event.target.value) {
      this.timeout = setTimeout(() => {
        let url = config.api.base_url;

        axios
          .get(`${url}/api/searchReport`, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            params: {
              title: this.state.searchValue,
              pageNo: "1",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.setState({
                loading: false,
                searchData: response.data,
              });
            } else {
              this.setState({
                loading: false,
                searchData: [],
              });
            }
          })

          .catch((err) => {
            this.setState({
              searchValue: "",
              loading: false,
            });
          });
      }, 1000);
    }
  };

  render() {
    return (
      <div style={{ width: "60%", margin: "200px auto" }}>
        <div className="searchContainer">
          <Search
            placeholder="Type to Search..."
            allowClear
            enterButton="Search"
            size="large"
            onSearch={this.onclickOfSearch}
            onChange={this.onInputSearch}
          />
          {this.state.searchValue ? (
            <div className="searchResult">
              <Spin spinning={this.state.loading}>
                {typeof this.state.searchData != "string" &&
                  this.state.searchData &&
                  this.state.searchData.length ? (
                  this.state.searchData.map((item, i) => {
                    return (
                      <div>
                        <p
                          onClick={() => this.props.setGetTocParams(item)}
                          className="search-list-element"
                        >
                          {item.title}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p>{this.state.loading ? "" : "Nodata"}</p>
                )}
              </Spin>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default SearchComponent;
