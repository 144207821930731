import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import { connect } from "react-redux";
import { COLORS } from "../graphs.configs";
import {
  breakLabels,
  maximumStack,
  intToString,
  firstColumnSumValue,
} from "../graphs.helperFunctions";
import { withRouter } from "react-router";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

let COLOR = COLORS.split(',').map(item => {
  return item.trim();
});

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: 0,
      total_legend_length: "",
      max_legend_length: 0,
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1",
          showValue: true,
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
    this.bytesToString = this.bytesToString.bind(this);
    this.customizeCaptionMargin = this.customizeCaptionMargin.bind(this);
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      let datas = this.props.data.data;
      let maxValue = maximumStack(datas);
      let catagory = this.props.data.catagory,
        new_data = [...this.props.data.data];
      let total_legend_length = "",
        max_legend_length = 0;
      new_data = new_data.map((item) => {
        if (item.seriesname.length > max_legend_length)
          max_legend_length = item.seriesname.length;
        // item.seriesname = this.bytesToString(item.seriesname, 35)
        item.seriesname = breakLabels(item.seriesname, 25);
        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname;
        return item;
      });

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      JSON.stringify(prevProps.data.data) !==
        JSON.stringify(this.props.data.data)
    ) {
      let datas = this.props.data.data;
      let maxValue = maximumStack(datas);
      let catagory = this.props.data.catagory,
        new_data = [...this.props.data.data];
      let total_legend_length = "",
        max_legend_length = 0;
      new_data = new_data.map((item) => {
        if (item.seriesname.length > max_legend_length)
          max_legend_length = item.seriesname.length;
        item.seriesname = item.seriesname.replace(/<br\/>/g, "");
        // item.seriesname = this.bytesToString(item.seriesname, 35);
        item.seriesname = breakLabels(item.seriesname, 25);

        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname;
        return item;
      });

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length,
      });
    }
  }

  bytesToString(d, width) {
    let str = "";
    let computed_array = [];

    let string_arr = d.trim().split(" ");
    for (let item of string_arr) {
      if ((item + str).length >= width) {
        computed_array.push(str);
        str = "";
      }
      str += " " + item;
    }
    if (str !== "") computed_array.push(str);

    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1)
        caption += computed_array[i] + "<br/>";
      else caption += computed_array[i];
    }
    return caption;
  }

  customizeCaptionMargin(currentMargin) {
    const { template_name, headers } = this.props;
    //We only want extra margin for template 1100 and only if its too big
    if (template_name !== "1100" && headers.length < 80) {
      return currentMargin;
    }
    return 90; // This is the margin between Chart's Caption/header and its graph
  }

  render() {
    let data = "",
      catagory = "",
      rotateValues = 0,
      legendsPositionDynamic = false,
      max_legend_length = 0,
      valueFontSize = 12,
      width = 740,
      height = 220,
      id = "1",
      headers = "",
      legendLength = 14,
      seriesName = [],
      legendsData = [],
      others = [],
      newData = [];

    data = this.state.data;
    catagory = this.state.catagory;
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.valueFontSize) valueFontSize = this.props.valueFontSize;
    if (this.props.rotateValues === 1) rotateValues = this.props.rotateValues;
    if (this.props.legendsPositionDynamic)
      legendsPositionDynamic = this.props.legendsPositionDynamic;
    if (this.props.id) id = this.props.id;

    if (data.length > 5) legendLength = 11;
    if (valueFontSize === 11) legendLength = data.length > 5 ? 11 : 14;
    if (legendsPositionDynamic) legendLength = 11;
    if (this.state.max_legend_length < 35)
      max_legend_length = 35 - this.state.max_legend_length;

    data.map((item) => {
      seriesName.push({ seriesname: item.seriesname });
    });

    seriesName = seriesName
      .sort((a, b) => {
        return a.seriesname.localeCompare(b.seriesname);
      })
      .map((series, index) => {
        return { ...series, color: COLOR[index] };
      });
    let x1 = new RegExp("^other.*"),
      x2 = new RegExp("^rest.*");
    seriesName = seriesName.filter((series) => {
      if (
        x1.test(series.seriesname.toLowerCase()) ||
        x2.test(series.seriesname.toLowerCase())
      ) {
        others.push(series);
        return false;
      } else {
        return true;
      }
    });
    if (others.length !== 0)
      others.map((series_item) => seriesName.push(series_item));

    data.map((item) => {
      seriesName.map((series) => {
        if (item.seriesname === series.seriesname) {
          newData.push({ ...item, color: series.color });
        }
      });
    });

    data = [...newData];

    if (this.props.template_name === "1000") {
      seriesName.map((series, i) => {
        let yCoordinate = "0";

        if (i === 0) {
          yCoordinate = "65";
        } else if (i === 1) {
          yCoordinate = "90";
        } else if (i === 2) {
          yCoordinate = "115";
        } else if (i === 3) {
          yCoordinate = "140";
        } else if (i === 4) {
          yCoordinate = "165";
        } else if (i === 5) {
          yCoordinate = "190";
        } else if (i === 6) {
          yCoordinate = "215";
        } else if (i === 7) {
          yCoordinate = "240";
        } else if (i === 8) {
          yCoordinate = "265";
        } else if (i === 9) {
          yCoordinate = "290";
        } else if (i === 10) {
          yCoordinate = "315";
        } else {
          yCoordinate = "340";
        }

        let colorData = {
          id: `shape-${i}`,
          type: "polygon",
          startangle: "45",
          sides: "4",
          radius: "7",
          color: `${series.color}`,
          x: `${this.props.fromInterface === true ? 920 : 1030}`,
          y: yCoordinate,
        };

        let seriesData = {
          id: `label-${i}`,
          type: "text",
          align: "left",
          fillcolor: "#7f7f7f",
          fontsize: 11,
          font: "Avenir Medium",
          text: `${series.seriesname}`,
          bold: "0",
          wrap: "1",
          x: `${this.props.fromInterface === true ? 930 : 1040}`,
          y: yCoordinate,
        };
        legendsData.push(colorData, seriesData);
      });
    }

    if (this.props.template_name === "1111") {
      seriesName.map((series, i) => {
        let yCoordinate = "0";

        if (i === 0) {
          yCoordinate = "65";
        } else if (i === 1) {
          yCoordinate = "85";
        } else if (i === 2) {
          yCoordinate = "105";
        } else if (i === 3) {
          yCoordinate = "125";
        } else if (i === 4) {
          yCoordinate = "145";
        } else if (i === 5) {
          yCoordinate = "165";
        } else if (i === 6) {
          yCoordinate = "185";
        } else if (i === 7) {
          yCoordinate = "205";
        } else if (i === 8) {
          yCoordinate = "225";
        } else if (i === 9) {
          yCoordinate = "245";
        } else {
          yCoordinate = "265";
        }

        let colorData = {
          id: `shape-${i}`,
          type: "polygon",
          startangle: "45",
          sides: "4",
          radius: "7",
          color: `${series.color}`,
          x: `${390}`,
          y: yCoordinate,
        };

        let seriesData = {
          id: `label-${i}`,
          type: "text",
          align: "left",
          fillcolor: "#7f7f7f",
          fontsize: 11,
          font: "Avenir Medium",
          text: `${series.seriesname}`,
          bold: "0",
          wrap: "1",
          x: `${400}`,
          y: yCoordinate,
        };

        legendsData.push(colorData, seriesData);
      });
    }

    let chartConfigs = {
      type: "stackedcolumn2d",
      dataFormat: "json",
      width: width,
      height: height,
      id: id,
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showAnchors: 1,

          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000",
          numberScaleUnit: "k,M,B",

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#E5E5E5",
          divLineAlpha: "80",
          divLineDashed: "0",

          decimals: 1,
          paletteColors: COLORS,
          showPlotBorder: "0",
          minPlotHeightForValue: 10,
          plotSpacePercent: 40,

          showsum: !this.props.dataHidden,
          sumPadding: "15",

          showValues: this.props.showGraphVal ? 1 : 0,
          valueFont: "Avenir Heavy",
          valueFontColor: this.props.showGraphVal ? "#ffff" : "#002F75",
          valueFontSize:
            this.props.width <= 566 ? valueFontSize - 2 : valueFontSize,
          valueFontBold: true,
          placeValuesInside: "0",
          rotateValues: rotateValues,

          labelFontColor: "#7f7f7f",
          labelFontSize: this.props.template_name === "1111" ? 10 : 14,

          showLegend:
            this.props.template_name === "1000" ||
            this.props.template_name === "1111"
              ? 0
              : 1,
          legendItemFont: "Avenir Medium",
          legendItemFontSize: legendLength,
          legendItemFontColor: "#7f7f7f",
          legendPosition: "bottom",
          legendXPosition: this.props.legendsXPosition
            ? this.props.legendsXPosition
            : 370 + max_legend_length,
          legendYPosition: 50 + this.props.legendsYPosition,

          showYAxisLine: false,
          yAxisNamePadding: 0,
          yAxisLineThickness: 1,
          showYAxisValues:
            this.props.template_name === "1111"
              ? false
              : !this.props.dataHidden,
          yAxisValueFontSize: 14,
          yAxisValueDecimals: 1,
          yAxisValueFontColor: "#7f7f7f",
          yAxisLineColor: "#4A90E2",
          yAxisMaxValue: this.state.maxValue,

          showXAxisLine: false,
          xAxisLineThickness: 1,
          xAxisLineColor: "#4A90E2",
          xAxisNameFont: "Avenir Medium",
          xAxisNameFontColor: "#444444",
          xAxisValueFontSize: width <= 566 ? 10 : 14,
          centerXaxisName: false,

          chartTopMargin: this.customizeCaptionMargin(
            50 + this.props.chartHeadingGap
          ),
          chartLeftMargin: 4,

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${520 + this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin + 4}`,
                },
              ],
            },
            {
              id: "legends",
              items: [],
            },
          ],
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };

    if (legendsPositionDynamic) {
      chartConfigs["dataSource"]["chart"] = {
        ...chartConfigs["dataSource"]["chart"],
        legendPosition: "absolute",
        legendNumColumns: 1,
        chartRightMargin: this.state.max_legend_length > 35 ? 220 : 200,
      };
    } else {
      chartConfigs["dataSource"]["chart"] = {
        ...chartConfigs["dataSource"]["chart"],
        legendPosition: "absolute",
        legendNumColumns: 1,
        chartRightMargin: 310 - max_legend_length,
        legendXPosition: 980 + max_legend_length,
      };
    }

    if (
      this.props.template_name === "1000" ||
      this.props.template_name === "1111"
    ) {
      chartConfigs.dataSource.annotations.groups[1].items.push(...legendsData);
    }

    if (this.props.dataHidden) {
      chartConfigs["dataSource"]["annotations"]["groups"].push({
        id: "infobar",
        items: [
          {
            id: "dyn-label",
            type: "text",
            align: "center",
            fillcolor: "#002F75",
            fontsize: "12",
            font: "Avenir Medium",
            text: intToString(firstColumnSumValue(data)),
            bold: "1",
            wrap: "1",
            x: `$dataset.${data.length - 1}.set.0.startx+25`,
            y: `$dataset.${data.length - 1}.set.0.starty-10`,
          },
        ],
      });
    }

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}

function mapStateToPros(state) {
  return {
    showGraphVal: state.showGraphVal,
  };
}

export default withRouter(connect(mapStateToPros, null)(Chart));
