import styled from "styled-components";

export const CropProtectionChemicalsWrapper = styled.div`
  .section_data1 {
    width: 16%;
    max-width: 230px;
    position: absolute;
    word-break: break-word;

    .font_design {
        font-size: 12px;
    }
  }

  .section_data2 {
    width: 15%;
    max-width: 168px;
    position: absolute;
    word-break: break-word;

    .font_design {
        font-size: 12px;
    }
  }

  .section1_list {
    top: 58%;
    left: 5%;
  }

  .section2_list {
    top: 58%;
    left: 39%;
  }

  .section3_list1 {
    top: 58%;
    left: 69%;
  }

  .section3_list2 {
    top: 58%;
    left: 84%;
  }

  .section3_list3 {
    top: 72%;
    left: 69%;
  }
`;
