import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { ProteinsVCWrapper } from "./styles";

var Base64 = require("js-base64").Base64;

class ProteinsVC extends React.Component {
  state = {
    data: this.props.data ? this.props.data : "",
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  renderValueChainList = (element) => {
    let globArr = [];
    const newStr = element.split(";");
    newStr.forEach((e) => {
      return globArr.push(e);
    });

    return (
      globArr.length > 0 &&
      globArr.map((elem) => {
        return (
          <div className="font_design_proteins_vc">
            <div>•</div>
            <div style={{ marginLeft: "5px" }}>{elem}</div>
          </div>
        );
      })
    );
  };

  render() {
    const { section_id } = this.state.data;
    const { slideNo, data } = this.props;
    const slug = this.state.data.slug;
    const pageId = Base64.encode(section_id);
    const { multi_urls, url } = this.state.data;

    return (
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}
        <ProteinsVCWrapper>
          <h2 className="heading valuchain_title">
            {this.props.data ? this.props.data.title : this.state.data.title}
          </h2>

          <div style={{ height: "560px", position: "relative" }}>
            {
              Array.isArray(multi_urls) && multi_urls?.length ? (
                multi_urls.map((item) => (
                  <img
                    src={item.url}
                    alt="Value Chain"
                    style={{ width: "100%", height: "100%", marginTop: "10px" }}
                  />
                ))
              ) : (
                <img
                  src={url}
                  alt="Value Chain"
                  style={{ width: "100%", height: "100%", marginTop: "10px" }}
                />
              )
            }

            {this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
              <div className="section1_list">
                <div className="proteins_title">TOP RAW MATERIAL SUPPLIERS</div>
                {data.data.length
                  ? data.data[0]["list"].map((elem) => {
                    return this.renderValueChainList(elem);
                  })
                  : ""}
              </div>
            ) : (
              ""
            )}

            {this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
              <div className="section2_list">
                <div className="proteins_title">Top protein companies</div>
                {data.data.length
                  ? data.data[1]["list"].map((elem) => {
                    return this.renderValueChainList(elem);
                  })
                  : ""}
              </div>
            ) : (
              ""
            )}

            {this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
              <div className="section3_list">
                <div className="proteins_title">Top protein SUPPLIERS</div>
                {data.data.length
                  ? data.data[2]["list"].map((elem) => {
                    return this.renderValueChainList(elem);
                  })
                  : ""}
              </div>
            ) : (
              ""
            )}

            {this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
              <div className="section4_list">
                <div className="proteins_title">Top End User Companies​</div>
                {data.data.length
                  ? data.data[3]["list"].map((elem) => {
                    return this.renderValueChainList(elem);
                  })
                  : ""}
              </div>
            ) : (
              ""
            )}
          </div>

          {!this.props.fromInterface ||
            this.props.fromInterface === undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              reseller={this.state.reseller}
            />
          ) : null}
        </ProteinsVCWrapper>
      </div>
    );
  }
}

export default ProteinsVC;
