import React from "react";
import { ExecutiveWrapper } from "../../styles/Executive.style";
export default function ExecSummaryBox(props) {

  const renderIcon = props?.icon?.hasOwnProperty("$binary")
    ? props.icon["$binary"]
    : props.icon;
    const re=new RegExp(/^http.*/);
  return (
    <ExecutiveWrapper
      url={props.icon}
      style={{ backgroundColor: props.bgColor }}
      className="excuSummeryBox"
    >
      <div className="exeSumryHead">
                {props.block}
      </div>

      <div className="flex">
        <div className="flex-item" style={{ width: "58%" }}>
          {
            (props.value.length >= 6) 
                ? (<div className="flex" style={{ fontSize: "24px", lineHeight:"1.1",fontWeight:"500", height:"80px", color: "#1F4986"}}>
                      {props.value}
                   </div> )
                :(<div className="flex" style={{ fontSize: "24px", height:"80px",fontWeight:"500", color: "#1F4986",}}>
                     {props.value}
                </div>)
          }
          <div
            className="execSumSubHead"
            style={{ textTransform: "capitalize" }}
          >
            {props.segment}
          </div>
        </div>
        <div className="svg-image" style={{ fill: props.logocolor }} >
        {(props.icon ||  props.logoname) ?
           ((props.logoname&& !re.test((props?.icon+""))) ? (
            <div  dangerouslySetInnerHTML={{ __html: atob(renderIcon) }} />
           )
           : (<img
              src={props.icon}
              alt="img"
              className="flex-item img"
              style={{ width: "150px", height: "110px" }}
            /> )) 
          : (<div></div>)}
        </div>
      </div>

      <hr
        className="hrtag"
        style={{
          border: `1px solid ${props.hrbgcolor}`,
          margin: "5px 0",
        }}
      />

      <div
        style={{
          marginTop: "10px",
          color: "#27518C",
          fontSize: "14px",
          lineHeight: "18px",
          fontFamily: "Avenir Roman",
          textAlign: "justify",
        }}
      >
        {props.analysis}
      </div>
    </ExecutiveWrapper>
  );
}
