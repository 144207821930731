import React, { Component } from "react";
import SearchComponent from "../Search";
import MarketActivities from "../MarketActivities";
import MasterMEsExport from "../MasterMEs";
import DownloadReports from "../DownloadReports";
import Interface from "../Interface";
import InterFaceHeader from "../InterfaceHeader";
import "../../App.css";
import {
  setTocData,
  setSearchParams,
  setActiveTab,
  setSwitchState,
  setPublishedDrawer,
  setDraftDrawer,
  setDraftStage,
  setNoDraftsFound,
  setSelectStageChangeLoader,
  setDraftId,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setAnalysisHeadingEditorState,
  setHLPParams,
} from "../../reducersAndActions/Actions";
import {
  Tabs,
  Button,
  Switch,
  Menu,
  Dropdown,
  Select,
  Modal,
  Input,
  Form,
  Drawer,
  notification,
  Row,
  Col,
} from "antd";
import { Spin, message } from "antd";
import {
  LoadingOutlined,
  DownOutlined,
  UserOutlined,
  SmileOutlined,
  CommentOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  AreaChartOutlined,
  ExportOutlined,
  CloudUploadOutlined,
  UploadOutlined,
  FileImageOutlined,
  FileOutlined,
  SettingOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { LayoutWrapper } from "../../styles/Layout.style";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../../Common/withWindowSizeHOC";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config/Api";

import isEqual from "react-fast-compare";
import HubDetails from "../HLPDataUpload";
import ChatButton from "../../Common/ChatButton/index";
import Text from "antd/lib/typography/Text";
import InterfaceHeader2 from "../InterfaceHeader2";
import InterfaceHeader from "../InterfaceHeader";

const { Option } = Select;

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} />;
class NewLayout extends Component {
  state = {
    blob: [],
    downloadloader: false,
    UpdateRddownloadloader: false,
    pdfurl: "",
    new_slug: "",
    isSlideEditable: false,
    // checked: false,
    publish_modal_warning: false,
    draft_publish_loader: false,
    duplicate_rd_modal_warning: false,
    duplicate_rd_modal_message: "",
    reset_urls_modal_warning: false,
    currentHover: "",
  };
  hubLeaderMenu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  slideEditFlag = (value = false) => {
    this.setState({ isSlideEditable: value });
  };

  componentDidMount() {
    if (!this.props.TOC_DATA) {
      this.props.setSearchParams(false);
    }
  }

  setGetTocParams = (item) => {
    this.props.history.push(`/editing/report/${item.slug}`);
    this.props.setTocData(item);
    this.props.setSearchParams(true);
    // this.props.setActiveTab("report");
    this.props.setActiveTab("drafts");
  };

  tabChange = (value) => {
    this.props.setActiveTab(value);
    if (value === "report") {
      this.props.history.push(`/editing/report/${this.props.TOC_DATA.slug}`);
    } else {
      this.props.history.push(`/editing/${value}`);
    }
  };
  tabClick = (key, event) => {};

  tabData = () => {
    if (this.props.ACTIVE_TAB === "report") {
      return !this.props.SEARCH_PARAMS ? null : (
        <Interface
          TocApiParams={this.props.TOC_DATA}
          reportName={this.props.TOC_DATA.title}
        />
      );
    } else if (
      this.props.ACTIVE_TAB === "published" ||
      this.props.ACTIVE_TAB === "drafts"
    ) {
      return !this.props.SEARCH_PARAMS ? null : (
        <Interface
          TocApiParams={this.props.TOC_DATA}
          reportName={this.props.TOC_DATA.title}
          edit_mode={this.props.switch_state}
          draft_drawer_visible={this.props.DRAFT_DRAWER_VISIBLE}
          published_drawer_visible={this.props.PUBLISHED_DRAWER_VISIBLE}
          handleSwitchchange={this.handleSwitchchange}
          slideEditFlag={this.slideEditFlag}
        />
      );
    } else if (this.props.ACTIVE_TAB === "datacharts") {
      return <div>Data and Charts</div>;
    } else if (this.props.ACTIVE_TAB === "customize") {
      return <div>Customize</div>;
    } else if (this.props.ACTIVE_TAB === "faqs") {
      return <div>FAQs</div>;
    } else {
      return <div>Purchases</div>;
    }
  };

  deleteOldRD = async () => {
    const payload = {
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
      deleteOlderRd: this.state.duplicate_rd_modal_message ? true : false,
      meta: { sample: false, rd: true },
    };
    this.setState({
      downloadloader: true,
    });
    try {
      let url = config.api.base_url;
      let response = await axios.post(`${url}/api/createRd`, payload);
      if (response.status === 200) {
        message.success(`rd generated successfully ${response.data.slugs}`);
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = `${config.api.website_url}industry-reports/${response.data.slugs}`;
        a.click();
        message.success(`Old RD Deleted successfully ${response.data.slugs}`);
        this.setState({
          downloadloader: false,
          duplicate_rd_modal_warning: false,
        });
      } else if (response.data.message) {
        message.error(response.message);
        this.setState({
          downloadloader: false,
        });
      } else if (!response) {
        message.error("Failed");
        this.setState({
          downloadloader: false,
        });
      }
    } catch (error) {
      message.error("Failed");
      this.setState({
        downloadloader: false,
      });
    }
  };
  updateRDSlug = async () => {
    const payload = {
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
      updateRdSlug: (this.state.new_slug + "")
        .trim()
        .toLowerCase()
        .replace(/ /g, "-"),
      meta: { sample: false, rd: true },
    };
    this.setState({
      UpdateRddownloadloader: true,
      new_slug: "",
    });
    try {
      let url = config.api.base_url;
      let response = await axios.post(`${url}/api/createRd`, payload);
      if (response.status === 200) {
        message.success(`rd generated successfully ${payload.updateRdSlug}`);
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = `${config.api.website_url}industry-reports/${payload.updateRdSlug}`;
        a.click();
        message.success(
          `Rd generated successfully with new slug ${payload.updateRdSlug}`
        );
        this.setState({
          UpdateRddownloadloader: false,
          duplicate_rd_modal_warning: false,
        });
      } else if (response.data.message) {
        message.error(response.message);
        this.setState({
          UpdateRddownloadloader: false,
        });
      } else if (!response) {
        message.error("Failed");
        this.setState({
          UpdateRddownloadloader: false,
        });
      }
    } catch (error) {
      message.error("Failed");
      this.setState({
        UpdateRddownloadloader: false,
      });
    }
  };
  deleteOldUrls = async () => {
    const payload = {
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
    };
    this.setState({
      downloadloader: true,
    });
    try {
      let url = config.api.base_url;
      let response = await axios.delete(
        `${url}/api/additional_routes/deleteHubUrls`,
        { data: payload }
      );
      if (response.status === 200) {
        message.success(
          `${this.props.TOC_DATA.title} report and sample url delete successfully..!`
        );

        this.setState({
          downloadloader: false,
          reset_urls_modal_warning: false,
        });
      } else {
        message.error(`Something went wrong, please try again later...!`);
        this.setState({
          downloadloader: false,
        });
      }
    } catch (e) {
      message.error("Failed");
      this.setState({
        downloadloader: false,
        reset_urls_modal_warning: false,
      });
    }
  };

  generatePdf = async (report) => {
    try {
      let payload;
      if (
        report === "Report" ||
        (report + "").toLowerCase() === "pptgen" ||
        (report + "").toLowerCase() === "pptgensample"
      ) {
        const isSample =
          (report + "").toLowerCase() === "pptgensample" ? true : false;
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          meta: { sample: isSample },
        };
        this.setState({
          downloadloader: true,
        });
      } else if ((report + "").toLowerCase() === "sample") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          // sample: true,
          meta: { sample: true, reseller: false },
        };
        this.setState({
          downloadloader: true,
        });
      } else if ((report + "").toLowerCase() === "rd") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          meta: { sample: false, rd: true },
        };
        this.setState({
          downloadloader: true,
        });
      } else if ((report + "").toLowerCase() === "reset_urls") {
        payload = {
          hub_id: this.props.TOC_DATA.hub_id,
          dimensions: this.props.TOC_DATA.dimensions,
          meta: { sample: false, rd: true },
        };
        this.setState({
          downloadloader: true,
        });
      }

      if (
        (report + "").toLowerCase() === "report" ||
        (report + "").toLowerCase() === "sample"
      ) {
        let url = config.api.puppeteer_ms_url;
        let response = await axios.post(`${url}/api/createReport`, payload);

        if (response.status === 200) {
          message.success("Success");
          this.downloadReport(response.data.pdfUrl);
        } else if (response.data.message) {
          message.error(response.message);
          this.setState({
            downloadloader: false,
          });
        } else if (!response) {
          message.error("Failed");
          this.setState({
            downloadloader: false,
          });
        }
      } else if (
        (report + "").toLowerCase() === "pptgen" ||
        (report + "").toLowerCase() === "pptgensample"
      ) {
        let url = config.api.pptgen_url;
        let response = await axios.post(`${url}createPPT`, payload);

        if (response.status === 200) {
          message.success("Success");
          this.downloadReport(response.data.url);
        } else if (response.data.message) {
          message.error(response.message);
          this.setState({
            downloadloader: false,
          });
        } else if (!response) {
          message.error("Failed");
          this.setState({
            downloadloader: false,
          });
        }
      } else if ((report + "").toLowerCase() === "rd") {
        let url = config.api.base_url;
        let response = await axios.post(`${url}/api/createRd`, payload);
        if (response.status === 200) {
          message.success(`rd generated successfully ${response.data.slugs}`);
          // let a = document.createElement("a");
          // a.target = "_blank";
          // a.href = `${config.api.website_url}industry-reports/${response.data.slugs}`;
          // a.click();
          this.setState({
            downloadloader: false,
          });
        } else if (response.data.message) {
          message.error(response.message);
          this.setState({
            downloadloader: false,
          });
        } else if (!response) {
          message.error("Failed");
          this.setState({
            downloadloader: false,
          });
        }
      } else if ((report + "").toLowerCase() === "reset_urls") {
        this.setState({
          downloadloader: false,
          reset_urls_modal_warning: true,
        });
      }
    } catch (error) {
      if (error.response.status === 409) {
        this.setState({
          duplicate_rd_modal_warning: true,
          duplicate_rd_modal_message: error.response?.data?.message,
        });
      }
      message.error("Failed");
      this.setState({
        downloadloader: false,
      });
    }
  };

  handleDuplicateModalCancel = () => {
    this.setState({
      duplicate_rd_modal_warning: false,
    });
  };
  handleResetUrlsModalCancel = () => {
    this.setState({
      reset_urls_modal_warning: false,
    });
  };

  downloadReport = (pdfurl) => {
    var link = document.createElement("a");
    link.download = "sample.pdf";
    link.href = `${pdfurl}`;
    link.target = "_blank";
    link.dispatchEvent(new MouseEvent("click"));
    this.setState({
      downloadloader: false,
    });
  };

  handleSwitchchange = (checked) => {
    this.props.setNoDraftsFound(false);
    // this.setState({
    //   checked: checked,
    // });
    this.props.setSwitchState(checked);
    this.props.setDraftId(null);
    this.props.setAnalysisHeadingEditorState("");
  };

  handleDraftTabStageChange = (stage) => {
    this.continueStagechange(stage);
  };

  showPublishedDrawer = () => {
    this.props.setPublishedDrawer(true);
  };

  showDraftDrawer = () => {
    this.props.setDraftDrawer(true);
  };

  handlePublishModalWarningCancel = () => {
    this.setState({
      publish_modal_warning: false,
    });
  };

  continueDraftPublish = () => {
    this.setState({
      draft_publish_loader: true,
    });
    //User agreed, proceed to publish
    this.props.setDraftStage("PUBLISHED");
    const data = {
      type: this.props.template_type,
      stage: "PUBLISHED",
      parent_id: this.props.parent_id,
      cacheId: this.props.cache_id,
      draftId: this.props.draft_id,
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
    };

    /* CHECK IF USER HAS ACCESS */
    if (
      this.props.USER_DATA.obj.access.move.includes(
        this.props.draft_versions.stage || "INITIAL"
      )
    ) {
      let deepCloneData = JSON.parse(JSON.stringify(data));
      if (deepCloneData.type === "regulatoryFramework2") {
        deepCloneData.type = "regulatoryFramework";
      }
      axios({
        method: "PATCH",
        url: `${config.api.base_url}/api/editing/drafts`,
        data: deepCloneData,
      })
        .then((response) => {
          this.setState({
            // checked: false,
            draft_publish_loader: false,
            publish_modal_warning: false,
          });
          this.props.setSwitchState(false);

          if (response.status === 200 || response.status === 201) {
            this.props.setActiveTab("published");
            notification.success({
              message: "Info",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          this.props.setDraftStage(
            this.props.draft_stage_from_get_specific_data
          ); //revert to previous stage if api call fails
          this.setState({
            draft_publish_loader: false,
            publish_modal_warning: false,
          });
          if (error.response) {
            notification.error({
              message: "Error",
              description: error.response.data.message,
            });
          }
        });
    } else {
      this.props.setDraftStage(this.props.draft_stage_from_get_specific_data); //revert to previous stage
      this.setState({
        publish_modal_warning: false,
        draft_publish_loader: false,
      });
      notification.warning({
        message: "Info",
        description: "You dont have access to move stage",
      });
    }
  };

  continueStagechange = (stage) => {
    if (stage === "PUBLISHED") {
      // If the stage about to move is PUBLISHED, show warning modal
      this.setState({
        publish_modal_warning: true,
      });
    } else {
      //Let the stage change happen. Don't show any modal.
      this.props.setDraftStage(stage);
      this.props.setSelectStageChangeLoader(true);
      const data = {
        type: this.props.template_type,
        stage: stage,
        parent_id: this.props.parent_id,
        draftId: this.props.draft_id,
        cacheId: this.props.cache_id,
      };

      /* CHECK IF USER HAS ACCESS */
      if (
        this.props.USER_DATA.obj.access.move.includes(
          this.props.draft_versions.stage || "INITIAL"
        )
      ) {
        let deepCloneData = JSON.parse(JSON.stringify(data));
        if (deepCloneData.type === "regulatoryFramework2") {
          deepCloneData.type = "regulatoryFramework";
        }
        axios({
          method: "PATCH",
          url: `${config.api.base_url}/api/editing/drafts`,
          data: deepCloneData,
        })
          .then((response) => {
            this.props.setSelectStageChangeLoader(false);
            if (response.status === 200 || response.status === 201) {
              // throw new Error('something went wrong');
              notification.success({
                message: "Info",
                description: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.props.setSelectStageChangeLoader(false);
            this.props.setDraftStage(
              this.props.draft_stage_from_get_specific_data
            ); //revert to previous stage if api call fails
            if (error.response) {
              notification.error({
                message: "Error",
                description: error.response.data.message,
              });
            }
          });
      } else {
        this.props.setDraftStage(this.props.draft_stage_from_get_specific_data); //revert to previous stage
        notification.warning({
          message: "Info",
          description: "You dont have access to move stage",
        });
      }
    }
  };
  onclickOfSearch = (event) => {
    this.setState(
      {
        new_slug: event.target.value,
      },
      () => {}
    );
  };
  // Reports, Marketing Activities, HUbs landing, comments dashboard, bulk export, Sample ME upload
  render() {
    const { width, parent_id, template_type } = this.props;
    const squares = [
      {
        border: "4px solid green",
        margin: "10px",
        text: "Reports",
        backgroundColor: "green",
        path: "/reports/search",
        icon: <AreaChartOutlined />,
        permissionTo: ["ALL", "TECH", "MARKETING", "RESEARCH", "EDITING"],
      },
      {
        border: "4px solid #FEC015",
        margin: "10px",
        text: "Marketing Activities",
        backgroundColor: "#FEC015",
        path: "/market-activities",
        icon: <GlobalOutlined />,
        permissionTo: ["ALL", "TECH", "MARKETING", "RESEARCH"],
      },
      {
        border: "4px solid blue",
        margin: "10px",
        text: "Hubs Landing Page",
        backgroundColor: "blue",
        path: "/hubs-landing-page",
        icon: <DatabaseOutlined />,
        permissionTo: ["ALL", "TECH", "MARKETING", "RESEARCH"],
      },
      {
        border: "4px solid red",
        margin: "10px",
        text: "Comments Dashboard",
        backgroundColor: "red",
        path: "/comments-dashboard",
        icon: <CommentOutlined />,
        permissionTo: ["ALL", "TECH", "RESEARCH", "EDITING"],
      },
      {
        border: "4px solid purple",
        margin: "10px",
        text: "Bulk Exports",
        backgroundColor: "purple",
        path: "/bulk-exports",
        icon: <ExportOutlined />,
        permissionTo: ["ALL", "TECH", "RESEARCH"],
      },
      // {
      //   border: "4px solid orange",
      //   margin: "10px",
      //   text: "Sample Reports ME Upload",
      //   backgroundColor: "orange",
      //   path: "/sample-mes-upload",
      //   icon: <CloudUploadOutlined />,
      //   permissionTo: ["ALL", "TECH", "RESEARCH"],
      // },
      {
        border: "4px solid #4831D4",
        margin: "10px",
        text: "Upload Sheet",
        backgroundColor: "#4831D4",
        path: "/upload-sheet",
        icon: <UploadOutlined />,
        permissionTo: ["ALL", "TECH", "RESEARCH"],
      },
      {
        border: "4px solid #4891E9",
        margin: "10px",
        text: "Upload Logos",
        backgroundColor: "#4891E9",
        path: "/upload-image",
        icon: <FileImageOutlined />,
        permissionTo: ["ALL", "TECH", "RESEARCH"],
      },
      {
        border: "4px solid #A891E9",
        margin: "10px",
        text: "Purge Reports",
        backgroundColor: "#A891E9",
        path: "/purge-reports",
        icon: <ClearOutlined />,
        permissionTo: ["ALL", "TECH", "RESEARCH"],
      },
      {
        border: "4px solid #ff743f",
        margin: "10px",
        text: "Documentation",
        backgroundColor: "#ff743f",
        path: "/documentation",
        icon: <FileOutlined />,
        permissionTo: ["ALL", "TECH", "RESEARCH"],
      },
      {
        border: "4px solid #04768c",
        margin: "10px",
        text: "Hub's Config",
        backgroundColor: "#04768c",
        path: "/hub-config",
        icon: <SettingOutlined />,
        permissionTo: ["ALL", "TECH", "RESEARCH"],
      },
      {
        border: "4px solid #00abfe",
        margin: "10px",
        text: "Upload Static Files",
        backgroundColor: "#00abfe",
        path: "/upload-static-image",
        icon: <FileOutlined />,
        permissionTo: ["ALL", "TECH", "RESEARCH"],
      },
      {
        border: "4px solid rgb(1 176 179)",
        margin: "10px",
        text: "Lead Automation Files",
        backgroundColor: "rgb(1 176 179)",
        path: "/lead-automation",
        icon: <FileOutlined />,
        permissionTo: ["ALL", "TECH", "RESEARCH"],
      },
      {
        border: "4px solid #510505",
        margin: "10px",
        text: "Bulk Delete Data",
        backgroundColor: "#510505",
        path: "/delete-data",
        icon: <FileOutlined />,
        permissionTo: ["ALL", "TECH"], // Add "RESEARCH" to the array to give access to Research Team
      },
    ];

    // const hub_id = this.props.TOC_DATA.hub_id;
    // const hub_title = this.props.TOC_DATA.title;
    // const report_id = this.props.TOC_DATA.report_id;
    const { hub_id, title, report_id } = this.props.TOC_DATA;

    const user = this.props?.USER_DATA;
    let newTemplateType =
      template_type === "regulatoryFramework2"
        ? "regulatoryFramework"
        : template_type;
    return (
      <LayoutWrapper
        mobile={width > 960 ? false : true}
        // checked={this.state.checked}
        checked={this.props.switch_state}
        template_type={this.props.template_type}
      >
        <div className="layoutCOntainer">
          <InterfaceHeader
            title={this.props.TOC_DATA.title}
            generatePdf={this.generatePdf}
            indicator={antIcon}
            // disableTitle={true}
            spinning={this.state.downloadloader}
          />
          <div style={{ width: "60%", margin: "95px auto" }}>
            <div className="searchContainer">
              <div className="new-Container">
                <h3
                  className="gradient-text"
                  style={{ marginLeft: "9px" }}
                  // style={{ color: "#2c8dbf", marginLeft: "13px" }}
                >
                  Welcome to CMS
                </h3>
                <Row style={{ width: "100%" }} gutter={16}>
                  {squares.map((square, index) => {
                     if (
                       square.path === "/delete-data" &&
                       (square.permissionTo.includes(user?.obj?.department) ||
                         user.obj.bulk_data_delete)
                     ) {
                       return (
                         <Col key={index} xs={24} sm={12} md={8} lg={8} xl={8}>
                           <div
                             className="square"
                             style={{
                               border: square.border,
                               margin: square.margin,
                               cursor: "pointer",
                               display: "flex",
                               flexDirection: "row",
                               // alignItems: "center",
                               justifyContent: "center",
                               width: "100%", // Make the square container full width
                               height: "112px", // Set a fixed height for the outer square container
                               padding: "6%",
                               backgroundColor:
                                 this.state.currentHover === index
                                   ? square?.backgroundColor
                                   : "transparent",
                             }}
                             onMouseEnter={() =>
                               this.setState({
                                 currentHover: index,
                               })
                             }
                             onMouseLeave={() =>
                               this.setState({
                                 currentHover: "",
                               })
                             }
                             onClick={() => {
                               const { history } = this.props;
                               history.push(square.path);
                             }}
                           >
                             <div
                               style={{
                                 display: "flex",
                                 flexDirection: "row",
                                 alignItems: "center",
                                 width: "100%",
                                 // justifyContent: "space-between",
                               }}
                             >
                               {/* <CommentOutlined
                                  style={{
                                    color:
                                      this.state.currentHover === index
                                        ? "white"
                                        : "black",
                                    fontSize: "30px",
                                  }}
                                  spin={this.state.currentHover === index}
                                /> */}
                               {square.icon && (
                                 <div
                                   style={{
                                     color:
                                       this.state.currentHover === index
                                         ? "white"
                                         : square?.backgroundColor,
                                     fontSize: "clamp(25px, 1.6vw, 50px)",
                                     display: "inline-block",
                                     marginRight: "30px", // Ensure inline display
                                   }}
                                 >
                                   {React.cloneElement(square.icon, {
                                     width: "64px",
                                   })}
                                 </div>
                               )}
                               <div
                                 style={{
                                   color:
                                     this.state.currentHover === index
                                       ? "white"
                                       : square?.backgroundColor,
                                   fontSize: "clamp(15px, 1.5vw, 30px)",
                                   fontFamily: "Avenir Medium",
                                 }}
                               >
                                 {square.text}
                               </div>
                             </div>
                           </div>
                         </Col>
                       );
                     } else if (
                       square.permissionTo.includes(user?.obj?.department)
                     ) {
                       return (
                         <Col key={index} xs={24} sm={12} md={8} lg={8} xl={8}>
                           <div
                             className="square"
                             style={{
                               border: square.border,
                               margin: square.margin,
                               cursor: "pointer",
                               display: "flex",
                               flexDirection: "row",
                               // alignItems: "center",
                               justifyContent: "center",
                               width: "100%", // Make the square container full width
                               height: "112px", // Set a fixed height for the outer square container
                               padding: "6%",
                               backgroundColor:
                                 this.state.currentHover === index
                                   ? square?.backgroundColor
                                   : "transparent",
                             }}
                             onMouseEnter={() =>
                               this.setState({
                                 currentHover: index,
                               })
                             }
                             onMouseLeave={() =>
                               this.setState({
                                 currentHover: "",
                               })
                             }
                             onClick={() => {
                               const { history } = this.props;
                               history.push(square.path);
                             }}
                           >
                             <div
                               style={{
                                 display: "flex",
                                 flexDirection: "row",
                                 alignItems: "center",
                                 width: "100%",
                                 // justifyContent: "space-between",
                               }}
                             >
                               {/* <CommentOutlined
                                  style={{
                                    color:
                                      this.state.currentHover === index
                                        ? "white"
                                        : "black",
                                    fontSize: "30px",
                                  }}
                                  spin={this.state.currentHover === index}
                                /> */}
                               {square.icon && (
                                 <div
                                   style={{
                                     color:
                                       this.state.currentHover === index
                                         ? "white"
                                         : square?.backgroundColor,
                                     fontSize: "clamp(25px, 1.6vw, 50px)",
                                     display: "inline-block",
                                     marginRight: "30px", // Ensure inline display
                                   }}
                                 >
                                   {React.cloneElement(square.icon, {
                                     width: "64px",
                                   })}
                                 </div>
                               )}
                               <div
                                 style={{
                                   color:
                                     this.state.currentHover === index
                                       ? "white"
                                       : square?.backgroundColor,
                                   fontSize: "clamp(15px, 1.5vw, 30px)",
                                   fontFamily: "Avenir Medium",
                                 }}
                               >
                                 {square.text}
                               </div>
                             </div>
                           </div>
                         </Col>
                       );
                     } else {
                       return;
                     }
                  })}
                </Row>
              </div>
            </div>
          </div>
          ; ; ;
          {/* {!this.props.SEARCH_PARAMS && (
            <SearchComponent setGetTocParams={this.setGetTocParams} />
          )} */}
          {this.props.MARKET_ACTIVITY_PARAMS && <MarketActivities />}
          {this.props.MASTER_ME_PARAMS && <MasterMEsExport />}
          {this.props.DOWNLOAD_REPORTS_PARAMS && <DownloadReports />}
          {this.props.HLP_PARAMS && <HubDetails />}
          {this.props.SEARCH_PARAMS &&
            !this.props.MARKET_ACTIVITY_PARAMS &&
            !this.props.MASTER_ME_PARAMS &&
            !this.props.DOWNLOAD_REPORTS_PARAMS &&
            !this.props.HLP_PARAMS && (
              <>
                <div className="antd-tabContainer">
                  <Tabs
                    activeKey={this.props.ACTIVE_TAB}
                    centered
                    animated={width > 960 ? true : false}
                    tabPosition={width > 960 ? "top" : "bottom"}
                    onChange={(value) => this.tabChange(value)}
                    className="simulateClick"
                  >
                    <TabPane tab="Drafts" key="drafts">
                      <Link to="/editing/report" />
                    </TabPane>
                    <TabPane tab="Published" key="published">
                      <Link to="/editing/report" />
                    </TabPane>
                  </Tabs>

                  <div className="editing-controls-container">
                    {this.state.isSlideEditable && (
                      <Switch
                        // defaultChecked
                        checked={this.props.switch_state}
                        onChange={this.handleSwitchchange}
                        checkedChildren="EDIT"
                        unCheckedChildren="VIEW"
                        disabled={this.props.switch_disabled}
                        className="edit-mode-switch"
                      />
                    )}
                    {[
                      "typedetail",
                      "typeoverview",
                      "indicator",
                      "keyStrategicMoves",
                      "marketShares",
                      "executive_summary",
                      "regulatoryFramework",
                    ].includes(newTemplateType) &&
                      this.props.switch_state && (
                        <ChatButton
                          hub_id={hub_id}
                          hub_title={title}
                          parent_id={parent_id}
                          template_type={template_type}
                          user={user.obj}
                          baseUrl={config.api.base_url}
                          report_id={report_id}
                        />
                      )}

                    {this.props.ACTIVE_TAB === "published" ? (
                      <div className="tab-buttons-container">
                        {/* <Button type="primary" disabled={!this.state.checked}>PUBLISHED</Button> */}
                        <Button
                          type="link"
                          onClick={this.showPublishedDrawer}
                          disabled={!this.props.switch_state}
                        >
                          Published Versions
                        </Button>
                      </div>
                    ) : (
                      <div className="tab-buttons-container">
                        <Select
                          value={this.props.draft_stage}
                          style={{ width: 120 }}
                          onChange={this.handleDraftTabStageChange}
                          disabled={
                            !this.props.switch_state ||
                            this.props.draft_stage === "NONE"
                          }
                          className="draft-tab-dropdown"
                          loading={this.props.select_stage_change_loader}
                        >
                          {this.props.USER_DATA &&
                            this.props.USER_DATA.obj &&
                            this.props.USER_DATA.obj.access &&
                            this.props.USER_DATA.obj.access.move &&
                            this.props.USER_DATA.obj.access.move.length &&
                            this.props.USER_DATA.obj.access.move.map((item) => {
                              return <Option value={item}>{item}</Option>;
                            })}
                        </Select>

                        <Button
                          type="link"
                          onClick={this.showDraftDrawer}
                          disabled={!this.props.switch_state}
                        >
                          Draft Versions
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="body-section">{this.tabData()}</div>
              </>
            )}
        </div>

        <Modal
          title="Notification"
          visible={this.state.publish_modal_warning}
          onCancel={this.handlePublishModalWarningCancel}
          onOk={this.continueDraftPublish}
          okText="Continue"
          cancelText="Cancel"
          footer={[
            <Button key="back" onClick={this.handlePublishModalWarningCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.draft_publish_loader}
              onClick={this.continueDraftPublish}
            >
              Continue
            </Button>,
          ]}
        >
          Once you publish this draft, it replaces the current version with this
          version. Are you sure?
        </Modal>

        <Modal
          title="Sorry! RD already exist"
          visible={this.state.duplicate_rd_modal_warning}
          okText="Continue"
          cancelText="Cancel"
          onCancel={this.handleDuplicateModalCancel}
          centered
          footer={[
            <Button key="back" onClick={this.handleDuplicateModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.deleteOldRD}
              loading={this.state.downloadloader}
            >
              Proceed
            </Button>,
          ]}
        >
          Do u want to modify slug to resolve duplicate slug issue?
          <Input onChange={this.onclickOfSearch} />
          <br />
          <br />
          <Button
            key="submit"
            type="primary"
            onClick={this.updateRDSlug}
            loading={this.state.UpdateRddownloadloader}
          >
            Update Slug
          </Button>
          <hr />
          {this.state.duplicate_rd_modal_message}
        </Modal>

        <Modal
          title="Are you sure removing existing Sample, Report?"
          visible={this.state.reset_urls_modal_warning}
          okText="Continue"
          cancelText="Cancel"
          onCancel={this.handleResetUrlsModalCancel}
          centered
          footer={[
            <Button key="back" onClick={this.handleResetUrlsModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.deleteOldUrls}
              loading={this.state.downloadloader}
            >
              Proceed
            </Button>,
          ]}
        >
          {this.state.duplicate_rd_modal_message}
        </Modal>
      </LayoutWrapper>
    );
  }
}
function mapStateToPros(state) {
  return {
    IS_AUTH: state.IS_AUTH,
    SEARCH_PARAMS: false,
    MARKET_ACTIVITY_PARAMS: state.MARKET_ACTIVITY_PARAMS,
    HLP_PARAMS: state.HLP_PARAMS,
    MASTER_ME_PARAMS: state.MASTER_ME_PARAMS,
    DOWNLOAD_REPORTS_PARAMS: state.DOWNLOAD_REPORTS_PARAMS,
    TOC_DATA: state.TOC_DATA,
    ACTIVE_TAB: state.ACTIVE_TAB,
    switch_state: state.switch_state,
    PLACEMENT: state.PLACEMENT,
    PUBLISHED_DRAWER_VISIBLE: state.PUBLISHED_DRAWER_VISIBLE,
    DRAFT_DRAWER_VISIBLE: state.DRAFT_DRAWER_VISIBLE,
    draft_versions: state.draft_versions,
    published_versions: state.published_versions,
    USER_DATA: state.USER_DATA,
    draft_stage: state.draft_stage,
    draft_stage_from_get_specific_data:
      state.draft_stage_from_get_specific_data,
    select_stage_change_loader: state.select_stage_change_loader,
    published_access_warning: state.published_access_warning,
    switch_disabled: state.switch_disabled,
    parent_id: state.parent_id,
    cache_id: state.cache_id,
    template_type: state.template_type,
    draft_id: state.draft_id,
  };
}
const mapDispatchToProps = {
  setTocData,
  setSwitchState,
  setSearchParams,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setHLPParams,
  setActiveTab,
  setPublishedDrawer,
  setDraftDrawer,
  setDraftStage,
  setNoDraftsFound,
  setSelectStageChangeLoader,
  setDraftId,
  setAnalysisHeadingEditorState,
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(NewLayout))
);
