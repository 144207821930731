class Switchtextlowercase {

  static get isInline() {
    return true;
  }

  constructor() {
    this.button = null;
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.style.display='flex';
    this.button.style.alignItems='center';
    this.button.style.justifyContent='center';
    this.button.style.width='30px';
    this.button.style.height='30px';
    this.button.innerHTML=`<svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
    width="800px" height="800px" viewBox="0 0 28.08 28.08" xml:space="preserve"
   >
 <g>
   <g>
     <path d="M5.356,5.135L0,22.659h4.108l1.248-4.498h5.018l1.352,4.498h4.264L10.556,5.135H5.356z M5.928,15.196l1.04-3.718
       c0.286-1.014,0.546-2.34,0.806-3.38h0.052c0.26,1.04,0.572,2.34,0.884,3.38l1.092,3.718H5.928z"/>
     <path d="M27.898,19.564v-4.42c0-2.99-1.353-5.486-5.59-5.486c-2.314,0-4.058,0.624-4.94,1.118l0.728,2.548
       c0.832-0.52,2.211-0.962,3.51-0.962c1.951,0,2.314,0.962,2.314,1.639v0.183c-4.498-0.026-7.462,1.561-7.462,4.861
       c0,2.028,1.534,3.9,4.108,3.9c1.508,0,2.808-0.547,3.64-1.562h0.078l0.233,1.274h3.562C27.95,21.957,27.898,20.787,27.898,19.564z
        M24.05,18.031c0,0.232-0.026,0.468-0.077,0.676c-0.261,0.807-1.066,1.456-2.055,1.456c-0.884,0-1.561-0.494-1.561-1.508
       c0-1.534,1.613-2.028,3.692-2.003V18.031z"/>
     <polygon points="21.663,8.742 26.08,6.938 21.663,5.135 21.663,6.376 15.637,6.376 15.637,6.625 15.637,7.253 15.637,7.501 
       21.663,7.501 		"/>
   </g>
 </g>
 </svg>`
    return this.button;
  }
 
  surround(range) {
    const selectedText = range.extractContents();
    const text = selectedText.textContent;

    let transformedText = text.toLowerCase();

    const span = document.createElement('span');
    span.textContent = transformedText;
    span.setAttribute('data-case', this.state);
    range.insertNode(span);
  }

  checkState(selection) {
    const text = selection.anchorNode;

    if (!text) {
      return;
    }
    this.button.classList.toggle('active', this.state !== 'none');
  }
}



export default Switchtextlowercase;
