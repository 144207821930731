import Config from "../config/Config";

export const CODE_COUNTRY_CONFIG = Config.CODE_COUNTRY_CONFIG;
export const CONTINENT_CONFIG = Config.CONTINENT_CONFIG;
export const SUBCONTINENT_CONFIG = Config.SUBCONTINENT_CONFIG;
export const COUNTRY_CONFIG = Config.COUNTRY_CONFIG;
export const COLOR_CONFIG = Config.COLOR_CONFIG;
export const COLORS = Config.COLORS;

export const CONTINENT_IDCODE_CONFIG = {
  asia: "AS",
  "asia-pacific": "AS",
  europe: "EU",
  africa: "AF",
  "north america": "NA",
  "south america": "SA",
  australia: "AU",
};

export const INDIA_IDCODE_CONFIG = {
  puducherry: "027",
  "andaman and nicobar islands": "001",
  "andaman and nicobar": "001",
  goa: "011",
  lakshadweep: "019",
  "daman and diu": "009",
  "jammu and kashmir": "015",
  punjab: "028",
  chandigarh: "006",
  "himachal pradesh": "014",
  rajasthan: "029",
  haryana: "013",
  delhi: "010",
  uttarakhand: "034",
  "uttar pradesh": "033",
  bihar: "005",
  jharkhand: "016",
  sikkim: "030",
  "west bengal": "035",
  "arunachal pradesh": "003",
  assam: "004",
  meghalaya: "023",
  tripura: "032",
  mizoram: "024",
  manipur: "022",
  nagaland: "025",
  gujarat: "012",
  "madhya pradesh": "020",
  chhattisgarh: "007",
  odisha: "026",
  maharashtra: "021",
  "dadra and nagar haveli": "008",
  telangana: "036",
  "andhra pradesh": "002",
  "tamil nadu": "031",
  kerala: "018",
  karnataka: "017",
};

export const EUROPE_IDCODE_CONFIG = {
  liechtenstein: "021",
  andorra: "002",
  malta: "025",
  "san marino": "034",
  monaco: "027",
  "vatican city": "043",
  montenegro: "028",
  cyprus: "044",
  moldova: "026",
  luxembourg: "023",
  denmark: "010",
  iceland: "017",
  "united kingdom": "042",
  ireland: "018",
  norway: "030",
  sweden: "039",
  netherlands: "029",
  belgium: "005",
  france: "013",
  spain: "038",
  portugal: "032",
  switzerland: "040",
  austria: "003",
  slovenia: "037",
  croatia: "008",
  "bosnia and herzegovina": "006",
  albania: "001",
  greece: "015",
  turkey: "045",
  bulgaria: "007",
  macedonia: "024",
  romania: "033",
  hungary: "016",
  slovakia: "036",
  "czech republic": "009",
  germany: "014",
  poland: "031",
  ukraine: "041",
  belarus: "004",
  lithuania: "022",
  latvia: "020",
  estonia: "011",
  finland: "012",
  russia: "046",
  italy: "019",
  serbia: "035",
  kosovo: "047",
};

export const RUSSIA_IDCODE_CONFIG = {
  belgorod: "RU.BL",
  "moscow city": "RU.MC",
  ingushetia: "RU.IN",
  "kabardino-balkaria": "RU.KB",
  "karachay-cherkessia": "RU.KC",
  chechnya: "RU.CN",
  "north ossetia": "RU.NO",
  adygaya: "RU.AD",
  kaliningrad: "RU.KN",
  "st.petersburg city": "RU.SP",
  chukotka: "RU.CK",
  kamchatka: "RU.KQ",
  magadan: "RU.MG",
  sakha: "RU.SK",
  khabarovsk: "RU.KH",
  krasnodar: "RU.KD",
  rostov: "RU.RO",
  dagestan: "RU.DA",
  murmansk: "RU.MM",
  leningrad: "RU.LN",
  pskov: "RU.PS",
  smolensk: "RU.SM",
  bryansk: "RU.BR",
  krasnoyarsk: "RU.KX",
  yamalia: "RU.YN",
  "khantia-mansia": "RU.KM",
  stavropol: "RU.ST",
  kalmykia: "RU.KL",
  astrakhan: "RU.AS",
  volgograd: "RU.VG",
  saratov: "RU.SR",
  orenburg: "RU.OB",
  kurgan: "RU.KU",
  tyumen: "RU.TY",
  omsk: "RU.OM",
  tomsk: "RU.TO",
  novosibirsk: "RU.NS",
  tuva: "RU.TU",
  irkutsk: "RU.IK",
  buryatia: "RU.BU",
  zabaykalsk: "RU.ZB",
  sakhalin: "RU.SL",
  primorsky: "RU.PR",
  "jewish autonomous region": "RU.JE",
  amur: "RU.AM",
  kemerovo: "RU.KE",
  khakassia: "RU.KK",
  novgorod: "RU.NG",
  tver: "RU.TV",
  kaluga: "RU.KG",
  kursk: "RU.KS",
  oryal: "RU.OL",
  voronezh: "RU.VR",
  tambov: "RU.TB",
  nenetsia: "RU.NN",
  komi: "RU.KO",
  sverdlovsk: "RU.SV",
  perm: "RU.PE",
  chelyabinsk: "RU.CL",
  bashkortostan: "RU.BK",
  arkhangelsk: "RU.AR",
  vologda: "RU.VO",
  moscow: "RU.MS",
  tula: "RU.TL",
  lipetsk: "RU.LP",
  samara: "RU.SA",
  penza: "RU.PZ",
  ryazan: "RU.RZ",
  yaroslavl: "RU.YS",
  kostroma: "RU.KT",
  ivanovo: "RU.IV",
  vladimir: "RU.VL",
  nizhegorod: "RU.NZ",
  mordovia: "RU.MR",
  chuvashia: "RU.CV",
  kirov: "RU.KV",
  ulyanovsk: "RU.UL",
  tatarstan: "RU.TT",
  "mariy-el": "RU.ME",
  udmurtia: "RU.UD",
  karelia: "RU.KI",
  "sevastopol city": "RU.SEV",
  crimea: "RU.KR",
  "altaiskiy kray": "RU.AL",
  "republic of altay": "RU.RA",
};

export const GERMANY_IDCODE_CONFIG = {
  "baden-wuerttemberg": "001",
  bayern: "002",
  berlin: "003",
  brandenburg: "004",
  bremen: "005",
  hamburg: "006",
  hessen: "007",
  "mecklenburg-vorpommern": "008",
  niedersachsen: "009",
  "nordrhein-westfalen": "010",
  "rheinland-pfalz": "011",
  saarland: "012",
  sachsen: "013",
  "sachsen-anhalt": "014",
  "schleswig-holstein": "015",
  thueringen: "016",
};

export const VIETNAM_IDCODE_CONFIG = {
  "an giang": "VN.AG",
  "bac can": "VN.BK",
  "bac giang": "VN.BG",
  "bac lieu": "VN.BL",
  "bac ninh": "VN.BN",
  "baria-vungtau": "VN.BV",
  "ben tre": "VN.BR",
  "binh dinh": "VN.BD",
  "binh duong": "VN.BI",
  "binh phuoc": "VN.BP",
  "binh thuan": "VN.BU",
  "ca mau": "VN.CM",
  "can tho": "VN.CN",
  "cao bang": "VN.CB",
  "da nang": "VN.DA",
  "dac lac": "VN.DC",
  "dac nong": "VN.DO",
  "dien bien": "VN.DB",
  "dong nai": "VN.DN",
  "dong thap": "VN.DT",
  "gia lai": "VN.GL",
  "ha giang": "VN.HG",
  "ha nam": "VN.HM",
  "ha tay": "VN.HA",
  "ha tinh": "VN.HT",
  "hai duong": "VN.HD",
  haiphong: "VN.HP",
  hanoi: "VN.HN",
  "hau giang": "VN.HU",
  "ho chi minh": "VN.HC",
  "hoa binh": "VN.HO",
  "hung yen": "VN.HY",
  "khanh hoa": "VN.KH",
  "kien giang": "VN.KG",
  "kon tum": "VN.KT",
  "lai chau": "VN.LI",
  "lam dong": "VN.LD",
  "lang son": "VN.LS",
  "lao cai": "VN.LO",
  "long an": "VN.LA",
  "nam dinh": "VN.ND",
  "nghe an": "VN.NA",
  "ninh binh": "VN.NB",
  "ninh thuan": "VN.NT",
  "phu tho": "VN.PT",
  "phu yen": "VN.PY",
  "quang binh": "VN.QB",
  "quang nam": "VN.QM",
  "quang ngai": "VN.QG",
  "quang ninh": "VN.QN",
  "quang tri": "VN.QT",
  "soc trang": "VN.ST",
  "son la": "VN.SL",
  "tay ninh": "VN.TN",
  "thai binh": "VN.TB",
  "thai nguyen": "VN.TY",
  "thanh hoa": "VN.TH",
  "thua thien hue": "VN.TT",
  "tien giang": "VN.TG",
  "tra vinh": "VN.TV",
  "tuyen quang": "VN.TQ",
  "vinh long": "VN.VL",
  "vinh phuc": "VN.VC",
  "yen bai": "VN.YB",
};

export const CHINA_IDCODE_CONFIG = {
  hebei: "10",
  hunan: "11",
  hubei: "12",
  xinjiang: "13",
  gansu: "15",
  guangxi: "16",
  guizhou: "18",
  liaoning: "19",
  "nei mongol": "20",
  "ningxia hui": "21",
  shanxi: "24",
  shandong: "25",
  shaanxi: "26",
  yunnan: "29",
  "guangdong": "30",
  hainan: "31",
  sichuan: "32",
  tibet: "36",
  qinghai: "06",
  heilongjiang: "08",
  jilin: "05",
  henan: "09",
  anhui: "01",
  jiangsu: "04",
  "zhejiang": "02",
  fujian: "07",
  jiangxi: "03",
};

export const AFRICA_IDCODE_CONFIG = {
  "cape verde": "008",
  "sao tome and principe": "040",
  "equatorial guinea": "016",
  togo: "049",
  gambia: "056",
  "guinea-bissau": "022",
  liberia: "025",
  "western sahara": "053",
  malawi: "028",
  lesotho: "024",
  swaziland: "047",
  "union of comoros": "011",
  mauritius: "058",
  rwanda: "038",
  burundi: "006",
  djibouti: "014",
  seychelles: "042",
  eritrea: "017",
  "canary islands": "059",
  morocco: "032",
  algeria: "001",
  tunisia: "051",
  libya: "026",
  egypt: "015",
  mauritania: "030",
  senegal: "041",
  guinea: "021",
  "sierra leone": "043",
  mali: "029",
  somalia: "044",
  "cote d'ivoire": "012",
  ghana: "020",
  "burkina faso": "005",
  benin: "003",
  "central african republic": "009",
  ethiopia: "018",
  cameroon: "007",
  gabon: "019",
  angola: "002",
  namibia: "034",
  botswana: "004",
  zimbabwe: "055",
  "south africa": "045",
  congo: "057",
  madagascar: "027",
  nigeria: "036",
  chad: "010",
  niger: "035",
  "democratic republic of the congo": "013",
  tanzania: "048",
  zambia: "054",
  mozambique: "033",
  kenya: "023",
  uganda: "052",
  sudan: "046",
  "south sudan": "060",
};

export const ARGENTINA_IDCODE_CONFIG = {
  jujuy: "10",
  "la pampa": "11",
  "la rioja": "12",
  mendoza: "13",
  misiones: "14",
  neuquén: "15",
  "rio negro": "16",
  salta: "17",
  "san juan": "18",
  "san luis": "19",
  "santa cruz": "20",
  "santa fe": "21",
  "santiago del estero": "22",
  "tierra del feugo": "23",
  tucumán: "24",
  "distrito federal": "07",
  formosa: "09",
  chaco: "03",
  catamarca: "02",
  "entre rios": "08",
  corrientes: "06",
  córdoba: "05",
  chubut: "04",
  "buenos aires": "01",
};

export const ASIA_IDCODE_CONFIG = {
  bhutan: "006",
  "sri lanka": "039",
  bangladesh: "005",
  tajikistan: "041",
  nepal: "031",
  armenia: "002",
  georgia: "013",
  azerbaijan: "003",
  taiwan: "049",
  "east timor": "012",
  vietnam: "047",
  brunei: "007",
  singapore: "038",
  malaysia: "028",
  "hong kong": "050",
  macau: "051",
  philippines: "034",
  "korea (north)": "022",
  japan: "019",
  "korea (south)": "023",
  kuwait: "061",
  bahrain: "060",
  qatar: "059",
  israel: "063",
  lebanon: "064",
  "russian federation": "036",
  kazakhstan: "021",
  uzbekistan: "046",
  kyrgyzstan: "025",
  turkmenistan: "044",
  afghanistan: "001",
  pakistan: "033",
  india: "014",
  "burma (myanmar)": "008",
  thailand: "042",
  laos: "026",
  cambodia: "009",
  indonesia: "015",
  "china": "010",
  mongolia: "030",
  syria: "053",
  iraq: "054",
  "saudi arabia": "055",
  yemen: "056",
  oman: "057",
  iran: "016",
  "united arab emirates": "058",
  jordan: "062",
  turkey: "052",
};

export const BRAZIL_IDCODE_CONFIG = {
  "distrito federal": "007",
  "rio de janeiro": "019",
  "espirito santo": "008",
  sergipe: "026",
  alagoas: "002",
  pernambuco: "017",
  paraiba: "015",
  amazonas: "004",
  roraima: "023",
  para: "014",
  amapa: "003",
  maranhao: "010",
  piaui: "018",
  ceara: "006",
  "rio grande do norte": "020",
  bahia: "005",
  tocantins: "027",
  "mato grosso": "011",
  rondonia: "022",
  acre: "001",
  "minas gerais": "013",
  "sao paulo": "025",
  "mato grosso do sul": "012",
  parana: "016",
  "santa catarina": "024",
  "rio grande do sul": "021",
  goias: "009",
};

export const AUSTRALIA_IDCODE_CONFIG = {
  tasmania: "TA",
  "new south wales": "SW",
  victoria: "VI",
  "south australia": "SA",
  queensland: "QU",
  "northern territory": "NT",
  "western australia": "WA",
};

export const MIDDLEEAST_IDCODE_CONFIG = {
  oman: "10",
  pakistan: "11",
  qatar: "12",
  "saudi arabia": "13",
  syria: "14",
  tajikistan: "15",
  "turkey": "16",
  turkmenistan: "17",
  "united arab emirates": "18",
  uzbekistan: "19",
  yemen: "20",
  israel: "05",
  kuwait: "07",
  "bahrain": "02",
  lebanon: "09",
  iraq: "04",
  jordan: "06",
  iran: "03",
  afghanistan: "01",
  kyrgyzstan: "08",
};

export const NORTHAMERICA_IDCODE_CONFIG = {
  "antigua and barbuda": "001",
  "bahamas": "002",
  "barbados": "003",
  "belize": "004",
  "canada": "005",
  "cayman islands": "026",
  "costa rica": "006",
  "cuba": "007",
  "dominica": "008",
  "dominican rep.": "009",
  "el salvador": "010",
  "greenland": "024",
  "grenada": "011",
  "guatemala": "012",
  "haiti": "013",
  "honduras": "014",
  "jamaica": "015",
  "mexico": "016",
  "nicaragua": "017",
  "panama": "018",
  "puerto rico": "025",
  "st. kitts & nevis": "019",
  "st. lucia": "020",
  "st. vincent & the grenadines": "021",
  "trinidad & tobago": "022",
  "united states": "023"
};

export const SOUTHAMERICA_IDCODE_CONFIG = {
  chile: "004",
  "falkland islands": "007",
  uruguay: "013",
  guyana: "009",
  suriname: "012",
  "french guiana": "008",
  "galapagos islands": "015",
  "south georgia island": "016",
  colombia: "005",
  venezuela: "014",
  ecuador: "006",
  paraguay: "010",
  argentina: "001",
  peru: "011",
  bolivia: "002",
  brazil: "003",
};

export const INDONESIA_IDCODE_CONFIG = {
  "special region of yogyakarta": "10",
  "west kalimantan": "11",
  "south kalimantan": "12",
  "central kalimantan": "13",
  "east kalimantan": "14",
  lampung: "15",
  "west nusa tenggara": "17",
  "east nusa tenggara": "18",
  "central sulawesi": "21",
  "southeast sulawesi": "22",
  "west sumatra": "24",
  "north sumatra": "26",
  maluku: "28",
  "north maluku": "29",
  "west java": "30",
  "north sulawesi": "31",
  "south sumatra": "32",
  banten: "33",
  gorontalo: "34",
  "bangka–belitung islands": "35",
  "special region of papua": "36",
  riau: "37",
  "south sulawesi": "38",
  "special region of west papua": "39",
  "riau islands": "40",
  "west sulawesi": "41",
  "north kalimantan": "42",
  bengkulu: "03",
  "jakarta special capital region": "04",
  bali: "02",
  "special region of aceh": "01",
  jambi: "05",
  "central java": "07",
  "east java": "08",
};

export const USA_IDCODE_CONFIG = {
  alabama: "AL",
  alaska: "AK",
  arizona: "AZ",
  arkansas: "AR",
  california: "CA",
  colorado: "CO",
  connecticut: "CT",
  delaware: "DE",
  "district of columbia": "DC",
  florida: "FL",
  georgia: "GA",
  hawaii: "HI",
  idaho: "ID",
  illinois: "IL",
  indiana: "IN",
  iowa: "IA",
  kansas: "KS",
  kentucky: "KY",
  louisiana: "LA",
  maine: "ME",
  maryland: "MD",
  massachusetts: "MA",
  michigan: "MI",
  minnesota: "MN",
  mississippi: "MS",
  missouri: "MO",
  montana: "MT",
  nebraska: "NE",
  nevada: "NV",
  "new hampshire": "NH",
  "new jersey": "NJ",
  "new mexico": "NM",
  "new york": "NY",
  "north carolina": "NC",
  "north dakota": "ND",
  ohio: "OH",
  oklahoma: "OK",
  oregon: "OR",
  pennsylvania: "PA",
  "rhode island": "RI",
  "south carolina": "SC",
  "south dakota": "SD",
  tennessee: "TN",
  texas: "TX",
  utah: "UT",
  vermont: "VT",
  virginia: "VA",
  washington: "WA",
  "west virginia": "WV",
  wisconsin: "WI",
  wyoming: "WY",
};
