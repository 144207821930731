import styled from "styled-components";

// Market Activities

export const MarketActivityContainer = styled.div`
    width: 80%;
    margin: 0 auto;
    margin-top: 64px;
`;
export const MarketActivityHeader = styled.div`
     margin-top: 6rem;
     padding-top: 20px;
     padding-bottom: 20px;
     display: flex;
     justify-content:space-between;
     align-items:center;
`;
export const ReporstList = styled.ul`
    margin-top: 25px;
    padding-top: 8px;
    background-color: #46cdcf;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const  ReportCard = styled.li`
    position:relative;
    background-color: #ffffff;
    min-height: 4rem;
    list-style:none;
    margin-top:-9px;
    &:first-child {
        margin-top:0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    }
    display:flex;
    justify-content:space-between;
    align-items: flex-start;
    & > div:first-child{
        display:flex;
        align-items: center;
        width: 50%;
    }
    & > div:last-child{
        display:flex;
        justify-content:space-between;
        align-items: center;
        width: 47%;
        min-height: 4rem;
        margin-right:10px;
        padding-right: 4rem;
    }
    &> div{
        &> h4{
         padding:0;
         margin-top:12px;
         margin-left:0px;
         line-height: 1.2;
         font-size: 16px !important;
        }
       &> p{
            padding:0;
            margin-top:2px;
            margin-left:0px;
            margin-right:5px;
            color: gray;
            line-height:1;
        }
        & >button{
            border: 1px solid #46cdcf;;
            background-color:white;
            color: #46cdcf;
            padding: 2px 6px;
            border-radius: 15px;
            &:hover{
                cursor:pointer;
                background-color:#46cdcf;
                color:#ffffff ;
            }
        } 
        &> img{
            height: 1.8rem;
            width: 1.8rem;
            &:hover{
                cursor:pointer;
            }
        }
    }
`;


export const Label = styled.div`
            background-color:#e52562;
            color:white;
            display:flex;
            justify-content:center;
            align-items:center;
            font-size: 12px;
            font-weight:bold;
            padding: 5px 8px;
            position:absolute; 
            right: 0; 
            top:50% ;
            transform: translate(50%, -50%); 
`;

export const ViewMore = styled.div`
      display: flex;
      justify-content:center;
      align-items:center;
      margin-bottom:5rem;
      & > button{
      background-color: #ffffff;
      color: #46cdcf;
      border: none;
      font-size:16px;
       &:hover{
        cursor:pointer;
       }
      }
`;

export const SpinBox = styled.div`
   display: flex;
   justify-content:center;
   align-items:center;
   height: 10rem;
`;

// Modals

export const ModalContainer = styled.div`
     position:fixed;
     z-index:100;
     top:0;
     bottom:0;
     left:0;
     right:0;
     background-color: rgba(0,0,0,.75);
`;

export const CloseModal = styled.button`
    position : absolute;
    right: 8px;
    top: 8px;
    color: rgba(255,0,0,.35);
    background-color:#ffffff;
    border: 2px solid rgba(255,0,0,.35);
    border-radius:50%;
    font-size:.8rem;
    height: 1.5rem;
    width: 1.5rem;
    &:hover{
     cursor:pointer;
     color: rgb(255,0,0);
     border-color: rgb(255,0,0)
    }
`;

// Update Meta 

export const UpdateMetaContainer = styled.form`
      position:fixed;
      top: 50%;
      left:50%;
      height:450px;
      width:1200px;
      padding: 2.5rem;
      background-color:#ffffff;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
      transform: translate(-50%, -50%);
      &> fieldset{
      &> legend{
        width:100%;
        text-align:center;
        font-size:1.6rem;
        font-weight:bold;
        color: #46cdcf;
        margin-top:-2rem;
      }
      & >div{
        display:flex;
        flex-direction:column;

        &>div{
        display:flex;
        justify-content:space-between;
        align-items:flex-start;
        margin-bottom: 1rem;
        
        &>label{
          flex: 0 1 15%;
          color: darkgray;
          font-size:1.1rem;
        }
        &> input{
            flex: 0 1 85%;
            border: 1px solid lightgray;
            &:focus{
                border: 1px solid #46cdcf;
                outline: none;
            }
        }
        &> textarea{
            flex: 0 1 85%;
            border: 1px solid lightgray;
            &:focus{
                border: 1px solid #46cdcf;
                outline: none;
            }
        }
       }
      }
    }
`;

export const Submit = styled.button`
    pointer-events:${(props) => props.disabled ? 'none' : null};
    position : absolute;
    left: 50%;
    bottom: 2rem;
    transform:translateX(-50%);
    color: ${(props) => props.disabled ? 'lightgray' : '#46cdcf'};
    font-weight:bold;
    background-color:#ffffff;
    border:  ${(props) => props.disabled ? '1px solid lightgray' : '1px solid #46cdcf'}; 
    padding: .25rem .75rem;
    border-radius:4px;
    &:hover{   
     color: ${(props) => props.disabled ? 'lightgray' : '#ffffff'};
     background-color:${(props) => props.disabled ? 'white' : '#46cdcf'};
    }
`;


// Publish RD

export const PublishRDContainer = styled.div`
      position:absolute;
      top: 50%;
      left:50%;
      height:300px;
      width:600px;
      background-color:#ffffff;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
      transform: translate(-50%, -50%);
      & >div{
        padding:2rem;
        & >p{
           margin-top:2rem;
           margin-bottom:1rem;
           color: #ffb01e;
        }
      }
`;

export const FullPageLoader = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,.65);
    display:flex;
    justify-content:center;
    align-items:center;
`;
