import {
  CODE_COUNTRY_CONFIG,
  CONTINENT_CONFIG,
  SUBCONTINENT_CONFIG,
  COUNTRY_CONFIG,
  COLOR_CONFIG,
  CONTINENT_IDCODE_CONFIG
} from "./graphs.configs";

export const roundOfMaxYAxisValue = (value) => {
  let divValue = value,
    val = (Number(divValue).toFixed(0)).toString();
  let len = val.length - 1;
  let lenNO = Number(val.charAt(0)) + 2;
  for (let i = 0; i < len; i++)
    lenNO += "0"
  divValue = (Math.ceil(divValue / Number(lenNO))) * Number(lenNO);

  return divValue;
}

export const roundOfpositive_NegativeMaxValue = (max, min) => {
  if (max < -1)
    max = max * -1;
  if (min < -1)
    min = min * -1;
  if (max < min)
    max = min;

  return Math.ceil(max / 5) * 5;
}

export const maxObject = function (data) {
  return data.reduce(function (prev, current) {
    return prev.value > current.value ? prev : current;
  });
};

export const minObject = function (data) {
  return data.reduce(function (prev, current) {
    return prev.value < current.value ? prev : current;
  });
};

export function removeLegends(data) {
  let total = 0;
  for (let item of data) {
    total += parseInt(item.value);
  }
  let delete_items = [];
  for (let [index, item] of data.entries()) {
    let percent = (item.value / total) * 100;
    if (percent === 0) delete_items.push(index)
    // if (percent < 6) item.label = "";
  }
  delete_items.reverse();
  for (let item of delete_items) {
    data.splice(item, 1);
  }
  return data;
}

export function placeValueOutside(data) {
  let total = 0;
  for (let item of data) {
    total += parseInt(item.value);
  }
  for (let item of data) {
    let percent = (item.value / total) * 100;

    if (percent < 4) {
      if (percent < 2) {
        item['labelPosition'] = "none";
        item['valuePosition'] = "none";
        // item['labelFontColor']= "#ffffff";
        item['showValue'] = false;
      }
      else {
        item['labelPosition'] = "outside";
        item['valuePosition'] = "outside";
        item['labelFontColor'] = "#7f7f7f";
        item['showValue'] = true;
      }
    }
  }
  return data;
}

export function data_length(data) {
  let datalength = 0;
  for (let item of data) {
    let count = (item.label + "").match(/<br/g);
    if (count !== null) datalength += count.length;
    if (item.label !== "") datalength++;
  }
  return datalength;
}

export function parseCatagory(data) {
  let alllabels = [];
  for (let item of data) {
    alllabels.push({
      label: item.label,
    });
  }
  return alllabels;
};

export function parseContinentData(data) {
  let arr = [];
  for (let item of data) {
    if (CONTINENT_IDCODE_CONFIG[item.label.toLowerCase()]) {
      arr.push({
        id: CONTINENT_IDCODE_CONFIG[item.label.toLowerCase()],
        value: item.value,
        displayValue: Number(item.value).toFixed(1),
        showLabel: "1",
        fontcolor: "#ffffff",
      });
      if (CONTINENT_IDCODE_CONFIG[item.label.toLowerCase()] === "AS")
        arr.push({
          id: "AU",
          value: item.value,
          displayValue: Number(item.value).toFixed(1),
          showLabel: "1",
          fontcolor: "#ffffff",
        });
    }
  }

  return arr;
};

export function parseContinentDataForExperimental(data) {
  let arr = [];
  for (let item of data) {
    if (CONTINENT_IDCODE_CONFIG[item.label.toLowerCase()]) {
      arr.push({
        id: CONTINENT_IDCODE_CONFIG[item.label.toLowerCase()],
        value: item.value,
        displayValue: item.value.toFixed(1),
        showLabel: "0",
      });
      if (CONTINENT_IDCODE_CONFIG[item.label.toLowerCase()] === "AS")
        arr.push({
          id: "AU",
          value: item.value,
          displayValue: item.value.toFixed(1),
          showLabel: "0",
        });
    }
  }
  return arr;
}

export function parseContinentDataForGeography(data) {
  let arr = [];
  for (let item of data) {
    if (CONTINENT_IDCODE_CONFIG[item.label.toLowerCase()]) {
      arr.push({
        id: CONTINENT_IDCODE_CONFIG[item.label.toLowerCase()],
        value: item.value,
        displayValue: Number(item.value).toFixed(1),
        showLabel: "1",
        fontcolor: "#ffffff",
      });
      if (CONTINENT_IDCODE_CONFIG[item.label.toLowerCase()] === "AS")
        arr.push({
          id: "AU",
          value: item.value,
          displayValue: Number(item.value).toFixed(1),
          showLabel: "1",
          fontcolor: "#ffffff",
        });
    }
  }
  return arr;
}

export function parseDataForMSBar(data) {
  let allKeys = Object.keys(data[0]).filter((val) => val !== "label");

  let dataset = [];

  for (let item_key of allKeys) {
    let dataname = {
      seriesname: item_key,
      data: [],
    };
    dataset.push(dataname);
  }
  for (let item of data) {
    for (let item_label of allKeys) {
      for (let item_key of dataset) {
        if (item_key.seriesname === item_label) {
          let value = {
            value: item[item_label],
          };
          item_key.data.push(value);
        }
      }
    }
  }
  return dataset;
};

export function parseDataForMSLine(data) {
  if (!data || data.length === 0) return [0, 0];
  let arr = [];
  for (let item of data) {
    for (let [index, child_item] of item.data.entries()) {
      if (arr[index]) arr[index] += child_item.value;
      else arr[index] = child_item.value;
    }
  }
  arr.sort(function (a, b) {
    if (a > b) return -1;
    else return 1;
  });

  return arr;
};

export function parseDataForMSColumn(data) {
  if (!data || data.length === 0) return [0, 0];
  let arr = [];
  for (let item of data) {
    for (let [index, child_item] of item.data.entries()) {
      if (arr[index]) arr[index] += child_item.value;
      else arr[index] = child_item.value;
    }
  }
  arr.sort(function (a, b) {
    if (a > b) return -1;
    else return 1;
  });

  return arr;
};

export function parseDataForSColumn(data) {
  let temp_arr = [];
  for (let key in data) {
    let color = COLOR_CONFIG[(data[key] + "").toLowerCase()];
    if (CONTINENT_CONFIG[key.toLowerCase()]) {
      for (let subCont of CONTINENT_CONFIG[key.toLowerCase()]) {
        for (let country of SUBCONTINENT_CONFIG[subCont]) {
          let index = temp_arr.findIndex((elem) => elem.id === country);
          if (index !== -1) {
            if (color === -1) {
              temp_arr.splice(index, 1);
            } else {
              temp_arr[index].value = color;
            }
          } else if (color !== -1) {
            temp_arr.push({
              id: country,
              value: color,
            });
          }
        }
      }
    } else if (SUBCONTINENT_CONFIG[key.toLowerCase()]) {
      for (let country of SUBCONTINENT_CONFIG[key.toLowerCase()]) {
        let index = temp_arr.findIndex((elem) => elem.id === country);
        if (index !== -1) {
          if (color === -1) {
            temp_arr.splice(index, 1);
          } else {
            temp_arr[index].value = color;
          }
        } else if (color !== -1) {
          temp_arr.push({
            id: country,
            value: color,
          });
        }
      }
    } else if (COUNTRY_CONFIG[key.toLowerCase()]) {
      let country = COUNTRY_CONFIG[key.toLowerCase()];
      let index = temp_arr.findIndex((elem) => elem.id === country);
      if (index !== -1) {
        if (color === -1) {
          temp_arr.splice(index, 1);
        } else {
          temp_arr[index].value = color;
        }
      } else if (color !== -1) {
        temp_arr.push({
          id: country,
          value: color,
        });
      }
    }
  }
  let data_arr = [];
  for (let item of temp_arr) {
    let temp = {
      id: CODE_COUNTRY_CONFIG[item.id],
      value: item.value,
    };
    data_arr.push(temp);
  }

  return data_arr;
};

export function parseDataForExperimental(data) {
  let temp_arr = [];
  for (let key in data) {
    let color = COLOR_CONFIG[(data[key] + "").toLowerCase()];
    if (CONTINENT_CONFIG[key.toLowerCase()]) {
      for (let subCont of CONTINENT_CONFIG[key.toLowerCase()]) {
        for (let country of SUBCONTINENT_CONFIG[subCont]) {
          let index = temp_arr.findIndex((elem) => elem.id === country);
          if (index !== -1) {
            if (color === -1) {
              temp_arr.splice(index, 1);
            } else {
              temp_arr[index].value = color;
            }
          } else if (color !== -1) {
            temp_arr.push({
              id: country,
              value: color,
            });
          }
        }
      }
    } else if (SUBCONTINENT_CONFIG[key.toLowerCase()]) {
      for (let country of SUBCONTINENT_CONFIG[key.toLowerCase()]) {
        let index = temp_arr.findIndex((elem) => elem.id === country);
        if (index !== -1) {
          if (color === -1) {
            temp_arr.splice(index, 1);
          } else {
            temp_arr[index].value = color;
          }
        } else if (color !== -1) {
          temp_arr.push({
            id: country,
            value: color,
          });
        }
      }
    } else if (COUNTRY_CONFIG[key.toLowerCase()]) {
      let country = COUNTRY_CONFIG[key.toLowerCase()];
      let index = temp_arr.findIndex((elem) => elem.id === country);
      if (index !== -1) {
        if (color === -1) {
          temp_arr.splice(index, 1);
        } else {
          temp_arr[index].value = color;
        }
      } else if (color !== -1) {
        temp_arr.push({
          id: country,
          value: color,
        });
      }
    }
  }
  let data_arr = [];

  for (let item of temp_arr) {
    let temp = {
      id: CODE_COUNTRY_CONFIG[item.id],
      value: item.value,
    };
    data_arr.push(temp);
  }
  return data_arr;
}

export function parseDataForGeography(data) {
  let temp_arr = [];
  for (let key in data) {
    let color = COLOR_CONFIG[(data[key] + "").toLowerCase()];
    if (CONTINENT_CONFIG[key.toLowerCase()]) {
      for (let subCont of CONTINENT_CONFIG[key.toLowerCase()]) {
        for (let country of SUBCONTINENT_CONFIG[subCont]) {
          let index = temp_arr.findIndex((elem) => elem.id === country);
          if (index !== -1) {
            if (color === -1) {
              temp_arr.splice(index, 1);
            } else {
              temp_arr[index].value = color;
            }
          } else if (color !== -1) {
            temp_arr.push({
              id: country,
              value: color,
            });
          }
        }
      }
    } else if (SUBCONTINENT_CONFIG[key.toLowerCase()]) {
      for (let country of SUBCONTINENT_CONFIG[key.toLowerCase()]) {
        let index = temp_arr.findIndex((elem) => elem.id === country);
        if (index !== -1) {
          if (color === -1) {
            temp_arr.splice(index, 1);
          } else {
            temp_arr[index].value = color;
          }
        } else if (color !== -1) {
          temp_arr.push({
            id: country,
            value: color,
          });
        }
      }
    } else if (COUNTRY_CONFIG[key.toLowerCase()]) {
      let country = COUNTRY_CONFIG[key.toLowerCase()];
      let index = temp_arr.findIndex((elem) => elem.id === country);
      if (index !== -1) {
        if (color === -1) {
          temp_arr.splice(index, 1);
        } else {
          temp_arr[index].value = color;
        }
      } else if (color !== -1) {
        temp_arr.push({
          id: country,
          value: color,
        });
      }
    }
  }
  let data_arr = [];

  for (let item of temp_arr) {
    let temp = {
      id: CODE_COUNTRY_CONFIG[item.id],
      value: item.value,
    };
    data_arr.push(temp);
  }
  return data_arr;
}


export function digitLength(num) {
  var value = Number(num).toFixed(0);
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value));
  shortValue = shortValue.toFixed(0);
  return ("" + shortValue).length;
}
export function maximumStack(data) {
  let arr = [];
  data.map(elm => {
    elm.data.map((item, i) => {
      if (arr[i] === undefined) {
        arr[i] = +(item.value);
      } else {
        arr[i] += +(item.value);
      }
    })
  })
  arr.sort((a, b) => b - a);
  return (arr[0] * 1.15);
}
export function intToString(value) {
  var suffixes = ["", "k", "M", "B", "T"];
  value = Number(value).toFixed(0);
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value));
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
}
export function divideByUnit(num, unit, decimals) {
  let value = num;
  switch (unit) {
    case "T": {
      value = (num / 1000000000000);
      break;
    }
    case "B": {
      value = (num / 1000000000);
      break;
    }
    case "M": {
      value = (num / 1000000);
      break;
    }
    case "K": {
      value = (num / 1000);
      break;
    }
    default: {
      value = num
    }
  }
  return Number(value).toFixed(decimals);
}
export function intToStringDynamic(value, decimals) {
  var suffixes = ["", "k", "M", "B", "T"];
  value = Number(value).toFixed(0);
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value));
  if (shortValue < 1) {
    suffixNum = suffixNum - 1
    shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value));
  }
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(decimals);
  }
  return shortValue + suffixes[suffixNum];
}
export function firstColumnSumValue(data) {
  let arr = [];
  data.map(elm => {
    elm.data.map((item, i) => {
      if (arr[i] === undefined) {
        arr[i] = +(item.value);
      } else {
        arr[i] += +(item.value);
      }
    })
  });
  return arr[0];
}
export function firstColumnSumValueDynamic(data, index) {
  let arr = [];
  data.map(elm => {
    elm.data.map((item, i) => {
      if (arr[i] === undefined) {
        arr[i] = +(item.value);
      } else {
        arr[i] += +(item.value);
      }
    })
  });
  return arr[index];
}
export function breakLabels(string, length = 15) {
  let reg = new RegExp(".{1," + length + "}", "g");
  let arr = string.match(reg)?string.match(reg):[];
  let newStr = "";

  for (let [index, value] of arr.entries()) {
    if (value[value.length - 1] === " ") {
      newStr = newStr + value + `<br/>`;
    } else if (index + 1 === arr.length) {
      newStr = newStr + value;
    } else if (value[value.length - 1] !== " ") {
      newStr = newStr + value + `- <br/>`;
    }
  }

  return newStr;
}