import React, { useState, useEffect} from 'react';
import * as fs from 'fs';


import axios from 'axios';
import config from '../../config/Api';

import { Modal , Tooltip, notification} from 'antd';
import { regionCountryConfig, instructionConfig } from './HLPDataUpload.config';
import { Form, Header, Fieldset, Legend, Separator, Label, Input, TextArea, AddButton, DeleteButton, Instruction, Warning, Ul, Li, Error, Indentor, SingleSelect, MultipleSelect , CloseModal, DeleteImageButton} from "./styles";
import {setLinkCounter} from "../../reducersAndActions/Actions";
import { connect } from "react-redux";

// Instruction Images Imports
import infoIcon from "../../images/HLPImages/infoIcon.svg";
import viewIcon from "../../images/HLPImages/view.svg";
import hubNameImage from "../../images/HLPImages/hubNameImage.jpg";
import dataPointsImage from "../../images/HLPImages/dataPointsImage.jpg";
import industryReportsImage from "../../images/HLPImages/industryReportsImage.jpg";
import marketSegmentsImage from "../../images/HLPImages/marketSegmentsImage.jpg";
import companiesWithMarketSharesImage from "../../images/HLPImages/companiesWithMarketShareImage.jpg";
import clientsLogosImage from "../../images/HLPImages/clientsLogosImage.png";
import testimonialAuthorImage from "../../images/HLPImages/testimonialAuthorImage.jpg";
import testimonialTextImage from "../../images/HLPImages/testimonialTextImage.jpg";
import segmentNameImage from "../../images/HLPImages/segmentNameImage.jpg";
import segmentCategoryTitleImage from "../../images/HLPImages/segmentCategoryTitleImage.jpg";
import segmentCategoryBulletsImage from "../../images/HLPImages/segmentCategoryBulletsImage.jpg";
import expertInsightsTitleImage from "../../images/HLPImages/expertInsightsTitleImage.jpg";
import expertInsightsDescriptionImage from "../../images/HLPImages/expertInsightsDescriptionImage.jpg";
import expertInsightsProfileImage from "../../images/HLPImages/expertInsightsProfileImage.jpg";
import expertInsightsGraphImage from "../../images/HLPImages/expertInsightsGraphImage.jpg";
import expertInsightsNameImage from "../../images/HLPImages/expertInsightsNameImage.jpg";
import expertInsightsRoleImage from "../../images/HLPImages/expertInsightsRoleImage.jpg";
import expertInsightsIndustryImage from "../../images/HLPImages/expertInsightsIndustryImage.jpg";
import mordorVsOthersImage from "../../images/HLPImages/mordorVsOthersImage.png";
import researchMethodologyTextImage from "../../images/HLPImages/researchMethodologyTextImage.jpg";
import researchMethodologyTitleImage from "../../images/HLPImages/researchMethodologyTitleImage.jpg";
import researchMethodologyBulletsImage from "../../images/HLPImages/researchMethodologyBulletsImage.jpg";
import customizedSolutionImage from "../../images/HLPImages/customizedSolutionsImage.jpg";
import customizedSolutionMobileImage from "../../images/HLPImages/customizedSolutionsMobileImage.jpg";
import reportImageImage from "../../images/HLPImages/reportImageImage.jpg";
import reportVideoImage from "../../images/HLPImages/reportVideoImage.jpg";
import sampleVideoImage from "../../images/HLPImages/sampleVideoImage.jpg";
import rdTitleImage from "../../images/HLPImages/rdTitleImage.jpg";
import rdBulletsImage from "../../images/HLPImages/rdBulletsImage.jpg";
import rbImageImage from "../../images/HLPImages/rbImageImage.jpg";
import rbSegmentsImage from "../../images/HLPImages/rbSegmentsImage.jpg";
import rbRegionsImage from "../../images/HLPImages/rbRegionsImage.jpg";
import rbCountriesImage from "../../images/HLPImages/rbCountriesImage.jpg";

const base_url = config.api.base_url;
const website_url = config.api.website_url;
const preview_url = config.api.express_url;
const mediaUploadUrl = "http://localhost:8080/api/hub_landing_page/logos_upload";

let regions=[], countries=[];

export default function HLPDataUpload(props) {
  // States & Refs
  let [info, setInfo]= useState({
    falg:false,
    name:""
  });
  let [imgArrToPreview, setImgArrToPreview] = useState(['hhhhhh', 'jjjjjj']);
  let [imgPreviewType, setImgPreviewType] = useState('');
  let [isImgModalOpen, setIsImgModalOpen] = useState(false);
  let [selectedRegion, setSelectedRegion] = useState("Global");
  let [selectedCountries, setSelectedCountries] = useState([]);
  let [set, setSet]= useState([]);
  let [sstoggle, setSsToggle] =useState(false);
  let [mstoggle, setMsToggle] =useState(false);
  let [fileErrors, setFileErrors] = useState({
    clientsError: "",
    eiProfileImgError: "",
    eiImageError: "",
    csImageError: "",
    csMobImageError: "",
    rdReportImageError: "",
    rdReportVideoError: "",
    rdSampleVideoError: "",
    rbImageError:""
  });
  let [segment, setSegment] = useState("");
  let [hubId, setHubId] = useState(0);
  let [hubName, setHubName] = useState("");
  let [slug, setSlug] = useState("");
  let [dataValues, setDataValues] = useState([
    {
      data: 0,
      title: "Data Points"
    },
    {
      data: 0,
      title: "Industry Reports"
    },
    {
      data: 0,
      title: "Market Segments"
    },
    {
      data: 0,
      title: "Companies With Market Shares"
    }
  ]);
  let [clients, setClients] = useState([]);
  let [testimonials, setTestimonials] = useState([]);
  let [segmentalCoverage, setSegmentalCoverage] = useState({});
  let [reportsAndDatasets, setReportsAndDatasets] = useState({
    image: "",
    mp4_url: "",
    mp4_url1: "",
    desc: [],
  });
  let [reportsBundles, setReportsBundles] = useState({
    image:"",
    desc:[
      {},
      {},
      {
      title: "Select the segments of your interest",
      bullets: [],
      }
    ]
  })
  let [customizedSolution, setCustomizedSolution] = useState({
    image: "",
    mobImage: ""
  });
  let [researchMethodology, setResearchMethodology] = useState({
    text: "",
    data: [],
  });
  let [mordorVsOthers, setMordorVsOthers] = useState([
    [
      "Research Approach",
      "Reporting",
      "Datasheets For Your Analysis",
      "Customization",
      "Query Resolution",
      "Analyst Support",
      "Usage Terms",
      "Flexible Offerings"
    ],
    [
      "True Bottom-Up approach",
      "Succinct",
      "Data-sheets complimentary",
      "Up to 20% at no additional cost",
      "<24 Business Hours",
      "Unlimited for 6 months",
      "100% refund if you do not like our reports",
      "Buy just the Data, data + report, bundles of reports, or consolidated dataset for the industry"
    ],
    [
      "Mostly top-down",
      "Verbose",
      "Data-sheets chargeable",
      "At additional cost",
      "Long turnaround times",
      "Limited",
      "Limited or no refunds",
      "Inflexible Offerings consisting of only reports"
    ]
  ]);
  let [expertInsights, setExpertInsights] = useState({
    title: "",
    description: "",
    profileImg: "",
    name: "",
    role: "",
    industry: "",
    image: ""
  });
  let [active, setActive] = useState(true);
  let [meta, setMeta] = useState({
    title: "",
    keywords: "",
    desc: ""
  });
  let [relatedTags, setRelatedTags] = useState([ "testimonial_bottom"]);
  let [finalDraft, setFinalDraft] = useState({});

  // Effects 
  useEffect(()=>{
    regions = [];
    for(let key in regionCountryConfig){
        regions.push(key);
    }
  })

  useEffect(async ()=>{
    mapDraftDataOverForm();
  }, []);


  // Media Handeling
  let getMediaUrl = async(fileArray=[], key='')=>{
      let cp_logos = key === 'clients' ? true : false;
      let data = new FormData();
          data.append('cp_logo', cp_logos);
          data.append('hub_name', hubName);
          data.append('hub_id', hubId);

          fileArray?.length && fileArray.forEach(item=>{
            data.append('files', item);
          })
     
     let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${base_url}/api/hub_landing_page/upload_files`,
      data :data
      };

      axios(config)
      .then((response)=>{
        let args = {
          message: "Media(s) Saved", 
          description: response.data.message,
          duration: 2.5,
          placement: "topRight",
        };
        notification.success(args);
        if(key=== 'clients'){
          let newClients = [...clients];
          newClients.push(...response.data.urls);
          setClients(newClients);
        }else if(key=== 'ei-profileImg'){
          let newExpertInsights = {...expertInsights};
              newExpertInsights['profileImg'] = response.data.urls[0];
          setExpertInsights(newExpertInsights )
        }else if(key=== 'ei-image'){
          let newExpertInsights = {...expertInsights};
          newExpertInsights['image'] = response.data.urls[0];
          setExpertInsights(newExpertInsights)
        }else if(key === "cs-image"){
          let newCustomizedSolution = {...customizedSolution};
              newCustomizedSolution['image'] = response.data.urls[0];
              setCustomizedSolution(newCustomizedSolution);
        }else if(key === "cs-mobImage"){
          let newCustomizedSolution = {...customizedSolution};
              newCustomizedSolution['mobImage'] = response.data.urls[0];
              setCustomizedSolution(newCustomizedSolution);
        }else if(key === "rb-image"){
            let newReportsBundles = {...JSON.parse(JSON.stringify(reportsBundles))}
             newReportsBundles['image'] = response.data.urls[0];
            setReportsBundles(newReportsBundles);
        }else if(key === "rd-image"){
          let newReportsAndDatasets = {...JSON.parse(JSON.stringify(reportsAndDatasets))}
              newReportsAndDatasets['image'] = response.data.urls[0];
              setReportsAndDatasets(newReportsAndDatasets);
      }else if(key === "rd-mp4_url"){
        let newReportsAndDatasets  = {...JSON.parse(JSON.stringify(reportsAndDatasets))}
            newReportsAndDatasets['mp4_url'] = response.data.urls[0];
            setReportsAndDatasets(newReportsAndDatasets);
    }else if(key === "rd-mp4_url1"){
      let newReportsAndDatasets = {...JSON.parse(JSON.stringify(reportsAndDatasets))}
          newReportsAndDatasets['mp4_url1'] = response.data.urls[0];
          setReportsAndDatasets(newReportsAndDatasets);
  }
       
        return response.data.urls;
       })
      .catch((error)=>{
        let args = {
          message:"Failed To Update Draft", 
          description: error,
          duration: 2,
          placement: "topRight",
        };
        notification.error(args);
       });
  }

// HANDELING INPUTS
  let handleInfo=(event)=>{
    setInfo({flag:true, name: event.target.name});
  }

  let handleSlug = (event) => {
    let value = hubName.replaceAll("&", "and").replaceAll(".", "").trim().split(" ").join("-").toLowerCase();
    setSlug(value);
  }

  // Testimonials 
  let addTestimonial = (event) => {
    event.preventDefault();
    let tempArr = [...testimonials];
    tempArr.push({ text: "", author: "", id: `${Date.now()}` })
    setTestimonials(tempArr);
  }
  let deleteTestimonial = (event, id) => {
    event.preventDefault();
    let tempArr = [...testimonials];
    let index = tempArr.findIndex(elem => {
      return elem.id === id;
    })
    tempArr.splice(index, 1)
    setTestimonials(tempArr);
  }

  //  Segment 
  let addSegment = (event) => {
    event.preventDefault();
    let tempObj = { ...segmentalCoverage };
    let key = segment.trim();
    if (key.length) {
      tempObj[key] = [];
    }
    setSegmentalCoverage(tempObj);
    setSegment("");
  }
  let deleteSegment = (event, key = null) => {
    event.preventDefault();
    let tempObj = { ...segmentalCoverage };
    delete tempObj[key];
    setSegmentalCoverage(tempObj);
  }
  let addCategory = (event, key = null) => {
    event.preventDefault();
    let tempObj = { ...segmentalCoverage };
    tempObj[key].push({
      id: `${Date.now()}`,
      title: "",
      bullets: []
    })
    setSegmentalCoverage(tempObj);
  }
  let deleteCategory = (event, key = null, id=null) => {
    event.preventDefault();
    let tempObj = { ...segmentalCoverage };
    let index = tempObj[key].findIndex(item=>{
      return item.id === id;
    })
    tempObj[key].splice(index, 1);
    setSegmentalCoverage(tempObj);
  }
  let handleSegmentalInputs = (event, key=null, id=null)=>{
    let { value, name } = event.target;
    switch (true) {
      case name.includes("segment"): {
        setSegment(value);
        break;
      }
      case name.includes("sc-"): {
        let tempObj = { ...segmentalCoverage };
        let index =  tempObj[key].findIndex(item=>{
          return item.id === id;
        })
        if (name.includes("sc-title")) {
          tempObj[key][index].title = value;
        } else if (name.includes("sc-bullets")) {
          tempObj[key][index].bullets = value.trim().split(";");
        }
        setSegmentalCoverage(tempObj);
        break;
      }
    }
  }
  let renderSegment = (segmentalCoverage) => {
    let arr = [];
    for (let key in segmentalCoverage) {
      let keyName = key;
      arr.push(
        <Indentor key={keyName}>
          <Separator>
            <Label style={{ fontWeight: "600" }}>
              {keyName}
            </Label>
            <AddButton onClick={(event) => addCategory(event, keyName)}>Add Catagory</AddButton>
            <DeleteButton onClick={(event) => deleteSegment(event, keyName)}>Delete Segment</DeleteButton>
          </Separator>
          {
            segmentalCoverage[keyName].map((item, index) => {
              return <Indentor>
                <Separator>
                  <Label htmlFor={`sc-title-${index}`}>Title</Label>
                  <Input id={`sc-title-${index}`} name={`sc-title-${index}`} value={item.title} onChange={(event) => handleSegmentalInputs(event, keyName, item.id)} />
                  <Instruction>
                  <img src={infoIcon} alt="info" name="segmentCategoryTitle" onClick={handleInfo}/>
                  </Instruction>
                </Separator>

                <Separator>
                  <Label htmlFor={`sc-bullets-${index}`}>Bullets</Label>
                  <TextArea id={`sc-bullets-${index}`} name={`sc-bullets-${index}`} value={item.bullets.join(';')} onChange={(event) => handleSegmentalInputs(event, keyName, item.id)}></TextArea>
                  <Instruction>
                  <img src={infoIcon} alt="info" name="segmentCategoryBullets" onClick={handleInfo}/>
                  </Instruction>
                  <Instruction>Separate different bullets using semi-colon(;)</Instruction>
                </Separator>
                <DeleteButton onClick={(event) => deleteCategory(event, keyName, item.id)}>Delete Category</DeleteButton>
              </Indentor>
            })
          }
        </Indentor>
      )
    }
    return arr.map(item => item);
  }

  // Solutions
  let addDescription = (event, id = null) => {
    event.preventDefault();
    let tempObj = { ...reportsAndDatasets };
    tempObj.desc.push({
      id: `${Date.now()}`,
      title: "",
      bullets: [],
    })
    setReportsAndDatasets(tempObj);
  }
  let deleteDescription = (event, id = null) => {
    event.preventDefault();
    let tempObj = { ...reportsAndDatasets };
    let index = tempObj.desc.findIndex(item => { return item.id === id });
    tempObj.desc.splice(index, 1);
    setReportsAndDatasets(tempObj);
  }
  let addSet=(event)=>{
    event.preventDefault();
    let tempArr = [...set];
    if(selectedRegion.length){
      tempArr.push({
        id: `${Date.now()}`,
        region: selectedRegion,
        countries: selectedCountries.length ? [...selectedCountries] : ["All"],
    });
    setSet(tempArr);
    setSsToggle(false);
    setMsToggle(false);
    setSelectedRegion("");
    setSelectedCountries([]);
    }
  }
  let deleteSet=(event, id)=>{
  event.preventDefault();
  let tempArr = [...set];
   let index  = tempArr.findIndex(elem=>{
    return elem.id === id
   })
   tempArr.splice(index, 1);
  setSet(tempArr);
  }
  let handleSolutionsInputs = async(event, id = null) => {
    let { value, name, files } = event.target;

    switch (true) {
      case name.includes("rd"): {
          let key = name.split('-')[1];
          let tempObj = { ...reportsAndDatasets };
        if (event.target.files != null) {
           let file = Array.from(event.target.files)[0];
           if (key === "image") {
              let errorFlag = !['image/svg+xml', 'image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
            if (errorFlag) {
               setFileErrors({ ...fileErrors, rdReportImageError: "File type should not other than .svg, .png, .jpg or .jpeg." });
             } else {
              tempObj[key] = file;
              // setReportsAndDatasets(tempObj);
              let newUrlsArray = await getMediaUrl([file], 'rd-image');
              setFileErrors({ ...fileErrors, rdReportImageError: "" });
            }
          } else if (key === "mp4_url") {
          let errorFlag = !['video/mp4'].includes(file.type);
            if (errorFlag) {
            setFileErrors({ ...fileErrors, rdReportVideoError: "File type should not other than .mp4 ." });
            } else {
            tempObj[key] = file;
            // setReportsAndDatasets(tempObj);
            let newUrlsArray = await getMediaUrl([file], 'rd-mp4_url');
            setFileErrors({ ...fileErrors, rdReportVideoError: "" });
          }
         } else if (key === "mp4_url1") {
          let errorFlag = !['video/mp4'].includes(file.type);
          if (errorFlag) {
            setFileErrors({ ...fileErrors, rdSampleVideoError: "File type should not other than .mp4 ." });
          } else {
            tempObj[key] = file;
            // setReportsAndDatasets(tempObj);
            let newUrlsArray = await getMediaUrl([file], 'rd-mp4_url1');
            setFileErrors({ ...fileErrors, rdSampleVideoError: "" });
          }
        }
        } else {
          let index = tempObj.desc.findIndex(item=> {return item.id === id})
        if (name.includes('rd-title')) {
          tempObj.desc[index][key] = value;
        } else if (name.includes('rd-bullets')) {
          tempObj.desc[index][key] = value.trim().split(';');
        }
        setReportsAndDatasets(tempObj);
      }
      break;
      }
      case name.includes("rb"):{
        let key = name.split('-')[1];
        let tempObj = {...reportsBundles};
        if (event.target.files != null) {
          let file = Array.from(event.target.files)[0];
          if (key === "image") {
            let errorFlag = !['image/svg+xml', 'image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
          if (errorFlag) {
             setFileErrors({ ...fileErrors, rbImageError: "File type should not other than .svg, .png, .jpg or .jpeg." });
           } else {
            tempObj[key] = file;
            // setReportsBundles(tempObj);
            let newUrlsArray = await getMediaUrl([file], 'rb-image');
            setFileErrors({ ...fileErrors, rbImageError: "" });
          }
        } 
        }
        break;
      }
      case name.includes('sg'):{
        let tempObj = {...reportsBundles};
        tempObj.desc[2].bullets = value.split(';');
        setReportsBundles(tempObj);
        break;
      }
      case name.includes("cs"): {
      let key = name.split('-')[1];
      let tempObj = { ...customizedSolution };
      if (event.target.files !== null) {
        let file = Array.from(event.target.files)[0];
        if (key === "image") {
          let errorFlag = !['image/svg+xml', 'image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
          if (errorFlag) {
            setFileErrors({ ...fileErrors, csImageError: "File type should not other than .svg, .png, .jpg or .jpeg." });
          } else {
            tempObj[key] = file;
            let newUrlsArray = await getMediaUrl([file], 'cs-image');
            // setCustomizedSolution(tempObj);
            setFileErrors({ ...fileErrors, csImageError: "" });
          }
        } else if (key === "mobImage") {
          let errorFlag = !['image/svg+xml', 'image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
          if (errorFlag) {
            setFileErrors({ ...fileErrors, csMobImageError: "File type should not other than .svg, .png, .jpg or .jpeg." });
          } else {
            tempObj[key] = file;
            let newUrlsArray = await getMediaUrl([file], 'cs-mobImage');
            // setCustomizedSolution(tempObj);
            setFileErrors({ ...fileErrors, csMobImageError: "" });
          }
        }
      }
      break;
      }
   }
  }

  // Research Methodology
  let addData = (event) => {
    event.preventDefault();
    let tempObj = { ...researchMethodology };
    tempObj.data.push({
      id: `${Date.now()}`,
      title: "",
      bullets: [],
    })
    setResearchMethodology(tempObj);
  }
  let deleteData = (event, id = null) => {
    event.preventDefault();
    let tempObj = { ...researchMethodology };
    let index = tempObj.data.findIndex(item => { return item.id === id });
    tempObj.data.splice(index, 1);
    setResearchMethodology(tempObj);
  }
  let handleMethodologyInputs = (event, id=null)=>{
    let { value, name } = event.target;
    switch(true){
    case name.includes("rm-text"): {
        let tempObj = { ...researchMethodology };
        tempObj.text = value;
        setResearchMethodology(tempObj);
        break;
    }
    case name.includes("rm-title"):{
        let tempObj = { ...researchMethodology };
        let index = tempObj.data.findIndex(item=>{return item.id === id})
        tempObj.data[index].title = value;
        setResearchMethodology(tempObj);
        break;
    } 
    case name.includes("rm-bullets"): {
        let tempObj = { ...researchMethodology };
        let index = tempObj.data.findIndex(item=>{return item.id === id})
        tempObj.data[index].bullets = value.trim().split(";");
        setResearchMethodology(tempObj);
        break;
    }
    }
  }

  // Others Inputs
  let handleInputs = async(event, id = null, index = 0) => {
    let { value, name } = event.target;
    switch (true) {
      case name.includes("hubName"): {
        setHubName(value);
        break;
      }
      case name.includes("dv-"): {
        let tempArr = [...dataValues];
        let key = name.split('-')[1];
        let index = tempArr.findIndex(item => {
          return item.title === key;
        })
        tempArr[index].data = value;
        setDataValues(tempArr);
        break;
      }

      case name.includes("clientsLogos"): {
        let fileArray = Array.from(event.target.files);
        let errorFlag = fileArray.findIndex(file => {
          return !['image/svg+xml', 'image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
        })

        if (errorFlag === -1) {
           let newUrlsArray = await getMediaUrl(fileArray, 'clients');
          setFileErrors({ ...fileErrors, clientsError: "" });
        } else {
          setFileErrors({ ...fileErrors, clientsError: "File type should not other than .svg, .png, .jpg or .jpeg." })
        }
        break;
      }

      case name.includes("testimonial-"): {
        let tempArr = [...testimonials];
        let key = name.split('-')[1];
        let index = tempArr.findIndex(item => {
          return item.id === id;
        })
        tempArr[index][key] = value;
        setTestimonials(tempArr);
        break;
      }
      case name.includes("mordor-"): {
        let key = name.split('-')[1];
        let tempArr = [...mordorVsOthers];
        let index = tempArr[0].findIndex(item => {
          return item === key;
        })
        tempArr[1][index] = value;
        setMordorVsOthers(tempArr);
        break;
      }
      case name.includes('others-'): {
        let key = name.split('-')[1];
        let tempArr = [...mordorVsOthers];
        let index = tempArr[0].findIndex(item => {
          return item === key;
        })
        tempArr[2][index] = value;
        setMordorVsOthers(tempArr);
        break;
      }
      case name.includes("ei-"): {
        let key = name.split('-')[1];
        let tempObj = { ...expertInsights };
        if (event.target.files === null) {
          tempObj[key] = value;
          setExpertInsights(tempObj);
        } else {
          let file = Array.from(event.target.files)[0];
          if (key === "profileImg") {
            let errorFlag = !['image/svg+xml', 'image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
            if (errorFlag) {
              setFileErrors({ ...fileErrors, eiProfileImgError: "File type should not other than .svg, .png, .jpg or .jpeg." });
            } else {
              tempObj[key] = file;
              let newUrlsArray = await getMediaUrl([file], 'ei-profileImg');
              // setExpertInsights(tempObj);
              setFileErrors({ ...fileErrors, eiProfileImgError: "" });
            }
          } else if (key === "image") {
            let errorFlag = !['image/svg+xml', 'image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
            if (errorFlag) {
              setFileErrors({ ...fileErrors, eiImageError: "File type should not other than .svg, .png, .jpg or .jpeg." });
            } else {
              tempObj[key] = file;
              let newUrlsArray = await getMediaUrl([file], 'ei-image');
              // setExpertInsights(tempObj);
              setFileErrors({ ...fileErrors, eiImageError: "" });
            }
          }
        }
        break;
      }
      case name.includes("meta"): {
           let  key = name.split("-")[1];
           let tempObj = {...meta};
           tempObj[key] = value;
            setMeta(tempObj);
        break;
      }
      // case name.includes("sticky_form"):
      case name.includes("testimonial_bottom"): {
        if (!relatedTags.includes(value)) {
          let tempArr = [...relatedTags];
          tempArr.push(value);
          setRelatedTags(tempArr);
        } else if (relatedTags.includes(value)) {
          let tempArr = [...relatedTags];
          tempArr = tempArr.filter(item => {
            return (item !== value);
          })
          setRelatedTags(tempArr);
        }
        break;
      }
    }
  }


// Form Functions
  let closeModal = (e)=>{
    e.preventDefault();
    props.set(false);
  }

  let mapDraftDataOverForm =()=>{
    let {draft}= props;
    if(props.hubId) setHubId(props.hubId); 
    if(draft.title) setHubName(draft.title);
    if(draft.slug) setSlug(draft.slug);
    if(draft.dataValues && draft.dataValues.length) setDataValues([...draft.dataValues]);
    if(draft.ourClients){
      setClients(draft.ourClients);
    }
    if(draft.testimonial && draft.testimonial.length){
      let tempData = [];
      draft.testimonial.map((item, i)=>{
        tempData.push({text: item.text, author: item.author ,id :`${Date.now()}${i}`});
      })
      setTestimonials(tempData);
    }

    if(draft.ourSegmentalCoverage){
      let tempObj = {}
      for(let key in draft.ourSegmentalCoverage){
        let value = [];
        draft.ourSegmentalCoverage[key].map((item , i)=>{
          value.push({...item, id:`${Date.now()}${i}`})
        })
        tempObj[key] =value;
      }
      setSegmentalCoverage(tempObj);
    }
    if(draft.ourSolutions){
      if(draft.ourSolutions.customizedSolution){
        let tempObj = {
          image: "",
          mobImage: ""
        }
         for(let key in draft.ourSolutions.customizedSolution){
          tempObj[key] = draft.ourSolutions.customizedSolution[key];
         }
        setCustomizedSolution(tempObj);
      }
      if(draft.ourSolutions.reportsAndDatasets){
         let tempObj= {
          image: "",
          mp4_url: "",
          mp4_url1: "",
          desc: [],
         }
         for(let key in draft.ourSolutions.reportsAndDatasets){
          if(key === 'desc'){
             let tempArr =[];
             draft.ourSolutions.reportsAndDatasets[key].map((item, i)=>{
              tempArr.push({...item, id:`${Date.now()}${i}`});
             })
             tempObj[key] = tempArr;
          }else{
            tempObj[key] = draft.ourSolutions.reportsAndDatasets[key];
          }
         }
        setReportsAndDatasets(tempObj);
      }
      if(draft.ourSolutions.reportsBundles){
        let tempObj = {
          image:"",
          desc:[
            {},
            {},
            {
            title: "Select the segments of your interest",
            bullets: [],
            }
          ]
      
        }
         

        for(let key in draft.ourSolutions.reportsBundles){
          if(key === 'desc'){
            draft.ourSolutions.reportsBundles[key].map((item, i)=>{
              if(item.title.includes('segment')){

                tempObj['desc'] =[{}, {}, { title: item.title,bullets: [...item.bullets],}]
              }else if(item.title.includes('regions')){

                let arr =[];
                item.bullets && item.bullets.length && item.bullets.map((elem, i)=>{
                    arr.push({region: elem.region,countries:elem.countries, id:`${Date.now()}${i}` })
                })
                setSet(arr);
              }
            })
          }else{
            tempObj[key] = draft.ourSolutions.reportsBundles[key];
          }
          
         }

         setReportsBundles(JSON.parse(JSON.stringify(tempObj)));
      }

    }
    if(draft.ourResearchMethodology){
       if(draft.ourResearchMethodology.data && draft.ourResearchMethodology.data.length ){
         let tempArr =[];
         let tempText ="";
         draft.ourResearchMethodology.data.map((item, i)=>{
           tempArr.push({
            bullets:item.bullets,
            title: item.title,
            id: `${Date.now()}${i}`
           })
         })
         if(draft.ourResearchMethodology.hasOwnProperty('text')){
           tempText = draft.ourResearchMethodology.text;
         }
        setResearchMethodology({text:tempText ,data:tempArr })
       }
    }
    if(draft.mordorIntelligenceVsOthers && draft.mordorIntelligenceVsOthers.length){
      let tempArr = [
        [
          "Research Approach",
          "Reporting",
          "Datasheets For Your Analysis",
          "Customization",
          "Query Resolution",
          "Analyst Support",
          "Usage Terms",
          "Flexible Offerings"
        ],
        [
          "True Bottom-Up approach",
          "Succinct",
          "Data-sheets complimentary",
          "Up to 20% at no additional cost",
          "<24 Business Hours",
          "Unlimited for 6 months",
          "100% refund if you do not like our reports",
          "Buy just the Data, data + report, bundles of reports, or consolidated dataset for the industry"
        ],
        [
          "Mostly top-down",
          "Verbose",
          "Data-sheets chargeable",
          "At additional cost",
          "Long turnaround times",
          "Limited",
          "Limited or no refunds",
          "Inflexible Offerings consisting of only reports"
        ]
      ];
      tempArr = draft.mordorIntelligenceVsOthers;
      setMordorVsOthers(tempArr);
    }
    if(draft.expertInsights){
      let tempObj ={
        title: "",
        description: "",
        profileImg: "",
        name: "",
        role: "",
        industry: "",
        image: ""
      }
     
     for(let key in draft.expertInsights){
      tempObj[key] =  draft.expertInsights[key];
     } 

     setExpertInsights(tempObj);
    }
    if(draft.meta){
      let tempObj ={
        title: "",
        keywords: "",
        desc: ""
      }
      for(let key in draft.meta){
        tempObj[key] =  draft.meta[key];
       } 

       setMeta(tempObj)
    }
    if(draft.related_tags){
      setRelatedTags(draft.related_tags) 
    }
  };

  let submitDraft = (e)=>{
    e.preventDefault();

    let newTestimonial = testimonials.map(item=>{
      delete item.id;
      return item;
    })

    let newResearchMethodology ={
      text: researchMethodology.text,
      data: researchMethodology.data.map(item=>{
        delete item.id;
        return item;})
    }

    let newSegmentalCoverage = {};
      for(let key in segmentalCoverage ){
        let newSegment = segmentalCoverage[key].map(item=>{
           delete item.id;
            return item;
        })
        newSegmentalCoverage[key] = newSegment;
      }

      let newReportsAndDatasets ={...reportsAndDatasets};
      newReportsAndDatasets.desc = reportsAndDatasets.desc?.map(item=>{
         let tempItem = {...item}  
        delete tempItem.id
         return tempItem;
      })
  
      let newReportsBundels = {...JSON.parse(JSON.stringify(reportsBundles))}
      newReportsBundels.desc[0] = {
        title: "Select the regions of your interest ",
        bullets: [...set].map(item=>{
          delete item.id;
          return item;
        })
      }
let countriesArr = [];
       [...set].map(item=>{
        countriesArr.push(item.countries)
       })

       function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }
      
      var uniqueCountries = countriesArr.flat(1).filter(onlyUnique);
      newReportsBundels.desc[1] = {
        title: "Select the countries of your interest ",
        bullets: uniqueCountries,
      }


   let draftObj = {
      hub_id : hubId,
      data:{
      title: hubName,
      slug: slug,
      dataValues: dataValues,
      ourClients: clients,
      testimonial: newTestimonial,
      ourSegmentalCoverage: newSegmentalCoverage,
      ourSolutions: {
        reportsAndDatasets: newReportsAndDatasets,
        reportsBundles: newReportsBundels,
        customizedSolution: customizedSolution
      },
      ourResearchMethodology: newResearchMethodology,
      mordorIntelligenceVsOthers: mordorVsOthers,
      expertInsights: expertInsights,
      active: active,
      meta: meta,
      related_tags: relatedTags
    }
  }


    

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${base_url}/api/hub_landing_page/draft`,
      data :draftObj
      };

      axios(config)
      .then((response)=>{
        let args = {
          message: "Draft Updated Successfully", 
          description: response.data.message,
          duration: 2.5,
          placement: "topRight",
        };
        notification.success(args);
        setTimeout(()=>{props.set(false)}, 1000)
        window.location.reload();
       })
      .catch((error)=>{
        let args = {
          message:"Failed To Update Draft", 
          description: error,
          duration: 2,
          placement: "topRight",
        };
        notification.error(args);
        console.log(error);
       });
  }

  let imagePreviewModalHandle = (key)=>{
     setImgPreviewType(key);
    switch (true){
      case key === 'clients':{
        setImgArrToPreview([...clients]);
        break;
      }
      case key === 'ei-profileImg':{
        setImgArrToPreview([expertInsights.profileImg]);
        break;
      }
      case key === 'ei-image':{
        setImgArrToPreview([expertInsights.image]);
        break;
      }
      case key === 'cs-image':{
        setImgArrToPreview([customizedSolution.image]);
        break;
      }
      case key === 'cs-mobImage':{
        setImgArrToPreview([customizedSolution.mobImage]);
        break;
      }
      case key === 'rb-image':{
        setImgArrToPreview([reportsBundles.image]);
        break;
      }
      case key === 'rd-image':{
        setImgArrToPreview([reportsAndDatasets.image]);
        break;
      }
      case key === 'rd-mp4_url':{
        setImgArrToPreview([reportsAndDatasets.mp4_url]);
        break;
      }
      case key === 'rd-mp4_url1':{
        setImgArrToPreview([reportsAndDatasets.mp4_url1]);
        break;
      }
    }

    setIsImgModalOpen(true);
  }

  let deleteImageUrl=(media)=>{
    if(imgPreviewType === 'clients'){
      let localArr = [...clients];
      localArr = localArr.filter(item=>{
        return item != media;
      })
      setImgArrToPreview(localArr);
      setClients(localArr);
      
    }

  }

//Instruction Images Config
  let imageCongif = {
    "hubName": hubNameImage,
    "Data Points":dataPointsImage,
    "Industry Reports":industryReportsImage,
    "Market Segments":marketSegmentsImage,
    "Companies With Market Shares":companiesWithMarketSharesImage,
    "clientsLogos":clientsLogosImage,
    "testimonialAuthor":testimonialAuthorImage,
    "testimonialText":testimonialTextImage,
    "segmentName":segmentNameImage,
    "segmentCategoryTitle":segmentCategoryTitleImage,
    "segmentCategoryBullets":segmentCategoryBulletsImage,
    "expertInsightsTitle":expertInsightsTitleImage,
    "expertInsightsDescription":expertInsightsDescriptionImage,
    "expertInsightsProfile":expertInsightsProfileImage,
    "expertInsightsGraph":expertInsightsGraphImage,
    "expertInsightsName":expertInsightsNameImage,
    "expertInsightsRole":expertInsightsRoleImage,
    "expertInsightsIndustry":expertInsightsIndustryImage,
    "mordorVsOthers":mordorVsOthersImage,
    "researchMethodologyText":researchMethodologyTextImage,
    "researchMethodologyTitle":researchMethodologyTitleImage,
    "researchMethodologyBullets":researchMethodologyBulletsImage,
    "customizedSolutionImage":customizedSolutionImage,
    "customizedSolutionMobileImage":customizedSolutionMobileImage,
    "reportImage":reportImageImage,
    "reportVideo":reportVideoImage,
    "sampleVideo":sampleVideoImage,
    "rdTitle":rdTitleImage,
    "rdBullets": rdBulletsImage,
    "rbImage":rbImageImage,
    "rbSegments":rbSegmentsImage,
    "rbRegions":rbRegionsImage,
    "rbCountries": rbCountriesImage
  }

  return (
    <>
    <Form>
    <CloseModal onClick={closeModal}>
            X
    </CloseModal>

      <Header>Hubs Landing Page Data Update</Header>

      {/* Hub Information */}
      <Fieldset>
        <Legend>Hub Information</Legend>

        <Separator>
          <Label htmlFor="hubName">Hub Name</Label>
          <Input id="hubName" name="hubName" value={hubName} onChange={handleInputs} />
          <Instruction>
            <img src={infoIcon} alt="info" name="hubName" onClick={handleInfo}/>
          </Instruction>
        </Separator>

        <Separator>
          <Label htmlFor='slug'>Slug</Label>
          <Input id="slug" name="slug" value={slug} readOnly onFocus={handleSlug} />
          <Instruction>Slug is autogenerated (Focus on Input to generate Slug).</Instruction>
        </Separator>
      </Fieldset>

      {/* Data Values */}
      <Fieldset>
        <Legend>Data Values</Legend>
        {
          dataValues.map(item => {
            return <Separator>
              <Label htmlFor={`dv-${item.title}`}>{item.title}</Label>
              <Input id={`dv-${item.title}`} name={`dv-${item.title}`} type="number" value={item.data} onChange={handleInputs} />
              <Instruction>
              <img src={infoIcon} alt="info" name={item.title} onClick={handleInfo}/>
              </Instruction>
            </Separator>
          })
        }
      </Fieldset>

      {/* Clients & Testimonials */}
      <Fieldset>
        <Legend>Clients & Testimonials</Legend>

        <Separator>
          <Label htmlFor='clientsLogos'>Clients Logos</Label>
          <Input id="clientsLogos" name="clientsLogos" type="file" multiple onChange={handleInputs} />
          {
            fileErrors.clientsError.length ? <Error>{fileErrors.clientsError}</Error> : <Instruction>
            <img src={infoIcon} alt="info" name="clientsLogos" onClick={handleInfo}/>
          </Instruction>
          }
          <Instruction>
            Width:Height should be 162:48 in pixals
          </Instruction>
          <Instruction>
            <Tooltip title={'See Previously Uploaded Image(s)'}>
            <img src={viewIcon} alt="viewImages" name="viewIcon"  onClick={()=>imagePreviewModalHandle('clients') }/>
            </Tooltip>
          </Instruction>
        </Separator>

        <Separator>
          <Label>Testimonials</Label>
          <AddButton onClick={addTestimonial}>Add Testimonial</AddButton>
        </Separator>

        {
          testimonials.map(testimonial => {
            return <Indentor >
              <Separator>
                <Label htmlFor='testimonial-author'>Author</Label>
                <Input id="testimonial-author" name="testimonial-author" value={testimonial.author} onChange={(event) => handleInputs(event, testimonial.id)} />
                <Instruction>
            <img src={infoIcon} alt="info" name="testimonialAuthor" onClick={handleInfo}/>
          </Instruction>
              </Separator>

              <Separator>
                <Label htmlFor='testimonial-text'>Text</Label>
                <TextArea id="testimonial-text" name="testimonial-text" value={testimonial.text} onChange={(event) => handleInputs(event, testimonial.id)}></TextArea>
                <Instruction>
               <img src={infoIcon} alt="info" name="testimonialText" onClick={handleInfo}/>
               </Instruction>
              </Separator>
              <DeleteButton onClick={(event) => deleteTestimonial(event, testimonial.id)}>Delete Testimonial</DeleteButton>
            </Indentor>
          })

        }
      </Fieldset>

      {/* Segmental Coverage */}
      <Fieldset>
        <Legend> Segmental Coverage</Legend>
        <Separator>
          <Input id="segment" name="segment" placeholder='Enter Segment' value={segment} onChange={handleSegmentalInputs} />
          <AddButton onClick={addSegment}>Add Segment</AddButton>
          <Instruction>
               <img src={infoIcon} alt="info" name="segmentName" onClick={handleInfo}/>
               </Instruction>
        </Separator>
        {renderSegment(segmentalCoverage)}
      </Fieldset>

      {/* Solutions */}
      <Fieldset>
        <Legend>Solutions</Legend>
        <Legend>Reports And Datasets</Legend>
        <Indentor>
          <Separator>
            <Label htmlFor='rd-image'>Report Image</Label>
            <Input id='rd-image' name='rd-image' type="file" onChange={handleSolutionsInputs} />
            {
              fileErrors.rdReportImageError.length ? <Error>{fileErrors.rdReportImageError}</Error> :                 <Instruction>
              <img src={infoIcon} alt="info" name="reportImage" onClick={handleInfo}/>
              </Instruction>
            }
           <Instruction>
           Width:Height should be 584:324 in pixals
          </Instruction>
            <Instruction>
            <Tooltip title={'See Previously Uploaded Image'}>
            <img src={viewIcon} alt="viewImages" name="viewIcon"  onClick={()=>imagePreviewModalHandle('rd-image') }/>
            </Tooltip>
          </Instruction>
          </Separator>

          <Separator>
            <Label htmlFor='rd-mp4_url'>Sample Video</Label>
            <Input id="rd-mp4_url" name="rd-mp4_url" type="file" onChange={handleSolutionsInputs} />
            {
              fileErrors.rdReportVideoError.length ? <Error>{fileErrors.rdReportVideoError}</Error> :                 <Instruction>
              <img src={infoIcon} alt="info" name="reportVideo" onClick={handleInfo}/>
              </Instruction>
            }
          <Instruction>
           Width:Height should be 584:324 in pixals
          </Instruction>
            <Instruction>
            <Tooltip title={'See Previously Uploaded Video'}>
            <img src={viewIcon} alt="viewImages" name="viewIcon"  onClick={()=>imagePreviewModalHandle('rd-mp4_url') }/>
            </Tooltip>
          </Instruction>
          </Separator>

          {/* <Separator>
            <Label htmlFor='rd-mp4_url1'>Sample Video</Label>
            <Input id="rd-mp4_url1" name="rd-mp4_url1" type="file" onChange={handleSolutionsInputs} />
            {
              fileErrors.rdSampleVideoError.length ? <Error>{fileErrors.rdSampleVideoError}</Error> :                 <Instruction>
              <img src={infoIcon} alt="info" name="sampleVideo" onClick={handleInfo}/>
              </Instruction>
            }
            <Instruction>
            <Tooltip title={'See Previously Uploaded Video'}>
            <img src={viewIcon} alt="viewImages" name="viewIcon"  onClick={()=>imagePreviewModalHandle('rd-mp4_url1') }/>
            </Tooltip>
          </Instruction>
          </Separator> */}

          <Separator>
            <Label>Description</Label>
            <AddButton onClick={addDescription}>Add Description</AddButton>
          </Separator>

          {
            reportsAndDatasets.desc.map(item => {
              return <Indentor>
                <DeleteButton onClick={(event) => deleteDescription(event, item.id)}>Delete Description</DeleteButton>
                <Separator>
                  <Label htmlFor={`rd-title-${item.id}`}>Title</Label>
                  <Input id={`rd-title-${item.id}`} name={`rd-title-${item.id}`} value={item.title} onChange={(event) => handleSolutionsInputs(event, item.id)} />
                  <Instruction>
                  <img src={infoIcon} alt="info" name="rdTitle" onClick={handleInfo}/>
                  </Instruction>
                </Separator>

                <Separator>
                  <Label htmlFor={`rd-bullets-${item.id}`}>Bullets</Label>
                  <TextArea id={`rd-bullets-${item.id}`} name={`rd-bullets-${item.id}`} value={item.bullets.join(';')} onChange={(event) => handleSolutionsInputs(event, item.id)}></TextArea>
                  <Instruction>
                  <img src={infoIcon} alt="info" name="rdBullets" onClick={handleInfo}/>
                  </Instruction>
                  <Instruction>Separate Each Segment By Semicolon(;).</Instruction>
                </Separator>
              </Indentor>
            })
          }
        </Indentor>

        <Legend>Reports Bundels</Legend>
        <Indentor>
          <Separator>
            {/* <Label htmlFor='rb-image'>Image</Label>
            <Input id="rb-image" name="rb-image" type="file" onChange={handleSolutionsInputs}></Input>
            {
              fileErrors.rbImageError.length ? <Error>{fileErrors.rbImageError}</Error> :                 <Instruction>
              <img src={infoIcon} alt="info" name="rbImage" onClick={handleInfo}/>
              </Instruction>
            } 
           <Instruction>
            <Tooltip title={'See Previously Uploaded Image'}>
            <img src={viewIcon} alt="viewImages" name="viewIcon"  onClick={()=>imagePreviewModalHandle('rb-image') }/>
            </Tooltip>
          </Instruction> */}

          </Separator>

          <Separator style={{ flexDirection: "column" }}>
            <Label>Description</Label>
            <Indentor>
            <Separator >
              <Label htmlFor='sg-segments'>Input The Segments of Your Interest</Label>
              <TextArea id="sg-segments" name="sg-segments" value={reportsBundles.desc[2].bullets.join(';')} onChange={handleSolutionsInputs}></TextArea>
              <Instruction>
               <img src={infoIcon} alt="info" name="rbSegments" onClick={handleInfo}/>
               </Instruction>
              <Instruction>Separate Each Segment By Semicolon(;).</Instruction>
            </Separator>
            </Indentor>

            <Separator style={{ flexDirection: "column" }} >
           <Label>Add Region Countries Set</Label> 
            <Indentor >
              <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start"  ,width:"fit-content"}}>
            <Separator style={{ flexDirection: "column", marginRight:"20px" }}>
             
             <Separator> 
              <Label>Select The Regions of Your Interest </Label>
              <Instruction>
               <img src={infoIcon} alt="info" name="rbRegions" onClick={handleInfo}/>
               </Instruction>
             </Separator>
              {SingleSelect(regions, selectedRegion, setSelectedRegion, sstoggle, setSsToggle)}
            </Separator>

            <Separator style={{ flexDirection: "column" ,marginRight:"20px"}}>
            <Separator>
            <Label>Select The Countries of Your Interest</Label>
            <Instruction>
               <img src={infoIcon} alt="info" name="rbCountries" onClick={handleInfo}/>
            </Instruction>
            </Separator>
              {MultipleSelect(regionCountryConfig[selectedRegion], selectedCountries, setSelectedCountries, mstoggle, setMsToggle)}
            </Separator>
            <Separator>
            <AddButton onClick={addSet}>Add</AddButton>
            <Instruction>Atleast Select Region To add.</Instruction>
            </Separator>
            </div>

            {
                  set.map(item=>{
                    return(
                       <Indentor>
                         <p><span style={{color:"#47cccf", fontWeight:"bold", marginRight:"10px"}}>Region:  </span> {item.region}</p>
                         <Ul>
                         <span style={{color:"#47cccf", fontWeight:"bold",marginRight:"10px"}}>Countries:  </span>
                          {item.countries.map((c, i)=>{
                            return  <Li >{i <(item.countries.length-1) ? c+',' : c+'.'}</Li>
                          })}
                         </Ul>
                         <DeleteButton onClick={(event)=>deleteSet(event, item.id)}>Delete</DeleteButton>
                       </Indentor>
                    )
                  })
            }
          </Indentor>
          </Separator>
            </Separator>
            </Indentor>

        
        <Legend>Customized Solution</Legend>
        <Indentor>
          <Separator>
            <Label htmlFor='cs-image'>Desktop Image</Label>
            <Input id="cs-image" name="cs-image" type="file" onChange={handleSolutionsInputs} />
            {
              fileErrors.csImageError.length ? <Error>{fileErrors.csImageError}</Error> :               <Instruction>
              <img src={infoIcon} alt="info" name="customizedSolutionImage" onClick={handleInfo}/>
              </Instruction>
            }
            <Instruction>
              Width:Height should be 872:444 in pixals
            </Instruction>
            <Instruction>
            <Tooltip title={'See Previously Uploaded Image'}>
            <img src={viewIcon} alt="viewImages" name="viewIcon"  onClick={()=>imagePreviewModalHandle('cs-image') }/>
            </Tooltip>
          </Instruction>
          </Separator>

          <Separator>
            <Label htmlFor='cs-mobImage'>Mobile Image</Label>
            <Input id="cs-mobImage" name="cs-mobImage" type="file" onChange={handleSolutionsInputs} />
            {
              fileErrors.csMobImageError.length ? <Error>{fileErrors.csMobImageError}</Error> :               <Instruction>
              <img src={infoIcon} alt="info" name="customizedSolutionMobileImage" onClick={handleInfo}/>
              </Instruction>
            }
            <Instruction>
               Width:Height should be 440:440 in pixals
            </Instruction>
            <Instruction>
            <Tooltip title={'See Previously Uploaded Image'}>
            <img src={viewIcon} alt="viewImages" name="viewIcon"  onClick={()=>imagePreviewModalHandle('cs-mobImage') }/>
            </Tooltip>
          </Instruction>
          </Separator>
        </Indentor>
      </Fieldset>

      {/* Research Methodology */}
      <Fieldset>
        <Legend>Research Methodology</Legend>

        <Separator>
          <Label htmlFor='rm-text'>Text </Label>
          <Input id="rm-text" name="rm-text" value={researchMethodology.text} onChange={handleMethodologyInputs} />
          <Instruction>
               <img src={infoIcon} alt="info" name="researchMethodologyText" onClick={handleInfo}/>
          </Instruction>
        </Separator>

        <Separator>
          <Label>Data </Label>
          <AddButton onClick={addData}>Add Data</AddButton>
        </Separator>
        {
          researchMethodology.data.map((item,index) => {
            return <Indentor>
              <DeleteButton onClick={(event) => deleteData(event, item.id)}>Delete Data</DeleteButton>
              <Separator>
                <Label htmlFor={`rm-title-${item.index}`}>Title </Label>
                <Input id={`rm-title-${item.index}`} name={`rm-title-${item.index}`} value={item.title} onChange={(event) => handleMethodologyInputs(event, item.id)} />
                <Instruction>
               <img src={infoIcon} alt="info" name="researchMethodologyTitle" onClick={handleInfo}/>
               </Instruction>
              </Separator>

              <Separator>
                <Label htmlFor={`rm-bullets-${item.index}`}>Bullets </Label>
                <TextArea id={`rm-bullets-${item.index}`} name={`rm-bullets-${item.index}`} value={item.bullets.join(";")} onChange={(event) => handleMethodologyInputs(event, item.id)}></TextArea>
                <Instruction>
               <img src={infoIcon} alt="info" name="researchMethodologyBullets" onClick={handleInfo}/>
               </Instruction>
                <Instruction>Separate The Bullets Using semicolon(;)</Instruction>
              </Separator>
            </Indentor>
          })
        }
      </Fieldset>

      {/* Mordor Vs Others */}
      <Fieldset>
        <Legend> Mordor Intelligence Vs Other Companies</Legend>

        <Separator>
          <Label style={{ fontWeight: "600", width: "250px" }}>Keys</Label>
          <Label style={{ fontWeight: "600", width: "320px" }}>Mordor Intelligence</Label>
          <Label style={{ fontWeight: "600", width: "300px" }}>Other Companies</Label>
          <Instruction>
               <img src={infoIcon} alt="info" name="mordorVsOthers" onClick={handleInfo}/>
          </Instruction>
        </Separator>

        {mordorVsOthers[0].map((item, index) => {
          return <Separator>
            <Label >{item}</Label>
            <Input id={`mordor-${item}`} name={`mordor-${item}`} value={mordorVsOthers[1][index]} onChange={handleInputs} />
            <Input id={`others-${item}`} name={`others-${item}`} value={mordorVsOthers[2][index]} onChange={handleInputs} />
          </Separator>
        })}

      </Fieldset>

      {/* Expert Insights */}
      <Fieldset>
        <Legend>Expert Insights</Legend>

        <Separator>
          <Label htmlFor='ei-title'>Title</Label>
          <Input id="ei-title" name="ei-title" value={expertInsights.title} onChange={handleInputs} />
          <Instruction>
               <img src={infoIcon} alt="info" name="expertInsightsTitle" onClick={handleInfo}/>
         </Instruction>
        </Separator>
        <Separator>
          <Label htmlFor='ei-description'>Description</Label>
          <Input id="ei-description" name="ei-description" value={expertInsights.description} onChange={handleInputs} />
          <Instruction>
               <img src={infoIcon} alt="info" name="expertInsightsDescription" onClick={handleInfo}/>
         </Instruction>
        </Separator>
        <Separator>
          <Label htmlFor='ei-profileImg'>Profile Image</Label>
          <Input id="ei-profileImg" name="ei-profileImg" type="file" onChange={handleInputs} />
          {
            fileErrors.eiProfileImgError.length ? <Error>{fileErrors.eiProfileImgError}</Error> :           <Instruction>
            <img src={infoIcon} alt="info" name="expertInsightsProfile" onClick={handleInfo}/>
      </Instruction>
          }
           <Instruction>
           Width:Height should be 140: 166 in pixals
          </Instruction>
            <Instruction>
            <Tooltip title={'See Previously Uploaded Image'}>
            <img src={viewIcon} alt="viewImages" name="viewIcon"  onClick={()=>imagePreviewModalHandle('ei-profileImg') }/>
            </Tooltip>
          </Instruction>
        </Separator>
        <Separator>
          <Label htmlFor='ei-name'>Name</Label>
          <Input id="ei-name" name="ei-name" value={expertInsights.name} onChange={handleInputs} />
          <Instruction>
               <img src={infoIcon} alt="info" name="expertInsightsName" onClick={handleInfo}/>
         </Instruction>
        </Separator>
        <Separator>
          <Label htmlFor='ei-role'>Role</Label>
          <Input id="ei-role" name="ei-role" value={expertInsights.role} onChange={handleInputs} />
          <Instruction>
               <img src={infoIcon} alt="info" name="expertInsightsRole" onClick={handleInfo}/>
         </Instruction>
        </Separator>
        <Separator>
          <Label htmlFor='ei-industry'>Industry</Label>
          <Input id="ei-industry" name="ei-industry" value={expertInsights.industry} onChange={handleInputs} />
          <Instruction>
               <img src={infoIcon} alt="info" name="expertInsightsIndustry" onClick={handleInfo}/>
         </Instruction>
        </Separator>
        <Separator>
          <Label htmlFor='ei-image'>Bubble Graph Image</Label>
          <Input id="ei-image" name="ei-image" type="file" onChange={handleInputs} />
          {
            fileErrors.eiImageError.length ? <Error>{fileErrors.eiImageError}</Error> : <Instruction>
            <img src={infoIcon} alt="info" name="expertInsightsGraph" onClick={handleInfo}/>
           </Instruction>
          }
          <Instruction>
          Width:Height should be 460:440 in pixals
          </Instruction>
          <Instruction>
            <Tooltip title={'See Previously Uploaded Image'}>
            <img src={viewIcon} alt="viewImages" name="viewIcon"  onClick={()=>imagePreviewModalHandle('ei-image') }/>
            </Tooltip>
          </Instruction>
     
        </Separator>

      </Fieldset>

      {/*  Meta  */}
      {/* <Fieldset>
        <Legend>Meta</Legend>

        <Separator>
          <Label htmlFor='meta-title'>Title</Label>
          <Input id="meta-title" name="meta-title" value={meta.title} onChange={handleInputs}></Input>
          <Instruction>Add Meta Title Here.</Instruction>
        </Separator>

        <Separator>
          <Label htmlFor='meta-keywords'>Keywords</Label>
          <Input id="meta-keywords" name="meta-keywords" value={meta.keywords} onChange={handleInputs}></Input>
          <Instruction>Add Meta Keywords Here.</Instruction>
        </Separator>

        <Separator>
          <Label htmlFor='meta-desc'>Description</Label>
          <Input id="meta-desc" name="meta-desc" value={meta.desc} onChange={handleInputs}></Input>
          <Instruction>Add Meta Description Here.</Instruction>
        </Separator>
      </Fieldset> */}

      {/* Related Tags */}
      <Fieldset>
        <Legend>Realted Tags</Legend>
        <Label>Select Tags</Label>

        {/* <Separator style={{ marginTop: "10px" }}>
          <Input type="checkbox" id="sticky_form" name="sticky_form" value="sticky_form" checked={relatedTags.includes("sticky_form")} onChange={handleInputs} />
          <Label htmlFor="sticky_form">Sticky Form</Label>
        </Separator> */}

        <Separator style={{ marginTop: "10px" }}>
          <Input type="checkbox" id="testimonial_bottom" name="testimonial_bottom" value="testimonial_bottom" checked={relatedTags.includes("testimonial_bottom")} onChange={handleInputs} />
          <Label htmlFor="testimonial_bottom">Testimonial Bottom</Label>
        </Separator>
       </Fieldset>

      {/* Submit Form */}
      <Fieldset style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
    
      <AddButton style={{height:"2.5rem", padding: "10px 25px", fontSize:"18px"}}  onClick={submitDraft}>Save Draft</AddButton>
      
      </Fieldset>

    </Form>

    {
     <Modal
        bodyStyle={{minHeight: "60vh"}}
        visible={info.flag}
        onCancel={() => setInfo({flag:false, name:""})}
        footer={null}>
      <div>
         {instructionConfig.filter(item=>{
              return item.name === info.name;
         }).map(item=>{
          return <div>
               <h2>Instruction Related To Respective Input</h2>
                <div style={{width: "100%", margin:"0 auto"}}>
                <img style={{width:"100%", objectFit:"cover"}} src={imageCongif[item.name]} alt="intruction-image" />
                <Instruction style={{marginLeft:"0",fontSize:"22px"}}>
                   {item.message}
                </Instruction>
                </div>
            </div>
         })}
      </div>
    </Modal>
    }


    {
      <Modal
      bodyStyle={{minHeight: "60vh"}}
      visible={isImgModalOpen}
      onCancel={() => setIsImgModalOpen(false)}
      footer={null}>
      <div>
        <h2>Previously Uploaded Image(s)</h2>
        <div style={{display:'flex', justifyContent:"space-between", alignItems:"center", flexWrap:"wrap"}}>
          {imgArrToPreview &&  imgArrToPreview.length && imgArrToPreview.map(media=>{
           if(media.split('.')[[media.split('.').length-1]] === 'mp4'){
            return (<div style={{width: '180px', margin:'20px', position:'relative'}}> 
              {imgArrToPreview.length >= 2 && <DeleteImageButton onClick={()=>deleteImageUrl(media)}>X</DeleteImageButton> }
            <video  style={{width:"100%", objectFit:"cover"}} controls>
               <source src={media} type="video/mp4" />
               Your browser does not support the video tag.
             </video>
            </div>)
           }else{
            return (
            <div style={{width: '180px', margin:'20px', position:'relative'}}>
               {imgArrToPreview.length >= 2 && <DeleteImageButton onClick={()=>deleteImageUrl(media)}>X</DeleteImageButton> }
               <img style={{width:"100%", objectFit:"cover"}} src={media} alt="image" /> 
            </div>)
           }
          })}
        
        </div>
      </div>
      </Modal>
    }
    </>
  )
}
