import styled from "styled-components";

export const MLCCVCWrapper = styled.div`
  .section_data1 {
    top: 68%;
    width: 40%;
    max-width: 200px;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    top: 64%;
    width: 21%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;
  }
  .section_data3 {
    top: 63%;
    width: 21%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list1 {
    left: 3%;
  }


  .section2_list {
    left: 27.5%;
  }

  .section3_list1 {
    left: 52%;
  }
  .section4_list1 {
    left: 76.5%;
  }
 
`;
