import React from "react";
import Adhesives_and_Sealants_VC from "./Adhesives_and_Sealants";
import Aviation_VC from "./Aviation";
import ArchitecturalCoatingVC from "./Architectural_Coating";
import Fertilizer_VC from "./Fertilizer";
import LubricantVC from "./Lubricant";
import Meats_and_meat_substitutes_VC from "./Meat_and_Meat_Substitutes";
import ProteinsVC from "./Proteins";
import RoadFreight_VC from "./Road_Freight";
import Seeds_VC from "./Seeds";
import Vehicles_VC from "./Vehicles";
import DataCenter_VC from "./Data_Center";
import Engineering_Plastics_VC from "./Engineering_Plastics";
import Feed_Additive_VC from "./Feed_Additive";
import eBikes_VC from "./E_Bikes";
import Agricultural_Biological_VC from "./Agricultural_Biological";
import Commercial_Aircraft_Cabin_VC from "./Commercial_Aircraft_Cabin";
import Food_Services_VC from "./Foodservice";
import Dairy_and_Dairy_Alternative_VC from "./Dairy_and_Dairy_Alternative";
import Freight_and_Logistics_VC from "./Freight_and_Logistics";
import CEP_VC from "./CEP";
import Satellite_VC from "./Satellite";
import EV_Battery_Pack_VC from "./EV_Battery_Pack";
import Pet_Food_VC from "./Pet_Food";
import Confectionery_VC from "./Confectionery"
import Crop_Protection_Chemicals from "./Crop_Protection_Chemicals";
import MLCC from './MLCC'
import Concreate_construction_chemicals from './Concreate_construction_chemicals';
import LED from './LED'

const componentMapping = {
  adhesives_and_sealants: Adhesives_and_Sealants_VC,
  architectural_coatings: ArchitecturalCoatingVC,
  aviation: Aviation_VC,
  fertilizer: Fertilizer_VC,
  lubricants: LubricantVC,
  meats_and_meat_substitutes: Meats_and_meat_substitutes_VC,
  proteins: ProteinsVC,
  road_freight: RoadFreight_VC,
  seeds: Seeds_VC,
  vehicles: Vehicles_VC,
  data_center: DataCenter_VC,
  engineering_plastics: Engineering_Plastics_VC,
  feed_additive: Feed_Additive_VC,
  e_bikes: eBikes_VC,
  agricultural_biologicals: Agricultural_Biological_VC,
  commercial_aircraft_cabin: Commercial_Aircraft_Cabin_VC,
  foodservice: Food_Services_VC,
  dairy_and_dairy_alternative: Dairy_and_Dairy_Alternative_VC,
  freight_and_logistics: Freight_and_Logistics_VC,
  cep: CEP_VC,
  satellite: Satellite_VC,
  ev_battery_pack: EV_Battery_Pack_VC,
  pet_food: Pet_Food_VC,
  confectionery: Confectionery_VC,
  crop_protection_chemicals: Crop_Protection_Chemicals,
  mlcc: MLCC,
  "concrete,_mortars_and_construction_chemicals": Concreate_construction_chemicals,
  led:LED
};

class ValueChainHubs extends React.Component {
  render() {
    const Components = componentMapping[this.props.data.meta.hub_name];

    return (
      <div>
        {Components !== undefined ? (
          <Components
            fromInterface={this.props.fromInterface}
            data={this.props.data}
            template={this.props.template}
            template_title={this.props.template_title}
            edit_mode={this.props.edit_mode}
            draft_drawer_visible={this.props.draft_drawer_visible}
            published_drawer_visible={this.props.published_drawer_visible}
            handleSwitchchange={this.props.handleSwitchchange}
            slide_details={this.props.slide_details}
            slideNo={this.props.slideNo}
          />
        ) : (
          <h2> NO DATA </h2>
        )}
      </div>
    );
  }
}

export default ValueChainHubs;
