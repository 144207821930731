import axios from "axios";
import config from "../config/Api";
import { notification } from "antd";

export const setTocData = (data) => ({
  type: "GETTOCDATA",
  data,
});

export const setShowGraphVal = (data) => ({
  type: "SETSHOWGRAPHVAL",
  data,
});

export const setAuthstatus = (data) => ({
  type: "SETAUTH",
  data,
});

export const setSearchParams = (data) => ({
  type: "SET_SEARCH_PARAM",
  data,
});

export const setMarketActivityParams = (data) => ({
  type: "SET_MARKET_ACTIVITY_PARAM",
  data,
});

export const setHLPParams = (data) => ({
  type: "SET_HLP_PARAM",
  data,
});

export const setMasterMEParams = (data) => ({
  type: "SET_MASTER_MES_PARAM",
  data,
});

export const setDownloadReportsParams = (data) => ({
  type: "SET_DOWNLOAD_REPORTS_PARAM",
  data,
});

export const setSwitchState = (data) => {
  return {
    type: "SWITCH_STATE",
    data,
  };
};

export const setActiveTab = (data) => {
  return {
    type: "ACTIVE_TAB",
    data,
  };
};

export const setLinkCounter = (data) => ({
  type: "ANALYSIS_LINK_COUNTER",
  data,
});

export const setReportHeaderData = (data) => ({
  type: "REPORT_HEADER_DATA",
  data,
});

export const setPublishedDrawer = (data) => ({
  type: "SHOW_PUBLISHED_DRAWER",
  data,
});

export const setDraftDrawer = (data) => ({
  type: "SHOW_DRAFT_DRAWER",
  data,
});

export const setDraftVersions = (data) => {
  return {
    type: "SET_DRAFT_VERSIONS",
    data,
  };
};

export const setPublishedVersions = (data) => ({
  type: "SET_PUBLISHED_VERSIONS",
  data,
});

export const setDraftDataHeading = (data) => {
  return {
    type: "SET_DRAFT_DATA_HEADING",
    data,
  };
};

//Draft Data from Analysis
export const setDraftDataAnalysis = (data) => {
  return {
    type: "SET_DRAFT_DATA_ANALYSIS",
    data,
  };
};

//Draft Data from Sources
export const setDraftDataSources = (data) => {
  return {
    type: "SET_DRAFT_DATA_SOURCES",
    data,
  };
};

export const setUserData = (data) => ({
  type: "SET_USER_DATA",
  data,
});

export const setDraftStage = (data) => ({
  //SELECTED STAGE BY USER
  type: "SET_DRAFT_STAGE",
  data,
});

export const setDefaultDraftStageFromGetSpecificDataAndDraftVersions = (
  data
) => {
  return {
    type: "SET_DRAFT_STAGE_FROM_GET_SPECIFIC_DATA",
    data,
  };
};

export const setUserEditAccess = (data) => ({
  type: "SET_USER_EDIT_ACCESS",
  data,
});

export const setNoDraftsFound = (data) => {
  return {
    type: "SET_NO_DRAFTS_FOUND",
    data,
  };
};

export const setDraftVersionsLoading = (data) => ({
  type: "SET_DRAFT_VERSIONS_LOADING",
  data,
});

export const setInitialDraftVersionsLoading = (data) => ({
  type: "SET_INITIAL_DRAFT_VERSIONS_LOADING",
  data,
});

export const setSpecificDraftLoading = (data) => ({
  type: "SET_SPECIFIC_DRAFT_LOADING",
  data,
});

export const setPublishedVersionsLoading = (data) => ({
  type: "SET_PUBLISHED_VERSIONS_LOADING",
  data,
});

export const setSelectStageChangeLoader = (data) => ({
  type: "SET_SELECT_STAGE_CHANGE_LOADER",
  data,
});

export const setSwitchDisable = (data) => {
  return {
    type: "SET_DISABLE_SWITCH",
    data,
  };
};

export const setSelectedIndicatorHeading = (data) => {
  return {
    type: "SET_SELECTED_INDICATOR_HEADING",
    data,
  };
};

export const setIndicatorObjectToShow = (data) => {
  return {
    type: "SET_INDICATOR_OBJECT_TO_SHOW",
    data,
  };
};

export const setParentId = (data) => {
  return {
    type: "SET_PARENT_ID",
    data,
  };
};

export const setCacheId = (data) => {
  return {
    type: "SET_CACHE_ID",
    data,
  };
};

export const setTemplateType = (data) => {
  return {
    type: "SET_TEMPLATE_TYPE",
    data,
  };
};

export const setDraftId = (data) => {
  return {
    type: "SET_DRAFT_ID",
    data,
  };
};

export const setIndicatorDropdownOptions = (data) => {
  return {
    type: "SET_INDICATOR_DROPDOWN_OPTIONS",
    data,
  };
};

export const setCompanyProfileData = (data) => {
  return {
    type: "SET_COMPANY_PROFILE_DATA",
    data,
  };
};

export const setUpdateCompanyProfileData = (data) => {
  return {
    type: "SET_UPDATE_COMPANY_PROFILE_DATA",
    data,
  };
};

export const setCompanyProfileDimensions = (data) => {
  return {
    type: "SET_COMPANY_PROFILE_DIMENSIONS",
    data,
  };
};

export const setCompanyType = (data) => {
  return {
    type: "SET_COMPANY_TYPE",
    data,
  };
};

export const setFetchFulldata = (data) => {
  return {
    type: "SET_FETCH_FULL_DATA",
    data,
  };
};

export const setCharacterCountError = (data) => {
  return {
    type: "SET_CHARACTER_COUNT_ERROR",
    data,
  };
};

export const setExecSummaryData = (data) => {
  return {
    type: "SET_EXEC_SUMMARY_DATA",
    data,
  };
};

export const setSaveDraftLoader = (data) => {
  return {
    type: "SET_SAVE_DRAFT_LOADER",
    data,
  };
};

export const setDirectPublishLoader = (data) => {
  return {
    type: "SET_DIRECT_PUBLISH_LOADER",
    data,
  };
};

export const partialStateReset = (data) => {
  //except user login data and toc data
  return {
    type: "PARTIAL_STATE_RESET",
    data,
  };
};

export const setAnalysisEditorState = (data) => {
  return {
    type: "SET_ANALYSIS_EDITOR_STATE",
    data,
  };
};

export const setSourcesEditorState = (data) => {
  return {
    type: "SET_SOURCES_EDITOR_STATE",
    data,
  };
};

export const setAnalysisHeadingEditorState = (data) => {
  return {
    type: "SET_ANALYSIS_HEADING_EDITOR_STATE",
    data,
  };
};

export const setAnalysisHeadingEditorStateForPublished = (data) => {
  return {
    type: "SET_ANALYSIS_HEADING_EDITOR_STATE_FOR_PUBLISHED",
    data,
  };
};

export const setAnalysisHeadingEditorStateForDraft = (data) => {
  return {
    type: "SET_ANALYSIS_HEADING_EDITOR_STATE_FOR_DRAFT",
    data,
  };
};

export const setAnalysisCharCount = (data) => {
  return {
    type: "SET_ANALYSIS_CHAR_COUNT",
    data,
  };
};

export const resetState = () => ({
  type: "RESET_STATE",
});

export const getDrafts = (parent_id, type) => {
  return function (dispatch) {
    dispatch(setDraftVersionsLoading(true));
    let newType =
      type === "regulatoryFramework2" ? "regulatoryFramework" : type;
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/drafts?parent_id=${parent_id}&type=${newType}&list=true`,
    })
      .then((response) => {
        dispatch(setDraftVersionsLoading(false));
        if (response.status === 200 || response.status === 201) {
          dispatch(setDraftVersions(response.data));
          if (response.data.versions && !response.data.versions.length) {
            setNoDraftsFound(true);
            setDraftStage("NONE");
            setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
              response.data.stage ? response.data.stage : "INITIAL"
            );
          } else if (response.data.versions && response.data.versions.length) {
            setNoDraftsFound(false);
            setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
              response.data.stage ? response.data.stage : "INITIAL"
            );
            setDraftStage(
              response.data.stage ? response.data.stage : "INITIAL"
            );
          }
          dispatch(setUserEditAccess(response.data.user_edit_access));
        }
      })
      .catch((error) => {
        dispatch(setDraftVersionsLoading(false));
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }

        dispatch(setDraftVersions({}));
      });
  };
};

export const getPublishedVersions = (parent_id) => {
  return function (dispatch) {
    dispatch(setPublishedVersionsLoading(true));
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/publish?parent_id=${parent_id}`,
    })
      .then((response) => {
        dispatch(setPublishedVersionsLoading(false));
        if (response.status === 200 || response.status === 201) {
          dispatch(setPublishedVersions(response.data));
        }
      })
      .catch((error) => {
        dispatch(setPublishedVersionsLoading(false));
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
        dispatch(setPublishedVersions({}));
      });
  };
};

export const saveDraftApi = (data, fun) => {
  return function (dispatch) {
    dispatch(setSaveDraftLoader(true));
    let deepCloneData = JSON.parse(JSON.stringify(data));
    if (deepCloneData.type === "regulatoryFramework2") {
      deepCloneData.type = "regulatoryFramework";
    }
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/drafts`,
      data: deepCloneData,
    })
      .then((response) => {
        dispatch(setSaveDraftLoader(false));
        if (response.status === 200 || response.status === 201) {
          dispatch(setDraftId(null));
          dispatch(
            setAnalysisHeadingEditorState(response.data.response_data.heading)
          );
          dispatch(
            setAnalysisHeadingEditorStateForDraft(
              response.data.response_data.heading
            )
          );
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
        // dispatch(setSwitchState(false));
      })
      .catch((error) => {
        dispatch(setSaveDraftLoader(false));
        if (error.response) {
          let err;
          console.log(error, "error");
          if (
            error.response.data.message === "please send something in analysis!"
          ) {
            err = "You did not edit anything!";
          }
          notification.error({
            message: "Error",
            description: err,
          });
        }
      });
  };
};

export const directPublishApi = (data) => {
  return function (dispatch) {
    dispatch(setDirectPublishLoader(true));
    let deepCloneData = JSON.parse(JSON.stringify(data));
    if (deepCloneData.type === "regulatoryFramework2") {
      deepCloneData.type = "regulatoryFramework";
    }
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/directPublish`,
      data: deepCloneData,
    })
      .then((response) => {
        dispatch(setSwitchState(false));
        dispatch(setDirectPublishLoader(false));
        if (response.status === 200 || response.status === 201) {
          dispatch(
            setAnalysisHeadingEditorState(response.data.response_data.heading)
          );
          dispatch(
            setAnalysisHeadingEditorStateForPublished(
              response.data.response_data.heading
            )
          );
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch(setDirectPublishLoader(false));
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };
};

export const getSpecificDraftApi = (draft_id, parent_id, template_type) => {
  return function (dispatch) {
    dispatch(setDraftId(draft_id));
    dispatch(setSpecificDraftLoading(true));
    let newTemplateType =
      template_type === "regulatoryFramework2"
        ? "regulatoryFramework"
        : template_type;
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/drafts?parent_id=${parent_id}&type=${newTemplateType}&draftId=${draft_id}`,
    })
      .then((response) => {
        dispatch(setSpecificDraftLoading(false));
        if (response.status === 200 || response.status === 201) {
          dispatch(
            setDraftDataHeading(
              response.data.hasOwnProperty("heading")
                ? response.data.heading
                : ""
            )
          );
          if (
            !["companyProfile", "executive_summary"].includes(template_type)
          ) {
            dispatch(setDraftDataAnalysis(response.data.analysis));
            dispatch(setDraftDataSources(response.data.source));
            // dispatch(setDraftDataHeading(response.data.heading));
            dispatch(setAnalysisHeadingEditorState(""));
            dispatch(
              setAnalysisHeadingEditorStateForDraft(
                response.data.hasOwnProperty("heading")
                  ? response.data.heading
                  : ""
              )
            );
          }
          if (["executive_summary"].includes(template_type)) {
            dispatch(setExecSummaryData(response.data.blocks));
          }
        }
      })
      .catch((error) => {
        dispatch(setSpecificDraftLoading(false));
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };
};

export const getSpecificPublishedVersionApi = (publish_id, template_type) => {
  return function (dispatch) {
    dispatch(setSpecificDraftLoading(true));
    //get specific published version api
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/publish?publish_id=${publish_id}`,
    })
      .then((response) => {
        dispatch(setSpecificDraftLoading(false));
        if (response.status === 200 || response.status === 201) {
          dispatch(
            setDraftDataHeading(
              response.data.hasOwnProperty("heading")
                ? response.data.heading
                : ""
            )
          );

          if (
            !["companyProfile", "executive_summary"].includes(template_type)
          ) {
            dispatch(setDraftDataAnalysis(response.data.analysis));
            dispatch(setDraftDataSources(response.data.source));
            // dispatch(setDraftDataHeading(response.data.heading));
            dispatch(setAnalysisHeadingEditorState(""));
            dispatch(
              setAnalysisHeadingEditorStateForPublished(
                response.data.hasOwnProperty("heading")
                  ? response.data.heading
                  : ""
              )
            );
          }
          if (["executive_summary"].includes(template_type)) {
            dispatch(setExecSummaryData(response.data.blocks));
          }
        }
      })
      .catch((error) => {
        dispatch(setSpecificDraftLoading(false));
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };
};

export const setDimensions = (data) => ({
  type: "SET_DIMENSIONS",
  data,
});

export const setEditGraph = (data) => ({
  type: "SET_EDIT_GRAPH",
  data,
});

export const setGraphColumn = (data) => ({
  type: "SET_GRAPH_COLUMN",
  data,
});

export const setGraphData = (data) => ({
  type: "SET_GRAPH_DATA",
  data,
});

export const setEditGraphConfig = (data) => ({
  type: "SET_EDIT_GRAPH_CONFIG",
  data,
});
