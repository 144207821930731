import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import { COLORS } from "../graphs.configs";
import { roundOfMaxYAxisValue, parseDataForMSColumn } from '../graphs.helperFunctions';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: 0,
      total_legend_length: "",
      max_legend_length: 0,
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1 ljkshdlkjsbd slkkkkdnlkmsf klshd.kjsdbms",
          showValue: true,
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
    this.bytesToString = this.bytesToString.bind(this);
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      let datas = this.props.data.data;
      let arr = parseDataForMSColumn(this.props.data.data);
      let maxValue = roundOfMaxYAxisValue(arr[0] * 1.7);
      let catagory = this.props.data.catagory,

        new_data = [...this.props.data.data];
      let total_legend_length = "", max_legend_length = 0;
      new_data = new_data.map(item => {
        if (item.seriesname.length > max_legend_length)
          max_legend_length = item.seriesname.length;
        // if (this.props.legendsPositionDynamic) 
        item.seriesname = this.bytesToString(item.seriesname, 35)
        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname
        item.data.map(elm => elm["showValue"] = !this.props.dataHidden)
        return item;
      })

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState &&
      JSON.stringify(prevState.data) !== JSON.stringify(this.props.data.data)
    ) {
      let datas = this.props.data.data;
      let arr = parseDataForMSColumn(this.props.data.data);
      let maxValue = roundOfMaxYAxisValue(arr[0]);
      let catagory = this.props.data.catagory,
        new_data = [...this.props.data.data];
      let total_legend_length = "", max_legend_length = 0;
      new_data = new_data.map(item => {
        if (item.seriesname.length > max_legend_length)
          max_legend_length = item.seriesname.length;
        // if (this.props.legendsPositionDynamic) {
        item.seriesname = item.seriesname.replace(/<br\/>/g, '');
        item.seriesname = this.bytesToString(item.seriesname, 35);
        // }
        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname
        return item;
      })

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length
      });
    }
  }

  bytesToString(d, width) {
    let str = "";
    let computed_array = [];

    let string_arr = d.trim().split(" ");
    for (let item of string_arr) {
      if ((item + str).length >= width) {
        computed_array.push(str);
        str = ""
      }
      str += " " + item;
    }
    if (str !== "")
      computed_array.push(str);

    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1)
        caption += computed_array[i] + "<br/>";
      else caption += computed_array[i];
    }
    return caption;
  }

  render() {
    let data = "",
      catagory = "",
      maxVal = 0,
      rotateValues = 0,
      legendsPositionDynamic = false,
      max_legend_length = 0,
      valueFontSize = 12,
      id = "1",
      width = 740,
      height = 220,
      headers = "",
      legendLength = 14;

    data = this.state.data;
    catagory = this.state.catagory;


    data.map(item=>{
      item.data.map(val=>{
        if(val.value > maxVal){
          maxVal = val.value;
        }
      })
    })

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.valueFontSize) valueFontSize = this.props.valueFontSize;
    if (this.props.rotateValues === 1) rotateValues = this.props.rotateValues;
    if (this.props.legendsPositionDynamic) legendsPositionDynamic = this.props.legendsPositionDynamic;
    if (this.props.id) id = this.props.id;

    if (data.length > 5) legendLength = 11
    if (valueFontSize === 11) legendLength = data.length > 5 ? 11 : 14;
    if (legendsPositionDynamic) legendLength = 11
    if (this.state.max_legend_length < 35) max_legend_length = 35 - this.state.max_legend_length;


    let chartConfigs1 = {
      type: "mscolumn2d",
      dataFormat: "json",
      width: width,
      height: height,
      id: id,
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showanchors: 1,

          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000",
          numberScaleUnit: "k,M,B",

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#E5E5E5",
          divLineAlpha: "80",
          divLineDashed: "0",

          decimals: 1,
          paletteColors: COLORS,
          showPlotBorder: "0",
          plotSpacePercent: 40,
          minPlotHeightForValue: 10,
         

          labelFontSize: 14,
          labelFontColor: "#7f7f7f",

          legendItemFont: "Avenir Medium",
          legendItemFontColor: "#7f7f7f",
          legendItemFontSize: legendLength,
          legendPosition: "bottom",
          legendXPosition: this.props.legendsXPosition ? this.props.legendsXPosition : 370 + max_legend_length,
          legendYPosition: 50 + this.props.legendsYPosition,

          showValues: !this.props.dataHidden,
          valueFontBold: false,
          valueFontColor: "#002F75",
          placeValuesInside: "0",
          valueFont: "Avenir Heavy",
          valueFontSize: valueFontSize,
          rotateValues: 1,

          showsum: !this.props.dataHidden,
          sumPadding: "15",

          showYAxisLine: false,
          yAxisLineColor: "#4A90E2",
          yAxisLineThickness: 1,
          yAxisNamePadding: 0,
          showYAxisValues: false,
          yAxisValueFontSize: 14,
          yAxisValueFontColor: "#7f7f7f",
          yAxisValueDecimals: 1,
          yAxisMaxValue :  maxVal*1.44,  //this.state.maxValue,

          showXAxisLine: false,
          xAxisLineThickness: 1,
          xAxisLineColor: "#4A90E2",
          xAxisNameFont: "Avenir Medium",
          xAxisValueFontSize: 14,
          xAxisNameFontColor: "#444444",
          centerXAxisName: false,

          chartTopMargin: 40 + this.props.chartHeadingGap,
          chartLeftMargin: 4,
          chartBottomMargin: 0,

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f"
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${570 + this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                }
              ]
            }
          ]
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };

    if (legendsPositionDynamic) {
      chartConfigs1['dataSource']['chart'] = {
        ...chartConfigs1['dataSource']['chart'],
        legendPosition: "absolute",
        legendNumColumns: 1,
        chartRightMargin: this.state.max_legend_length > 35 ? 220 : 200,
      }
    }
    else {
      chartConfigs1['dataSource']['chart'] = {
        ...chartConfigs1['dataSource']['chart'],
        legendPosition: "absolute",
        legendNumColumns: 1,
        chartRightMargin: 310 - max_legend_length,
        legendXPosition: 980 + max_legend_length
      }
    }
    return (
        <div>
          <ReactFC {...chartConfigs1} />
        </div>
    );
  }
}




