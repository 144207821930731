import React, { useEffect, useState } from "react";
import Options from "./Options";
import "./comment.css";
import { FilePptOutlined, SmileOutlined } from "@ant-design/icons";
import { Switch } from "antd";
function ChatMessage({
  chat,
  user,
  handleButtonClick,
  handleBoxClose,
  handleMakeComment,
  openChatId,
  handleReplyClick,
  handleViewReplies,
  selectedReply,
  setSelectedReply,
  editedDraftDate
}) {
  const [showemji, setShowemji] = useState(false);
  const [showAllEmojis, setShowAllEmojis] = useState(false);
  const [emojiReplies, setEmojiReplies] = useState({});
  const [showRepliesForChatId, setShowRepliesForChatId] = useState(null);
  let AllEmojis = ["🔥", "👍", "👎", "🙌", "🤔"];

  const mockemk = {
    "😀": 10,

    "😂": 20,

    "🔥": 30,

    "👍": 100,
  };
  useEffect(() => {
    if (chat && chat.emoji) {
      let emo = {};

      Object.values(chat.emoji).forEach((emoji) => {
        if (emo[emoji]) {
          emo[emoji] = emo[emoji] + 1;
        } else {
          emo[emoji] = 1;
        }
      });
      setEmojiReplies(emo);
    }
  }, [chat]);

  // Function to handle Switch toggle
  const handleSwitchToggle = (chatID) => {
    if (showRepliesForChatId === chatID) {
      setShowRepliesForChatId(null);
      handleViewReplies(null);
    } else {
      setShowRepliesForChatId(chatID);
      handleViewReplies(chatID);
    }
  };

  return (
    <div
      onMouseEnter={() => setShowemji(true)}
      className={`comment-${chat._id}`}
      onMouseLeave={() => {
        handleBoxClose();
        setShowemji(false);
        setShowAllEmojis(false);
      }}
    >
      <div className="commentcontainer">
        <div
          style={{
            background: chat.user_id !== user.uid ? "none" : "#dceff6",
            padding: "5px",
            position: "relative",
            borderRadius: "8px",
            border: chat.user_id !== user.uid ? "1px solid grey" : "none",
            boxShadow:
              selectedReply === chat._id
                ? "rgb(255 193 0 / 47%) 0px -1px 20px 1px inset"
                : "none",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: "800",
                }}
              >
                {chat.user_name} {` ${chat.user_designation}`}
              </p>

              <div className="chat">
                <Options
                  handleButtonClick={handleButtonClick}
                  chat={chat}
                  handleBoxClose={handleBoxClose}
                  handleMakeComment={handleMakeComment}
                  user={user}
                  openChatId={openChatId}
                  isEditedDraft={new Date(editedDraftDate)>new Date(chat.created_at)}
                />
              </div>
            </div>
          </div>
          {chat.reply && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "rgb(176 224 242)",
                borderRadius: "10px",
                padding: "1px 5px",
                gap: "5px",
                borderLeft: "5px solid rgb(0 152 255)",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedReply(chat.reply.message_id);
                handleReplyClick(chat.reply.message_id);
              }}
            >
              <div style={{ fontWeight: "500" }}>{chat.reply.user_name}</div>
              {/* <div> {chat.reply.comment}</div> */}
              {chat.reply.messageType === "attachment" ? (
                <p
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxHeight: "60px",
                    padding: "5px 0px",
                  }}
                >
                  <FilePptOutlined /> An Attachment
                </p>
              ) : (
                <p
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxHeight: "60px",
                  }}
                >
                  {chat.reply.comment}
                </p>
              )}
            </div>
          )}
          {chat.messageType === "attachment" ? (
            <div>
              <p
                style={{
                  maxWidth: "100%",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  fontWeight: "800",
                  margin: 0,
                  maxHeight: "130px",
                  overflowY: "auto",
                }}
              >
                {chat.comment}
              </p>
              <div
                onClick={() => window.open(chat.attachmentUrl, "_blank")}
                className="attachment-btn"
              >
                <FilePptOutlined /> Attachment
              </div>
            </div>
          ) : (
            <p
              style={{
                maxWidth: "100%",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                margin: 0,
                maxHeight: "130px",
                overflowY: "auto",
              }}
            >
              {chat.comment}
            </p>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: "10.5px" }}>
              {`${new Date(chat.created_at).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}, ${new Date(chat.created_at).toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}`}
            </p>
            <p
              style={{
                background:
                  chat.status === "Pending"
                    ? "#3b8de2"
                    : chat.status === "Addressed"
                    ? "#ff7300"
                    : chat.status === "Satisfactory"
                    ? "#18a10c"
                    : chat.status === "Re-addressed"
                    ? "rgb(214,47, 47)"
                    : "transparent",
                color: "white",
                padding: "0px 7px",
                borderRadius: "6px",
                fontSize: "13px",
              }}
            >
              {chat.status}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: '11px',
              marginTop: '10px'
            }}
          >
            <Switch
              checked={showRepliesForChatId === chat._id} // Set the checked state based on whether chat._id matches the state
              onChange={() => handleSwitchToggle(chat._id)}
              size="small"
            />
            <span>View Replies</span>
          </div>
          <div
            style={{
              display: "flex",
              position: "absolute",
              bottom: "-22px",
              gap: "2px",
            }}
          >
            {Object.entries(emojiReplies).map(([emoji, count], i) => {
              return (
                <div key={i} style={{ display: "flex", gap: "5px" }}>
                  <span>{emoji}</span>
                  <span>{count}</span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="emoption">
          {showAllEmojis && (
            <div
              style={{
                display: "flex",
                gap: "8px",
                height: "29px",
                position: "absolute",
                right: "18px",
                top: "-16px",
                background: "#ecf8fa",
                padding: "5px",
                borderRadius: "10px",
                boxShadow: "1px 1px 10px 0px #00000052",
              }}
            >
              {AllEmojis.map((emoji) => {
                return (
                  <span
                    className="emoji"
                    onClick={() => {
                      handleMakeComment("emoji", null, chat._id, emoji);
                      setShowAllEmojis(false);
                    }}
                  >
                    {emoji}
                  </span>
                );
              })}
            </div>
          )}
          <span
            className="emojiico"
            style={{
              display: showemji ? "block" : "none",
              cursor: "pointer",
            }}
            onClick={() => setShowAllEmojis(!showAllEmojis)}
          >
            <SmileOutlined />
          </span>
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;
