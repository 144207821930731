import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import { COLORS } from "../graphs.configs";
import { breakLabels } from "../graphs.helperFunctions";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

let COLOR = COLORS.split(',').map(item => {
  return item.trim();
});

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      categories_sentance_limit: 1,
      data: [
        {
          seriesname: "Polybutylene Terephthalate (PBT) klakdjskfd sdklfsjkdfnsd ahloals",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1",
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      let str, new_str, new_catagory;
      let datas = [...this.props.data.data].map((item, i) => {
        if (this.props.template_name !== "1100") {
          if (item?.seriesname?.length > 24) {

            str = item.seriesname;// breakLabels(String(item.seriesname), 25);

            return {
              ...item,
              seriesname: str,
            };
          }
        }

        return {
          ...item,
        };
      });

      datas = datas.map((elm) => {
        let result = elm.data.map((it) => {
          if (it.value > 10) {
            return {
              showValue: !this.props.dataHidden,
              value: it.value,
            };
          } else if (it.value <= 10 && it.value >= 4) {
            return {
              showValue: !this.props.dataHidden,
              value: Math.floor(it.value),
            };
          } else if (
            it.value < 4 &&
            this.props.template !== "keyStrategicMoves"
          ) {
            return {
              showValue: "0",
              value: Math.floor(it.value),
            };
          } else if (it.value > 0) {
            return {
              showValue: !this.props.dataHidden,
              value: Math.floor(it.value),
            };
          }
        });
        return {
          seriesname: breakLabels(elm.seriesname, 30),
          data: [...result],
        };
      }).sort((a) => {
        return a.seriesname;
      });
      let categories_sentance_limit = 1;
      new_catagory = [...this.props.data.catagory].map((item) => {
        const companyCategoryLabel = item?.label || ""
        if (companyCategoryLabel.length > 12 && companyCategoryLabel.length < 30) {
          str = breakLabels(companyCategoryLabel, 13);
          let br = new RegExp("<br/>", "g");
          let limit = (str + "").match(br) ? (str + "").match(br).length : 1;
          if (categories_sentance_limit < limit)
            categories_sentance_limit = limit
          return {
            ...item,
            label: str,
          };
        }
        return {
          ...item,
        };
      });

      this.setState({
        loadingData: false,
        data: datas,
        catagory: new_catagory,
        categories_sentance_limit
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      JSON.stringify(prevProps.data.data) !==
      JSON.stringify(this.props.data.data)
    ) {
      let str, new_str, new_catagory;

      let datas = [...this.props.data.data].map((item, i) => {
        if (this.props.template_name !== "1100") {
          if (item?.seriesname?.length > 24) {

            str = item.seriesname;// breakLabels(String(item.seriesname), 25);


            return {
              ...item,
              seriesname: str,
            };
          }
        }
        return {
          ...item,
        };
      });

      datas = datas.map((elm) => {
        let result = elm.data.map((it) => {
          if (it.value > 10) {
            return {
              showValue: "1",
              value: it.value,
            };
          } else if (5 <= it.value && it.value <= 10) {
            return {
              showValue: "1",
              value: Math.floor(it.value),
            };
          } else if (
            it.value < 4 &&
            this.props.template !== "keyStrategicMoves"
          ) {
            return {
              showValue: "0",
              value: Math.floor(it.value),
            };
          } else if (it.value > 0) {
            return {
              showValue: "1",
              value: Math.floor(it.value),
            };
          }
        });
        return {
          seriesname: breakLabels(elm.seriesname, 30),
          data: [...result],
        };
      });
      let categories_sentance_limit = 1;
      new_catagory = [...this.props.data.catagory].map((item) => {
        const companyCategoryLabel = item?.label || ""
        if (companyCategoryLabel.length > 12 && companyCategoryLabel.length < 30) {
          str = breakLabels(companyCategoryLabel, 13);
          let br = new RegExp("<br/>", "g");
          let limit = (str + "").match(br) ? (str + "").match(br).length : 1;
          if (categories_sentance_limit < limit)
            categories_sentance_limit = limit
          return {
            ...item,
            label: str,
          };
        }
        return {
          ...item,
        };
      });

      this.setState({
        loadingData: false,
        data: datas,
        catagory: new_catagory,
        categories_sentance_limit
      });
    }
  }

  render() {
    let data = "",
      catagory = "",
      width = 740,
      height = 220,
      legendItemFontSize = 14,
      headers = "",
      seriesName = [],
      legendsData = [],
      newData = [],
      others = [];

    data = this.state.data;
    catagory = this.state.catagory;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (data?.length >= 8) legendItemFontSize = 11;

    data.map((item) => {
      seriesName.push({ seriesname: item.seriesname });
    })

    seriesName = seriesName.sort((a, b) => { return a.seriesname.localeCompare(b.seriesname) }).map((series, index) => {
      return { ...series, color: COLOR[index] }
    });

    let x1 = new RegExp("^other.*"),
      x2 = new RegExp("^rest.*");
    seriesName = seriesName.filter(series => {
      if (x1.test(series.seriesname.toLowerCase()) || x2.test(series.seriesname.toLowerCase())) {
        others.push(series);
        return false;
      } else {
        return true;
      }
    });
    if (others.length !== 0)
      others.map(series_item => seriesName.push(series_item));

    data.map((item) => {
      seriesName.map((series) => {
        if (item.seriesname === series.seriesname) {
          newData.push({ ...item, color: series.color })
        }
      })
    })

    data = [...newData]


    if (this.props.template_name === "1100") {

      seriesName.map((series, i) => {
        let yCoordinate = "0";

        if (i < 2) {
          yCoordinate = "400";
        } else if (i >= 2 && i < 4) {
          yCoordinate = "420"
        } else if (i >= 4 && i < 6) {
          yCoordinate = "440"
        } else if (i >= 6 && i < 8) {
          yCoordinate = "460"
        } else if (i >= 8 && i < 10) {
          yCoordinate = "480"
        } else {
          yCoordinate = "500"
        };

        let colorData = {
          id: `shape-${i}`,
          type: "polygon",
          startangle: "45",
          sides: "4",
          radius: "10",
          color: `${series.color}`,
          x: `${i % 2 === 0 ? 30 : 265}`,
          y: yCoordinate,
        };

        let seriesData = {
          id: `label-${i}`,
          type: "text",
          align: "left",
          fillcolor: "#7f7f7f",
          fontsize: 12,
          font: "Avenir Medium",
          text: `${series.seriesname}`,
          bold: "0",
          wrap: "1",
          x: `${i % 2 === 0 ? 45 : 280}`,
          y: yCoordinate,
        };
        legendsData.push(colorData, seriesData);
      })

    }

    if (this.props.template_name === "1101c") {

      seriesName.map((series, i) => {
        let yCoordinate = "0";
        if (i === 0) {
          yCoordinate = "60";
        } else if (i === 1) {
          yCoordinate = "84"
        } else if (i === 2) {
          yCoordinate = "108"
        } else if (i === 3) {
          yCoordinate = "132"
        } else if (i === 4) {
          yCoordinate = "156"
        } else if (i === 5) {
          yCoordinate = "180"
        } else if (i === 6) {
          yCoordinate = "204"
        } else if (i === 7) {
          yCoordinate = "228"
        } else if (i === 8) {
          yCoordinate = "252"
        } else {
          yCoordinate = "276"
        };

        let colorData = {
          id: `shape-${i}`,
          type: "polygon",
          startangle: "45",
          sides: "4",
          radius: "7",
          color: series.color,
          x: this.props.template === "keyStrategicMoves" ? `${365}` : `${345}`,
          y: yCoordinate,
        };

        let seriesData = {
          id: `label-${i}`,
          type: "text",
          align: "left",
          fillcolor: "#7f7f7f",
          fontsize: 11,
          font: "Avenir Medium",
          text: `${series.seriesname}`,
          bold: "0",
          wrap: "1",
          x: this.props.template === "keyStrategicMoves" ? `${375}` : `${355}`,
          y: yCoordinate,
        };
        legendsData.push(colorData, seriesData);
      })
    }



    let chartConfigs = {
      type: "stackedbar2d",
      dataFormat: "json",
      width: width,
      height: height,
      id: this.props.id ? this.props.id : "stacked",
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showAnchors: 1,

          formatNumberScale: 0,
          numberScaleValue: "1000,1000,1000",
          numberScaleUnit: "k,M,B",

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#ffffff",
          divLineAlpha: "80",
          divLineDashed: "0",

          paletteColors: COLORS,
          minPlotHeightForValue: 0,
          plotSpacePercent: 40,

          showValues: !this.props.dataHidden,
          valueFont: "Avenir Heavy",
          valueFontSize: 12,
          valueFontBold: true,
          valueFontColor: "#ffffff",
          placeValuesInside: "0",

          labelFontColor: "#7f7f7f",
          labelFontSize: this.props.template == "typeoverview" ? 14 : this.state.categories_sentance_limit > 1 ? 10 : 12,
          labelDisplay: "WRAP",

          showLegend: (this.props.template_name === '1100' || this.props.template_name === '1101c') ? 0 : 1,
          legendPosition: "absolute",
          legendNumColumns: 1,
          legendXPosition: width <= 566 ? "350" : "1100",
          legendYPosition: 48 + this.props.legendsYPosition,
          legendItemFontColor: "#7f7f7f",
          legendItemFont: "Avenir Medium",
          legendItemFontSize: width <= 566 && this.state.data?.length >= 4 ? 12 : 14,

          showYAxisValues: false,
          showYAxisLine: false,
          yAxisNamePadding: 0,
          yAxisMaxValue: this.props.value_percentage ? 101 : 0,

          showXAxisLine: false,
          centerXaxisName: false,

          chartTopMargin: width <= 566 ? (60 + this.props.chartHeadingGap) : (100 + this.props.chartHeadingGap),
          chartBottomMargin: (this.props.template_name === "1100") ? 200 : 0,
          chartRightMargin: this.props.value_percentage ? 250 : 200,
          // chartLeftMargin: 0,

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${500 + this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin + 10}`,
                },
              ],
            },
            {
              id: "legends",
              items: [],
            },

          ],
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };

    if ((this.props.template_name === "1100") || (this.props.template_name === "1101c")) {
      chartConfigs.dataSource.annotations.groups[1].items.push(
        ...legendsData
      );
    }

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
