import styled from "styled-components";

export const Page = styled.div`
    display: block;
    page-break-after: always;
`;

export const Container = styled.div`
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${({formInterface})=>  formInterface ? "auto" : "626px"};
    width: 100%;
    @media only screen and (max-width: 960px) {
        height: auto;
        padding: 25px 10px;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
    height: calc(45%- 10px);
`;

export const Column = styled.div`
    flex: 50%;
    max-width: 48%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;