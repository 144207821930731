import styled from "styled-components";

export const ArchitecturalCoatingVCWrapper = styled.div`
  .section_data1 {
    top: 78%;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    top: 78%;
    width: 20%;
    max-width: 235px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list1 {
    left: 4%;
    width: 11%;
    max-width: 130px;
  }

  .section1_list2 {
    left: 14%;
    width: 11%;
    max-width: 130px;
  }

  .section1_list3 {
    left: 26%;
    width: 11%;
    max-width: 130px;
  }

  .section2_list {
    left: 43%;
  }

  .section3_list1 {
    left: 70%;
    width:14%;
    max-width: 170px;
  }

  .section3_list2 {
    left: 84%;
    width:14%;
    max-width: 170px;
  }
`;
