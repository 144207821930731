import React from "react";

export default function OverviewComponent(props) {
  return (
    <div className="company_bio" style={{ background: props.bgColor }}>
      <h3 className="subheading" style={{ color: props.color }}>
        {props.heading}
      </h3>
      
      {props.analysis && props.analysis.length
        ? props.analysis.map((item) => (
            <div className="company_content">
              <p style={{ marginBottom: "15px", color: props.color }}>{replaceNbsps(item)}</p>
            </div>
          ))
        : ""}
    </div>
  );
}
function replaceNbsps(str) {
  str=str+""
  var re = new RegExp(String.fromCharCode(160), "g");
  return str.replace(re, " ");
}