import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
var Base64 = require("js-base64").Base64;

class StaticSlide extends React.Component {
  state = {
    data: this.props.data,
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  render() {
    let { section_id, slug } = this.state.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;

    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <div id={pageId} className="page">
        {(!this.props.fromInterface || this.props.fromInterface === undefined) && !this.state.data.header_hidden ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}

        {!this.state.data.header_hidden && !this.state.data.footer_hidden ? (
          <div className="static-slide-container">
            {this.props.data?.url ? (
              <img src={this.props.data?.url} alt="Static Slide" />
            ) : (
              this.props.data.data?.map((item, idx) => (
                <img src={item.url} alt="Static Slide" key={idx} />
              ))
            )}
          </div>
        ) : (
          <div className="static-slide-container" style={{height:"720px"}}>
            {this.props.data?.url ? (
              <img src={this.props.data?.url} alt="Static Slide" />
            ) : (
              this.props.data.data?.map((item, idx) => (
                <img src={item.url} alt="Static Slide" key={idx} />
              ))
            )}
          </div>
        )}


        {(!this.props.fromInterface || this.props.fromInterface === undefined) && !this.state.data.footer_hidden ? (
          <Footer
            slug={slug}
            slideNo={slideNo}
            reseller={this.state.reseller}
          />
        ) : null}
      </div>
    );
  }
}

export default StaticSlide;
