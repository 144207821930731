class Superscript {

  static get isInline() {
      return true;
  }
  static get sanitize() {
      return {
          sup: {},
      };
  }


  constructor() {
      this.button = null;
      this.state = false;
  }

  render() {
      this.button = document.createElement('button');
      this.button.type = 'button';
      this.button.style.display='flex';
      this.button.style.alignItems='center';
      this.button.style.justifyContent='center';
      this.button.style.width='30px';
      this.button.style.height='30px';
      this.button.innerHTML = `<svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.062 19h1.09L9.459 4h-.918L1.848 19h1.09l2.24-5h7.645zm-9.437-6L9 5.464 12.375 13zM21.9 10h-5.875V8.51c4.736-4.126 4.93-4.138 4.93-5.01 0-1.962-3.614-1.869-3.985-.39L16 2.868c.662-2.633 5.954-2.525 5.954.633 0 1.308-.423 1.58-4.93 5.5H21.9z"/><path fill="none" d="M0 0h24v24H0z"/></svg>`

      return this.button;
  }

  surround(range) {
      if (this.state) {
          // If highlights is already applied, do nothing for now
          return;
      }

      const selectedText = range.extractContents();

      // Create MARK element
      const mark = document.createElement('sup');

      // Append to the MARK element selected TextNode
      mark.appendChild(selectedText);

      // Insert new element
      range.insertNode(mark);
  }

 
  checkState(selection) {
      const text = selection.anchorNode;

      if (!text) {
          return;
      }

      const anchorElement = text instanceof Element ? text : text.parentElement;
    
      this.state = !!anchorElement.closest('sup');
  }
}
export default Superscript;
