import styled from "styled-components";

export const AviationVCWrapper = styled.div`
  .section_container {
    display: flex;
  }

  .section_data1 {
    width: 13%;
    max-width: 160px;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    width: 18%;
    max-width: 240px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list1 {
    left: 3%;

    top: ${(props) =>
      props.templatePart
        ? props.templatePart === "1" || props.templatePart === "2"
          ? props.fromInterface
            ? "58%"
            : "52%"
          : props.templatePart === "3"
          ? props.fromInterface
            ? "58%"
            : "52%"
          : ""
        : ""};
  }

  .section1_list2 {
    left: 18%;

    top: ${(props) =>
      props.templatePart
        ? props.templatePart === "1" || props.templatePart === "2"
          ? props.fromInterface
            ? "58%"
            : "52%"
          : props.templatePart === "3"
          ? props.fromInterface
            ? "58%"
            : "52%"
          : ""
        : ""};

    .font_design {
      color: #35bc68;
    }
  }

  .section2_list {
    left: ${(props) =>
      props.templatePart
        ? props.templatePart === "1" || props.templatePart === "2"
          ? props.fromInterface
            ? "44%"
            : "42%"
          : props.templatePart === "3"
          ? props.fromInterface
            ? "42%"
            : "40%"
          : ""
        : ""};

    top: ${(props) =>
      props.templatePart
        ? props.templatePart === "1" || props.templatePart === "2"
          ? props.fromInterface
            ? "53%"
            : "47%"
          : props.templatePart === "3"
          ? props.fromInterface
            ? "53%"
            : "47%"
          : ""
        : ""};
  }

  .section3_list1 {
    left: ${(props) =>
      props.templatePart
        ? props.templatePart === "1" || props.templatePart === "2"
          ? "68%"
          : props.templatePart === "3"
          ? "75%"
          : ""
        : ""};

    top: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? props.fromInterface
            ? "64%"
            : "57%"
          : props.templatePart === "2"
          ? props.fromInterface
            ? "59%"
            : "52%"
          : props.templatePart === "3"
          ? props.fromInterface
            ? "45%"
            : "40%"
          : ""
        : ""};

    width: ${(props) =>
      props.templatePart
        ? props.templatePart === "1" || props.templatePart === "2"
          ? "18%"
          : props.templatePart === "3"
          ? "18%"
          : ""
        : ""};

    .font_design {
      color: ${(props) =>
        props.templatePart === "1" || props.templatePart === "2"
          ? "#EE9A69"
          : "#1f4986"};
    }

    max-width: ${(props) =>
      props.templatePart === "1" || props.templatePart === "2"
        ? "180px"
        : "188px"};
  }

  .section3_list2 {
    left: ${(props) =>
      props.templatePart
        ? props.templatePart === "1" || props.templatePart === "2"
          ? "85%"
          : props.templatePart === "3"
          ? props.fromInterface
            ? "84%"
            : "85%"
          : ""
        : ""};

    top: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? props.fromInterface
            ? "64%"
            : "57%"
          : props.templatePart === "2"
          ? props.fromInterface
            ? "59%"
            : "52%"
          : props.templatePart === "3"
          ? props.fromInterface
            ? "45%"
            : "40%"
          : ""
        : ""};
  }

  // ROW 1
  .section1_row1_list1 {
    left: 3%;

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "58%" : "52%") : ""};
  }

  .section1_row1_list2 {
    left: 17%;

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "58%" : "52%") : ""};

    .font_design {
      color: #35bc68;
    }
  }

  .section2_row1_list1 {
    left: ${(props) =>
      props.templatePart ? (props.fromInterface ? "44%" : "42%") : ""};

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "53%" : "47%") : ""};
  }

  .section3_row1_list1 {
    left: 68%;

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "63%" : "57%") : ""};

    .font_design {
      color: #ee9f70;
    }
  }

  .section3_row1_list2 {
    left: ${(props) =>
      props.templatePart ? (props.fromInterface ? "85%" : "87%") : ""};

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "63%" : "57%") : ""};
  }

  // ROW 2
  .section1_row2_list1 {
    left: 3%;

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "58%" : "52%") : ""};
  }

  .section1_row2_list2 {
    left: 17%;

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "58%" : "52%") : ""};

    .font_design {
      color: #35bc68;
    }
  }

  .section2_row2_list1 {
    left: ${(props) =>
      props.templatePart ? (props.fromInterface ? "44%" : "42%") : ""};

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "53%" : "47%") : ""};
  }

  .section3_row2_list1 {
    left: 68%;

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "59%" : "52%") : ""};

    .font_design {
      color: #ee9f70;
    }
  }

  .section3_row2_list2 {
    left: 85%;

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "59%" : "52%") : ""};
  }

  // ROW 3
  .section1_row3_list1 {
    left: 3%;

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "57%" : "52%") : ""};
  }

  .section1_row3_list2 {
    left: 17%;

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "57%" : "52%") : ""};

    .font_design {
      color: #35bc68;
    }
  }

  .section2_row3_list1 {
    left: ${(props) =>
      props.templatePart ? (props.fromInterface ? "43%" : "42%") : ""};

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "53%" : "47%") : ""};
  }

  .section3_row3_list1 {
    left: ${(props) =>
      props.templatePart ? (props.fromInterface ? "79%" : "78%") : ""};

    top: ${(props) =>
      props.templatePart ? (props.fromInterface ? "45%" : "40%") : ""};
  }
`;
