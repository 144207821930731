import React from "react";
import Footer from "../components/Footer";
import { Base64 } from "js-base64";

export const demoreportofferdata = {
  slug: "North america",
};

class ReportOffers extends React.Component {
  state = {
    data: this.props.data || demoreportofferdata,
  };
  render() {
    const { slideNo } = this.props;
    const { slug, data, url } = this.state.data;
    const { section_id } = this.props.data;
    const pageId = section_id ? Base64.encode(section_id) : undefined;

    if (Array.isArray(data) && data?.length) {
      // mapping was removed because the all the images share the same section id which caused same page number in the pdf
      // return data.map((item, index) => {
      return (
        <div className="page" id={pageId}>

          <div className="reportoffer-container">
            <img src={data[0].url || ""} alt="report offers" />
          </div>

          {!this.props.fromInterface || this.props.fromInterface === undefined ? (
            <Footer slug={slug} slideNo={slideNo} />
          ) : null}
        </div>)
      // })
    } else if (url) {
      return (
        <div className="page" id={pageId}>
          <div className="reportoffer-container">
            <img src={url} alt="report offers" />
          </div>

          {!this.props.fromInterface || this.props.fromInterface === undefined ? (
            <Footer slug={slug} slideNo={slideNo} />
          ) : null}
        </div>
      )
    } else {
      return (
        <div className="page" id={pageId}>
          <div className="reportoffer-container">
            <p>No data found</p>
          </div>

          {!this.props.fromInterface || this.props.fromInterface === undefined ? (
            <Footer slug={slug} slideNo={slideNo} />
          ) : null}
        </div>
      )
    }

    // return {
    //   Array.isArray(data) && data?.length ? (
    //     data.map((item, index) => (
    //       <div className="page" id={pageId} key={index}>

    //         <div className="reportoffer-container">
    //           <img src={item?.url || ""} alt="report offers" />
    //         </div>

    //         {!this.props.fromInterface || this.props.fromInterface === undefined ? (
    //           <Footer slug={slug} slideNo={slideNo} />
    //         ) : null}
    //       </div>
    //     ))
    //   ) : url ? (
    //     <div className="page" id={pageId}>
    //       <div className="reportoffer-container">
    //         <img src={url} alt="report offers" />
    //       </div>

    //       {!this.props.fromInterface || this.props.fromInterface === undefined ? (
    //         <Footer slug={slug} slideNo={slideNo} />
    //       ) : null}
    //     </div>

    //   ) : (

    //   )
    // }

    // return (
    //   <div className="page" id={pageId}>

    //     {!this.props.fromInterface || this.props.fromInterface === undefined ? (
    //       <Footer slug={slug} slideNo={slideNo} />
    //     ) : null}
    //   </div>
    // );
  }
}

export default ReportOffers;
